import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concurso-de-bolsas-santa-clara-challenge-candido-mota',
  templateUrl: './concurso-de-bolsas-santa-clara-challenge-candido-mota.component.html',
  styleUrls: ['./concurso-de-bolsas-santa-clara-challenge-candido-mota.component.scss']
})
export class ConcursoDeBolsasSantaClaraChallengeCandidoMotaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
