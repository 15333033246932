
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Participe do CONCOC e tenha a chance de ganhar até 100% de bolsas!">
    

    
      <meta property="og:title" content="CONCOC - COC Online" />
      <meta name="twitter:title" content="CONCOC - COC Online" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.coconline.com.br/concoc-semiextensivo-2024-2" />
    

    <title>CONCOC - COC Online</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NM5DHFZ3');</script>
<!-- End Google Tag Manager -->

    
  </head>
  <body>
    
      <input type="hidden" id="ga4-measurement-id" value="">
      <input type="hidden" id="has-gtm" value="false">
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kppthk7k {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1719495641/$jr4uz31v4oc), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kppthk7k > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-kppthk7k" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lpu1cjvi {
      min-height: 50px;
    }

    #rd-row-lpu1cjvi .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lpu1cjvi.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lpu1cjvi.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lpu1cjvi" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lpu1cjvj {
      flex: 12 0 0;
    }

    #rd-column-lpu1cjvj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lpu1cjvj > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lpu1cjvj" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lpu1cv1f {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lpu1cv1f" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lpu1csaz {
      min-height: 50px;
    }

    #rd-row-lpu1csaz .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lpu1csaz.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lpu1csaz.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lpu1csaz" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lpu1csb0 {
      flex: 12 0 0;
    }

    #rd-column-lpu1csb0 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lpu1csb0 > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-lpu1csb0 > div {
          padding-top: 0px;
          padding-right: 10px;
          padding-bottom: 0px;
          padding-left: 10px;
        }
      }
    
  
</style>

<div id="rd-column-lpu1csb0" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lpu1cy8z {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 363px;
    }

    #rd-image-lpu1cy8z a,
    #rd-image-lpu1cy8z img {
      width: 363px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lpu1cy8z {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lpu1cy8z" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719582093/$ia45w5x2yoo" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxbci75 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lxxbci75.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lxxbci75 {
          display: none;
        }

        #rd-text-lxxbci75.mobile {
          display: block;
          max-width: 200px;
          margin-top: -13px;
          margin-bottom: 10px;
        }
      }
    
  
</style>

<div id="rd-text-lxxbci75" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>


  <div id="rd-text-lxxbci75" class="bricks--component bricks--component-text mobile rd-text">
    <p> </p>
  </div>


<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxbbuba {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 691px;
      margin-top: 0px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lxxbbuba.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lxxbbuba {
          display: none;
        }

        #rd-text-lxxbbuba.mobile {
          display: block;
          max-width: 691px;
          margin-top: 0px;
          margin-bottom: 30px;
        }
      }
    
  
</style>

<div id="rd-text-lxxbbuba" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="background-color: transparent; color: rgb(60, 55, 118); font-size: 24px;">Concorra a bolsas de estudo de até 100% e estude de graça no semiextensivo que mais aprova no estado!</strong></p>
</div>


  <div id="rd-text-lxxbbuba" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><strong style="font-size: 24px; color: rgb(60, 55, 118); background-color: transparent;">Concorra a bolsas de estudo de até 100% e estude de graça! </strong></p>
  </div>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kppygs21 {
      min-height: 680px;
    }

    #rd-row-kppygs21 .bricks--column > div {
      min-height: 680px;
    }

    @media (max-width: 480px) {
      #rd-row-kppygs21.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-kppygs21.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kppygs21" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45w82ct {
      flex: 6 0 0;
    }

    #rd-column-l45w82ct > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45w82ct > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-l45w82ct > div {
          padding-top: 0px;
          padding-right: 10px;
          padding-bottom: 0px;
          padding-left: 10px;
        }
      }
    
  
</style>

<div id="rd-column-l45w82ct" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l74zzmgs {
      margin-top: 37px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 308px;
    }

    #rd-image-l74zzmgs a,
    #rd-image-l74zzmgs img {
      width: 308px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l74zzmgs {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-l74zzmgs {
          margin-top: 9px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 308px;
        }

        #rd-image-l74zzmgs a,
        #rd-image-l74zzmgs img {
          width: 308px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-l74zzmgs" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719511305/$gy53hr2x0d" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kylxv2au {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 15px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-kylxv2au.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-kylxv2au {
          display: none;
        }

        #rd-text-kylxv2au.mobile {
          display: block;
          max-width: 10px;
          margin-top: -52px;
          margin-bottom: 30px;
        }
      }
    
  
</style>

<div id="rd-text-kylxv2au" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="background-color: transparent; color: rgb(60, 55, 118); font-size: 16.5pt;">Preencha o formulário e participe do Concurso de Bolsas do COC Online!</strong></p>
</div>


  <div id="rd-text-kylxv2au" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><br></p>
  </div>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kppygs22 {
      flex: 6 0 0;
    }

    #rd-column-kppygs22 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kppygs22 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-kppygs22" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-joq3m2m2 {
      
          background-color: #bdc132;
        
      border-bottom-style: none;
      border-color: #ffffff;
      border-left-style: none;
      border-radius: 50px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      min-height: auto0px;
      margin-top: 48px;
      margin-bottom: 30px;
      max-width: 450px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-joq3m2m2" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m3 {
      display: block;
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-28px;
      max-width: 357px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-joq3m2m3" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><strong style="font-size: 23px; font-family: &quot;Open Sans&quot;, sans-serif; color: rgb(255, 255, 255);">Preencha os campos abaixo e</strong></p><p style="line-height: 1.25; text-align: center;"><strong style="font-size: 23px; font-family: &quot;Open Sans&quot;, sans-serif; color: rgb(255, 255, 255);">cadastre-se gratuitamente!</strong></p><p style="line-height: 1.25; text-align: center;"><br></p>
</div>






<style type="text/css" rel="stylesheet">
  

  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #ffffff;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #ffffff;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #ffffff;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      text-decoration: none;
    }

    #rd-form-joq3m2m5 label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__field[data-step].step-hidden {
      display: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #ffffff;
      border-radius: 13px;
      border-width: 1px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        ;
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  

  
</style>


<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 input[type="date"] {
      -webkit-appearance: none;
    }
  
</style>

<div
  id="rd-form-joq3m2m5"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup]="profileForm" (ngSubmit)="onSubmit()"         
    novalidate="novalidate"
    action="https://cta-redirect.rdstation.com/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb250ZXVkb3MuY29jb25saW5lLmNvbS5ici9hZ3JhZGVjaW1l
bnRvLWNvbmNvYy1vbmxpbmUtMjAyNC0y
"
    data-ga-ua-id=""
    data-recaptcha-public-token=""
  >
    <input type="hidden" name="token_rdstation" value="fc4e496744bb9cfcf9fd52eb4269d348">
    <input type="hidden" name="conversion_identifier" value="concoc-semiextensivo-2024-2">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    

    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
  data-multi-step="false"
>
  

<div class="bricks-form__field " data-step="1">
  <label for="rd-text_field-lxytumb4" class="bricks-form__label">
    Nome*
  </label>
  <input
    id="rd-text_field-lxytumb4"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
    (change)= "changeField()" formControlName="nome"
  >
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-email_field-lxytumb5" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-lxytumb5"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    data-always-visible="true"
    data-input-mask=""
    placeholder="Email *"
    required="required"
    (change)= "changeField()" formControlName="email"
  >
</div>


<div class="bricks-form__field with-select-flags " data-step="1">
  <label for="rd-phone_field-lxytumb6" class="bricks-form__label">
    Celular*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-lxytumb6"
      class="bricks-form__input required phone js-phone js-field-mobile_phone"
      name="mobile_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Celular *"
      required="required"
      (change)= "changeField()" formControlName="telefone"
    >
  </div>
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-text_field-lxytumb7" class="bricks-form__label">
    Data de nascimento*
  </label>
  <input
    id="rd-text_field-lxytumb7"
    class="bricks-form__input required js-text js-field-cf_data_de_nascimento"
    name="cf_data_de_nascimento"
    type="text"
    data-use-type="STRING"
    placeholder="Data de nascimento *"
    required="required"
    required="required"  (change)= "changeField()"
  >
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-select_field-lxytumb8" class="bricks-form__label">
    Você é aluno(a) de*
  </label>

  
  
  <select
    id="rd-select_field-lxytumb8"
    class="bricks-form__input required js-field-cf_voce_e_aluno_a_de"
    data-use-type="STRING"
    name="cf_voce_e_aluno_a_de"
    required="required"
    required="required"  (change)= "changeField()"
  >
    <option value="">
      
      Selecione
    </option>
    
      <option value="Escola Pública">Escola Pública</option>
    
      <option value="Escola Particular">Escola Particular</option>
    
  </select>
</div>  


<div class="bricks-form__field " data-step="1">
  <label for="rd-text_field-lxytumb9" class="bricks-form__label">
    Em qual curso superior você deseja ingressar?
  </label>
  <input
    id="rd-text_field-lxytumb9"
    class="bricks-form__input  js-text js-field-cf_em_qual_curso_superior_voce_deseja_ingressar"
    name="cf_em_qual_curso_superior_voce_deseja_ingressar"
    type="text"
    data-use-type="STRING"
    placeholder="Em qual curso superior você deseja ingressar?"
    required="required"  (change)= "changeField()"
    
  >
</div>



  <div class="bricks-form__field " data-step="1">
    <div class="bricks-form__field__option">
      <label class="bricks-form__label">
        <input
          id="rd-checkbox_field-lxytumba"
          name="cf_li_e_aceito_edital_do_concurso_de_bolsas"
          type="checkbox"
          value="1"
          (change)= "changeField()"
          data-use-type="STRING"
          data-always-visible=""
          class="bricks-form__field__option__input required js-field-cf_li_e_aceito_edital_do_concurso_de_bolsas"
          required="required"
          
        >
        Li e concordo com o edital do concurso de bolsas*
      </label>
    </div>
  </div>




  <div class="bricks-form__field  "  data-step="1">
    <label for="captcha" class="bricks-form__label">
      <span #mathexpression id="math_expression">1 + 1 = ?</span>
    </label>
    <input
      id="captcha"
      class="bricks-form__input math rd-bricks-captcha"
      name="captcha" #captcha
      type="text"
      placeholder="1 + 1 = ?"
      formControlName="captcha"
      (keyup)="checkCaptcha(mathexpression.innerHTML, captcha.value);"
    >
  </div>


</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2m7 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2m7.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #3c3776;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 280px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2m7 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>



  

  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7"
    >
      QUERO PARTICIPAR!
    </button>
  </div>
  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7" (click)="avisoCampos()"
    >
      QUERO PARTICIPAR!
    </button>
  </div>



    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>
  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1719832768"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1719832768"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1719832768"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      

      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo && redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-llo44zgw {
      min-height: 50px;
    }

    #rd-row-llo44zgw .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-llo44zgw.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-llo44zgw.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-llo44zgw" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-llo44zgx {
      flex: 12 0 0;
    }

    #rd-column-llo44zgx > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-llo44zgx > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-llo44zgx" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lxxbhth0 {
      margin-top: 37px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 382px;
    }

    #rd-image-lxxbhth0 a,
    #rd-image-lxxbhth0 img {
      width: 382px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lxxbhth0 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lxxbhth0" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719582093/$fz1lsb8w2sm" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-llo45ejr {
      display: flex;
      flex-direction: column;
    }

    #rd-button-llo45ejr.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #bdc132;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #ffffff;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: bold;
      height: 54px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 245px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-llo45ejr {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://drive.google.com/file/d/1OWXxK7F9D9VJNGaDYlZN0O_7-Lb_8tlA/view?usp=sharing"
    id="rd-button-llo45ejr"
    target="_blank"
    title="Any Title"
  >
    Confira o edital completo!
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxitedp {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lxxitedp.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lxxitedp {
          display: none;
        }

        #rd-text-lxxitedp.mobile {
          display: block;
          max-width: 200px;
          margin-top: -9px;
          margin-bottom: 10px;
        }
      }
    
  
</style>

<div id="rd-text-lxxitedp" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>


  <div id="rd-text-lxxitedp" class="bricks--component bricks--component-text mobile rd-text">
    <p> </p>
  </div>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-llo4865j {
      min-height: 50px;
    }

    #rd-row-llo4865j .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-llo4865j.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-llo4865j.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-llo4865j" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-llo4865k {
      flex: 12 0 0;
    }

    #rd-column-llo4865k > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-llo4865k > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-llo4865k" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l4d5pzl1 {
      margin-top: 15px;
      margin-bottom: -25px;
      align-self: center;
      max-width: 140px;
    }

    #rd-image-l4d5pzl1 a,
    #rd-image-l4d5pzl1 img {
      width: 140px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l4d5pzl1 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l4d5pzl1" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719495641/$6097glh87tt" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-llo48916 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-llo48916" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d24ecbab-6dfd-4592-b8fd-59cfba8ebcfb-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6410886" data-experiment-content-id=""></script>


    

    <div data-editable="true" data-field-name="landing_page"></div>
    <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NM5DHFZ3"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    <style>
      /* FONTE DO TÍTULO*/
@font-face {
    font-family: 'trashhandregular';
    src: local('TrashHand'), url('https://aletech.com.br/trashhand.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#rd-text-kppt77vn h1 span{
    font-family: 'trashhandregular'!important;
    font-size: 79px!important;
    margin-bottom: 12px;
    background: -webkit-linear-gradient(#5548FF, #0A7EFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/*FIM DA FONTE DO TÍTULO*/

/*BOX DA PRIMEIRA SEÇÃO E DA ÚLTIMA SEÇÃO*/

#rd-box-l45zpsgd, #rd-box-l470thna, #rd-box-l470tpm1, #rd-box-l470trgj{
    overflow:hidden;
    border-radius: 15px;
}

/*FIM DO BOX DA PRIMEIRA SEÇÃO*/
#rd-section-l46zb1jt{
    background: linear-gradient(#64c500, #509600);
}

@media (max-width: 600px)
{
  #rd-section-l460eoho, #rd-section-l46367mc{
        background-position:center center;
    }
}

#rd-row-kylxpp37 .rd-box:hover img{
    transform: rotate(720deg) scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover{
    transform: scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box{
    box-shadow: 20px 20px 20px #333;
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover span, #rd-row-kylxpp37 .rd-box:hover strong{
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box, #rd-row-kylxpp37 .rd-box img, #rd-row-kylxpp37 .rd-box span, #rd-row-kylxpp37 .rd-box strong{
    transition: 0.5s all;
}
  #rd-button-joq3m2m7:hover {
    animation: shake 0.5s infinite, borderSpin 3s linear infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
    </style>
    

  </body>
</html>
