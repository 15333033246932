import { Component, OnInit } from '@angular/core';
import { GtmService } from 'src/app/gtm.service';
@Component({
  selector: 'app-agradecimento-concurso-bolsas-op-enem2024',
  templateUrl: './agradecimento-concurso-bolsas-op-enem2024.component.html',
  styleUrls: ['./agradecimento-concurso-bolsas-op-enem2024.component.scss']
})

export class AgradecimentoConcursoBolsasOpEnem2024Component implements OnInit {

  constructor(private gtmService: GtmService) { }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-WFT3R45V');
  }

}
