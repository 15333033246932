import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agradecimentoconcocbalneariojun2024',
  templateUrl: './agradecimentoconcocbalneariojun2024.component.html',
  styleUrls: ['./agradecimentoconcocbalneariojun2024.component.scss']
})
export class Agradecimentoconcocbalneariojun2024Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
