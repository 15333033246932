<header>
    <div></div>
</header>
<section>
    <div><h2><b>Serviço pausado!</b></h2></div> 
    <br>
    <br>
    <div>Em caso de dúvidas fale com o administrador ou entre em contato com o suporte. </div>
    
</section>
