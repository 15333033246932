import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concurso-de-bolsas-santa-clara-challenge-assis',
  templateUrl: './concurso-de-bolsas-santa-clara-challenge-assis.component.html',
  styleUrls: ['./concurso-de-bolsas-santa-clara-challenge-assis.component.scss']
})
export class ConcursoDeBolsasSantaClaraChallengeAssisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
