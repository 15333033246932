import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concurso-de-bolsas-colegio-fato-medio2023',
  templateUrl: './concurso-de-bolsas-colegio-fato-medio2023.component.html',
  styleUrls: ['./concurso-de-bolsas-colegio-fato-medio2023.component.scss']
})
export class ConcursoDeBolsasColegioFatoMedio2023Component implements OnInit {

  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService)
  { 
    this.landRoute          = "concurso-de-bolsas-colegio-fato-medio-2023";
    this.urlRedirect        = "https://fatoeducacional.notamaxima.com.br/redirect";
    this.landRouteNavigate  = "./concurso-de-bolsas-colegio-fato-confirmacao";
    }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      alunode: new FormControl(''), 
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')
    
    });
    this.getLandClass();
  }
  getLandClass(){
    this.concocService.getLandRoute(this.landRoute)
    .subscribe(async res => {
        const response = res.body as ResponseApi; 
        if (!response.error) {
          this.landClasses = response.data;  
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-l83f5p19")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {
    if(this.botaoEnviar){
      // TODO: Use EventEmitter with form value
      var data = this.profileForm.getRawValue(); 
      if(data.turmaqueentra == "Ensino Médio 1º Ano 2023"){
        this.pv = '1151235cde6292fdc5b3bdfed4484eba';
      }else if(data.turmaqueentra == "Ensino Médio 2º Ano 2023"){
        this.pv = '274e6645e2cc9844a81a9e6076b52666';
      }else if(data.turmaqueentra == "Ensino Médio 3º Ano 2023"){
        this.pv = '8b84600f7a7b5e91d519d3e826616894';
      }      
      var jsondata = {
        name : data.nome,
        email: data.email, 
        pass: data.senha,  
        telefone: data.telefone,
        alunode :data.alunode,          
        colegio: data.turma, 
        turmaqueentra: data.turmaqueentra,
        urlRedirect: this.urlRedirect ,
        pv :this.pv
      } 
      this.concocService.registerSave(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
              let url:any = response.data; 
              //await localStorage.setItem('link',url.link);
              this.router.navigate( ['./concurso-de-bolsas-colegio-fato-confirmacao'], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    console.log(data);
    if(data.nome != null && data.email != null && data.senha != null &&
          data.telefone != null && data.turmaqueentra != null && data.alunode != null && data.captcha != null){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}