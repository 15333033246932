
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    

    
      <meta property="og:title" content="Preparatório ENEM Gratuito - 100% On-Line" />
      <meta name="twitter:title" content="Preparatório ENEM Gratuito - 100% On-Line" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/preparatorio-enem-gratuito-2024" />
    

    <title>Preparatório ENEM Gratuito - 100% On-Line</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WFT3R45V');</script>
<!-- End Google Tag Manager -->

    
  </head>
  <body>
    
      <input type="hidden" id="ga4-measurement-id" value="">
      <input type="hidden" id="has-gtm" value="false">
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2lv {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$vjaqu0ss07j), linear-gradient(to bottom, rgba(0,38,85,0.61) 0%, rgba(0,38,85,0.61) 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #BBBBBB;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
    }

    #rd-section-joq3m2lv > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2lv" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-jt7iat68 {
      min-height: 50px;
    }

    #rd-row-jt7iat68 .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-jt7iat68.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-jt7iat68.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-jt7iat68" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jt7iat69 {
      flex: 12 0 0;
    }

    #rd-column-jt7iat69 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jt7iat69 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-jt7iat69" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-joq3m2lu {
      margin-top: 30px;
      margin-bottom: 5px;
      align-self: center;
      max-width: 192px;
    }

    #rd-image-joq3m2lu a,
    #rd-image-joq3m2lu img {
      width: 192px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-joq3m2lu {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-joq3m2lu" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$s42vjdw8ugg" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-lv3vqa70 {
      margin-top: 0px;
      margin-bottom: 13px;
      align-self: center;
      max-width: 525px;
    }

    #rd-image-lv3vqa70 a,
    #rd-image-lv3vqa70 img {
      width: 525px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lv3vqa70 {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lv3vqa70 {
          margin-top: 0px;
          margin-bottom: 13px;
          align-self: center;
          max-width: 351px;
        }

        #rd-image-lv3vqa70 a,
        #rd-image-lv3vqa70 img {
          width: 351px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lv3vqa70" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$g0jyqb5sg6a" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-lv3vtoxn {
      margin-top: 14px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 569px;
    }

    #rd-image-lv3vtoxn a,
    #rd-image-lv3vtoxn img {
      width: 569px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lv3vtoxn {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lv3vtoxn {
          margin-top: 14px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 350px;
        }

        #rd-image-lv3vtoxn a,
        #rd-image-lv3vtoxn img {
          width: 350px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lv3vtoxn" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$e60qnya1kht" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2lw {
      min-height: 50px;
    }

    #rd-row-joq3m2lw .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-joq3m2lw.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2lw.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2lw" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2lx {
      flex: 6 0 0;
    }

    #rd-column-joq3m2lx > div {
      
            background-image: url(), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: initial;
            background-attachment: scroll;
          
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2lx > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-joq3m2lx" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lv3uf786 {
      margin-top: 29px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 229px;
    }

    #rd-image-lv3uf786 a,
    #rd-image-lv3uf786 img {
      width: 229px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lv3uf786 {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lv3uf786 {
          margin-top: 10px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 215px;
        }

        #rd-image-lv3uf786 a,
        #rd-image-lv3uf786 img {
          width: 215px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lv3uf786" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$1wzgvhe6p8i" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-lv3v7ega {
      margin-top: -47px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 248px;
    }

    #rd-image-lv3v7ega a,
    #rd-image-lv3v7ega img {
      width: 248px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lv3v7ega {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lv3v7ega" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$xexprs3sgw" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lv3x3npk {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 420px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lv3x3npk" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: justify;"><span style="font-family: Lato, sans-serif; font-size: 20px; color: rgb(255, 255, 255);">Além de se preparar com um time de professores</span><strong style="font-family: Lato, sans-serif; font-size: 20px; color: rgb(255, 255, 255);"> especialistas</strong><span style="font-family: Lato, sans-serif; font-size: 20px; color: rgb(255, 255, 255);">, você pode concorrer a um kit com material didático impresso + camiseta COC!</span></p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-lv45a9n4 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 420px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lv45a9n4" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: justify;"><span style="font-family: Lato, sans-serif; font-size: 17px; color: rgb(255, 255, 255);">Exclusivo para estudantes da 3ª série do Ensino Médio de escolas públicas.</span></p>
</div>



  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2m1 {
      flex: 6 0 0;
    }

    #rd-column-joq3m2m1 > div {
      
            background-image: url(), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: initial;
            background-attachment: scroll;
          
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2m1 > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-joq3m2m1 > div {
          padding-top: 0px;
          padding-right: 30px;
          padding-bottom: 0px;
          padding-left: 30px;
        }
      }
    
  
</style>

<div id="rd-column-joq3m2m1" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-joq3m2m2 {
      
          background-color: #1e421a;
        
      border-bottom-style: none;
      border-color: #4b5677;
      border-left-style: none;
      border-radius: 6px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      min-height: auto0px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-joq3m2m2" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m3 {
      display: block;
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 360px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-joq3m2m3" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(206, 227, 38); font-family: &quot;Source Sans Pro&quot;, sans-serif; font-size: 36px;">Cadastre-se AGORA!</strong></p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-lv43kvvw {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 319px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lv43kvvw.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lv43kvvw {
          display: none;
        }

        #rd-text-lv43kvvw.mobile {
          display: block;
          max-width: 343px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    
  
</style>

<div id="rd-text-lv43kvvw" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 18px;">Somente os </span><strong style="color: rgb(255, 255, 255); font-size: 18px;">30 primeiros inscritos</strong><span style="color: rgb(255, 255, 255); font-size: 18px;"> de cada escola concorrerão aos kits.</span></p>
</div>


  <div id="rd-text-lv43kvvw" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 18px;">Somente os </span><strong style="color: rgb(255, 255, 255); font-size: 18px;">30 primeiros inscritos</strong><span style="color: rgb(255, 255, 255); font-size: 18px;"> de cada escola concorrerão aos kits.</span></p>
  </div>





<style type="text/css" rel="stylesheet">
  

  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #FFFFFF;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #FFFFFF;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #FFFFFF;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      text-decoration: none;
    }

    #rd-form-joq3m2m5 label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__field[data-step].step-hidden {
      display: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #a73131;
      border-radius: 4px;
      border-width: 0px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        ;
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  

  
</style>


<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 input[type="date"] {
      -webkit-appearance: none;
    }
  
</style>

<div
  id="rd-form-joq3m2m5"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup]="profileForm" (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    action="https://cta-redirect.rdstation.com/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb250ZXVkb3Mubm90YW1heGltYS5jb20uYnIvYWJyLTIwMjQt
cHJlcGFyYXRvcmlvLWVuZW0tZ3JhdHVpdG8tYWdyYWRlY2ltZW50bw==
"
    data-ga-ua-id="UA-143747841-1"
    data-recaptcha-public-token=""
  >
    <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
    <input type="hidden" name="conversion_identifier" value="preparatorio-enem-gratuito-2024">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    

    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
  data-multi-step="false"
>
  

<div class="bricks-form__field " data-step="1">
  <label for="rd-text_field-lv5mvkzf" class="bricks-form__label">
    Nome*
  </label>
  <input
    id="rd-text_field-lv5mvkzf"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
    (change)= "changeField()" formControlName="nome"
  >
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-email_field-lv5mvkzg" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-lv5mvkzg"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    data-always-visible="true"
    data-input-mask=""
    placeholder="Email *"
    required="required"
    (change)= "changeField()" formControlName="email"
  >
</div>


<div class="bricks-form__field with-select-flags " data-step="1">
  <label for="rd-phone_field-lv5mvkzh" class="bricks-form__label">
    Celular*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-lv5mvkzh"
      class="bricks-form__input required phone js-phone js-field-mobile_phone"
      name="mobile_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Celular *"
      required="required"
      (change)= "changeField()" formControlName="telefone"
    >
  </div>
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-text_field-lv5mvkzi" class="bricks-form__label">
    Qual o nome da sua escola?
  </label>
  <input
    id="rd-text_field-lv5mvkzi"
    class="bricks-form__input  js-text js-field-cf_nome_da_sua_escola"
    name="custom_fields[2632145]"
    type="text"
    data-use-type="STRING"
    placeholder="Qual o nome da sua escola?"
    required="required"  (change)= "changeField()"
  >
</div>


<div class="bricks-form__field " data-step="1">
  <label for="rd-select_field-lv5mvkzj" class="bricks-form__label">
    Em qual curso superior você deseja ingressar?*
  </label>

  
  
  <select
    id="rd-select_field-lv5mvkzj"
    class="bricks-form__input required js-field-cf_curso_superior"
    data-use-type="STRING"
    name="custom_fields[2632400]"
    required="required"
    required="required"  (change)= "changeField()"
  >
    <option value="">
      
      Selecione
    </option>
    
      <option value="Medicina">Medicina</option>
    
      <option value="Engenharia">Engenharia</option>
    
      <option value="Direito">Direito</option>
    
      <option value="Administração">Administração</option>
    
      <option value="Outros">Outros</option>
    
  </select>
</div>  


<div class="bricks-form__field " data-step="1">
  <label for="rd-select_field-lv5mvkzk" class="bricks-form__label">
    Qual o colégio COC mais próximo?*
  </label>

  
  
  <select
    id="rd-select_field-lv5mvkzk"
    class="bricks-form__input required js-field-cf_qual_o_colegio_coc_mais_proximo"
    data-use-type="STRING"
    name="custom_fields[2631419]"
    required="required"
    required="required"  (change)= "changeField()"
  >
    <option value="">
      
      Selecione
    </option>
    
      <option value="COC - Balneário Camboriú">COC - Balneário Camboriú</option>
    
      <option value="COC - Blumenau">COC - Blumenau</option>
    
      <option value="COC - Lages">COC - Lages</option>
    
      <option value="COC - Rio do Sul">COC - Rio do Sul</option>
    
  </select>
</div>  




  <div class="bricks-form__field  "  data-step="1">
    <label for="captcha" class="bricks-form__label">
      <span  #mathexpression  id="math_expression">1 + 1 = ?</span>
    </label>
    <input
      id="captcha" #captcha
      class="bricks-form__input math rd-bricks-captcha"
      name="captcha"
      type="text"
      placeholder="1 + 1 = ?"
      formControlName="captcha"
      (keyup)="checkCaptcha(mathexpression.innerHTML, captcha.value);"
    >
  </div>


</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2m7 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2m7 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #cee326;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #1e421a;
      cursor: pointer;
      font-family: Lato, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 349px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2m7 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>



  

  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7"
    >
      Quero garantir minha vaga!
    </button>
  </div>

  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7" (click)="avisoCampos()"
    >
      Quero garantir minha vaga!
    </button>
  </div>


    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>
  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1713468452"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1713468452"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1713468452"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/preparatorio-enem-gratuito-2024/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/preparatorio-enem-gratuito-2024/conversao'); } catch(err) { }
        }
      

      
        alert("Obrigado!");
      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo && redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lv3vsa12 {
      min-height: 50px;
    }

    #rd-row-lv3vsa12 .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lv3vsa12.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lv3vsa12.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lv3vsa12" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lv3vsa13 {
      flex: 12 0 0;
    }

    #rd-column-lv3vsa13 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lv3vsa13 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lv3vsa13" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lv3vsbxq {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 200px;
    }

    #rd-image-lv3vsbxq a,
    #rd-image-lv3vsbxq img {
      width: 200px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lv3vsbxq {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lv3vsbxq" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1713356595/$xc529qsxeln" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6069863" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', uuid, 'auto');
      ga('send', 'pageview');

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);

      if (idsDataset.experimentContentId) {
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    window.addEventListener('load', function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    })
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFT3R45V"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    <style>
      .rd-button:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
}
    </style>
    

  </body>
</html>
