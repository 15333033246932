import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';
import { GtmService } from 'src/app/gtm.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
export interface Profile {
  nome: string;
  email: string;
  cf_voce_e_aluno_de_coc: string;
  telefone: string;
  captcha: number;
}

@Component({
  selector: 'app-riodosul',
  templateUrl: './riodosul.component.html',
  styleUrls: ['./riodosul.component.scss']
})
export class RiodosulComponent implements OnInit {
  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
    private router : Router,
    private formBuilder: FormBuilder,
    private  concocService: ConcocService,
    private gtmService: GtmService)
  { 
  this.landRoute          = "concoc-rio-do-sul";
  this.urlRedirect        = "https://plataforma.notamaxima.com.br";
  this.landRouteNavigate  = "./presencial/concoc-confirmacao-rio-do-sul";
  }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-KJ5WVH4N');
    this.profileForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      cf_voce_e_aluno_de_coc: ['', Validators.required],
      telefone: ['', Validators.required],
      captcha: ['', Validators.required]
    });
  }

  async onSubmit() {
    setTimeout(() => {
 
      if(this.botaoEnviar){
        this.router.navigate( ['./presencial/concoc-confirmacao-rio-do-sul']);
        var data = this.profileForm.getRawValue() as Profile;
        var jsondata = {
          name : data.nome,
          email: data.email, 
          pass: 'alunoCOC2024',   
          telefone: data.telefone,
          turmaqueentra: '20eccd7fc8f83f8bee745c6b6c98b52b',
          studentFrom: data.cf_voce_e_aluno_de_coc, 
          urlRedirect: this.urlRedirect ,
          pv :'20eccd7fc8f83f8bee745c6b6c98b52b'
        }
        
          this.concocService.registerSave(jsondata)
          .subscribe(async res => {
              const response = res.body as ResponseApi;
              if (!response.error) { 
                  let url:any = response.data; 
                  this.router.navigate( ['./presencial/concoc-confirmacao-rio-do-sul'], {queryParams:{ link : btoa(url.link) } })
              }
          }, err => console.log("Verifique sua conexão com a internet!"));
        
        
      }  
    }, 2000);
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue() as Profile;
    console.log(data);
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null && !this.hasDotCom(data.email)){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
 
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  hasDotCom(email) { 
    const atIndex = email.indexOf('.com');   
    if (atIndex !== -1) {
      const domain = email.slice(atIndex + 1);
      return domain.endsWith('.com');
    }
    const atIndex2= email.indexOf('.com.br');   
    if (atIndex2 !== -1) {
      const domain = email.slice(atIndex2 + 1);
      return domain.endsWith('.com.br');
    }     
    return false;
  }
}