import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
export interface Profile {
  nome: string;
  email: string;
  telefone: string;
  captcha: number;
} 

@Component({
  selector: 'app-simulado-acafe',
  templateUrl: './simulado-acafe.component.html',
  styleUrls: ['./simulado-acafe.component.scss']
})
export class SimuladoAcafeComponent implements OnInit {
  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private formBuilder: FormBuilder,
              private  concocService: ConcocService)
  { 
    this.landRoute          = "2023-simulado-acafe";
    this.urlRedirect        = "https://plataforma.notamaxima.com.br";
    this.landRouteNavigate  = "./2023-simulado-acafe-agradecimento";
    }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      //city_id: ['', Validators.required],
      telefone: ['', Validators.required],
      captcha: ['', Validators.required]
    });
  }

  async onSubmit() {
    if(this.botaoEnviar){
      this.router.navigate( ['./2023-simulado-acafe-agradecimento']);
      var data = this.profileForm.getRawValue() as Profile;
      var jsondata = {
        name : data.nome,
        email: data.email, 
        pass: 'Acafe2024',   
        telefone: data.telefone,
        turmaqueentra: 'ecd83eee3e2ed6b8f6585524e962e501',
        //whereYouFrom: data.city_id, 
        urlRedirect: this.urlRedirect ,
        pv :'ecd83eee3e2ed6b8f6585524e962e501'
      } 
      this.concocService.registerSave(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
              let url:any = response.data; 
              this.router.navigate( ['./2023-simulado-acafe-agradecimento'], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue() as Profile;
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}