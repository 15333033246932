import { Component, OnInit } from '@angular/core'; 
import { FormGroup, FormControl } from '@angular/forms';

import { ConcocService } from '../concoc.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-concoc-hawking',
  templateUrl: './concoc-hawking.component.html',
  styleUrls: ['./concoc-hawking.component.scss']
})
export class ConcocHawkingComponent implements OnInit {

  profileForm: FormGroup;
  botaoEnviar: boolean;
  constructor(  private route: ActivatedRoute,
                private router : Router,
                private concocService: ConcocService
  ) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl('')
    
    });  
  }
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    var data = this.profileForm.getRawValue(); 
     
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          urlRedirect: "https://hawking.coconline.com.br/redirect",
          pv :"6a1bc320a31378731f69af4ccd1edfc1"
        }
        this.concocService.register(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( ['/concoc-hawking-confirmacao'], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a")); 
      
  }  

}
