import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acafe-agradecimento',
  templateUrl: './acafe-agradecimento.component.html',
  styleUrls: ['./acafe-agradecimento.component.scss']
})
export class AcafeAgradecimentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
