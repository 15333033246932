<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Participe do Concurso de Bolsas do Fato que dará bolsas de até 100% para você estudar em um cursinho de qualidade, com toda a infraestrutura e tecnologia para garantir o seu futuro!">
    

    
      <meta property="og:title" content="Concurso de Bolsas Pré-Vestibular 2023 - Colégio Fato mais" />
      <meta name="twitter:title" content="Concurso de Bolsas Pré-Vestibular 2023 - Colégio Fato mais" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    
      <meta property="og:description" content="Participe do Concurso de Bolsas do Fato que dará bolsas de até 100% para você estudar em um cursinho de qualidade, com toda a infraestrutura e tecnologia para garantir o seu futuro!" />
      <meta name="twitter:description" content="Participe do Concurso de Bolsas do Fato que dará bolsas de até 100% para você estudar em um cursinho de qualidade, com toda a infraestrutura e tecnologia para garantir o seu futuro!" />
    

    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/concurso-de-bolsas-colegio-fato-pre-vestibular" />
    

    <title>Concurso de Bolsas Pré-Vestibular 2023 - Colégio Fato mais</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript"> </script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vm {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1663249893/$svhvk93t4q), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #585858;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vm > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2vm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l8345jy2 {
      min-height: 50px;
    }

    #rd-row-l8345jy2 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l8345jy2.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l8345jy2" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l8345jy3 {
      flex: 12 0 0;
    }

    #rd-column-l8345jy3 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l8345jy3 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l8345jy3" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kfgt0rfb {
      margin-top: 40px;
      margin-bottom: 40px;
      align-self: center;
      max-width: 544px;
    }

    #rd-image-kfgt0rfb a,
    #rd-image-kfgt0rfb img {
      width: 544px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kfgt0rfb {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-kfgt0rfb" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1663249893/$w00ylkbkcj" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2vn {
      min-height: 408px;
    }

    #rd-row-joq3m2vn .bricks--column > div {
      min-height: 408px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2vn.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2vn" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jruwfhaa {
      flex: 6 0 0;
    }

    #rd-column-jruwfhaa > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jruwfhaa > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-jruwfhaa" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt40fg {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 460px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt40fg" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="color: rgb(255, 255, 255);">Concurso de Bolsas para o Pré-Vestibular do Colégio Fato Mais</strong></h1>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-box-kfpf77gs {
      
          background-color: #d71920;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: auto-48px;
      margin-top: 20px;
      margin-bottom: 10px;
      max-width: 425px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-kfpf77gs" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfpf40bp {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfpf40bp" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="font-family: 'Open Sans', sans-serif; color: rgb(255, 255, 255); font-size: 16px;">Bolsas de 100, 80, 70, 50, 30% para você!</strong></h4>
</div>



</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt4xaq {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt4xaq" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><span style="font-family: 'Open Sans', sans-serif; font-size: 14px; color: rgb(255, 255, 255);">Participe do Concurso de Bolsas do Fato que dará bolsas de até 100% para você estudar em um cursinho de qualidade, com toda a infraestrutura e tecnologia para garantir o seu futuro!</span></p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt588q {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt588q" class="bricks--component bricks--component-text rd-text">
  <h4 style="text-align: center; line-height: 1.25;"><strong style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: rgb(255, 255, 255);">Inscrições até dia 07 de Outubro de 2022</strong></h4><p style="text-align: center; line-height: 1.25;"><strong style="font-family: 'Open Sans', sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Prova ON-LINE no dia 08 de Outubro a partir das 14h</strong></p>
</div>




<style type="text/css" rel="stylesheet">
  
    a#rd-button-l834h66b {
      display: flex;
      flex-direction: column;
    }

    #rd-button-l834h66b {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(17,221,94,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Ubuntu, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 20px;
      max-width: 260px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-l834h66b {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://drive.google.com/file/d/1ZVORBSGARGZCvdX5ysk1McHAIxjS8cGw/view?usp=sharing"
    id="rd-button-l834h66b"
    target="_blank"
    title=""
  >
    CONFIRA O EDITAL COMPLETO!
  </a>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2vu {
      flex: 6 0 0;
    }

    #rd-column-joq3m2vu > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2vu > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-joq3m2vu" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-kfgt264b {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 6px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 25px;
      padding-right: 30px;
      padding-bottom: 25px;
      padding-left: 30px;
      min-height: -1px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-kfgt264b" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt7h9g {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt7h9g" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="font-size: 18px; color: rgb(56, 56, 56); font-family: 'Open Sans', sans-serif;">Preencha o formulário abaixo, participe do concurso e tenha a chance de ganhar</strong></h2>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt9jfg {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt9jfg" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="font-size: 25px; color: rgb(56, 56, 56); font-family: 'Open Sans', sans-serif; background-color: transparent;"> BOLSAS DE ATÉ 100% </strong></h2>
</div>






<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2vy {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2vy .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2vy .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2vy .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2vy .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2vy .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2vy .bricks-form__static {
      color: #383838;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2vy .bricks-form__static a {
      color: #383838;
      text-decoration: underline;
    }

    #rd-form-joq3m2vy .bricks-form__label {
      color: #383838;
      display: inline-block;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      text-decoration: none;
    }

    #rd-form-joq3m2vy label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2vy .bricks-form__label.always-visible,
    #rd-form-joq3m2vy .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2vy .bricks-form__input {
      color: #383838;
      background-color: #ffffff;
      border-bottom-style: solid;
      border-left-style: solid;
      border-right-style: solid;
      border-top-style: solid;
      border-color: #c6c6c6;
      border-radius: 4px;
      border-width: 1px;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        ;
    }

    #rd-form-joq3m2vy .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2vy .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #383838 !important;
      font-family: Open Sans, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2vy .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2vy .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  

  
</style>


<div
  id="rd-form-joq3m2vy"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup]="profileForm"
    (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    action="https://gyruss.rdops.systems/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb250ZXVkb3Mubm90YW1heGltYS5jb20uYnIvY29uY3Vyc28t
ZGUtYm9sc2FzLWNvbGVnaW8tZmF0by1jb25maXJtYWNhbw==
"
    data-ga-ua-id="UA-143747841-1"
  >

    <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
    <input type="hidden" name="conversion_identifier" value="concurso-de-bolsas-colegio-fato-pre-vestibular">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    


    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
>
  <div class="bricks-form__field">
  <label for="rd-text_field-b7aFDtRMo5KfVCMRvmaK9Q" class="bricks-form__label">
    Nome*
  </label>
  <input
    id="rd-text_field-b7aFDtRMo5KfVCMRvmaK9Q"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
    (change)= "changeField()"
    formControlName="nome">
</div>
<div class="bricks-form__field">
  <label for="rd-email_field-4KqRu76piSm0My0hAz6emg" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-4KqRu76piSm0My0hAz6emg"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    placeholder="Email *"
    required="required"
    (change)= "changeField()"
    formControlName="email">
</div>
<div class="bricks-form__field">
    <label for="rd-senha_field-kcng8yh5" class="bricks-form__label">
      Crie a sua senha*
    </label>
    <input
      formControlName="senha"
      id="rd-senha_field-kcng8yh5"
      class="bricks-form__input required js-senha js-field-senha"
      name="senha"
      type="senha"
      data-use-type="STRING"
      placeholder="Senha *"
      required="required">
  </div> 
<div class="bricks-form__field with-select-flags">
  <label for="rd-phone_field-P5KVcmABB_JFqcckEK4dwQ" class="bricks-form__label">
    Telefone*
  </label>
  <div class="phone-input-group">
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    <input
      id="rd-phone_field-P5KVcmABB_JFqcckEK4dwQ"
      class="bricks-form__input required phone js-phone js-field-personal_phone"
      name="personal_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Telefone *"
      required="required"
      (change)= "changeField()"
        formControlName="telefone">
  </div>
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-3z19SZAKBRR7FiYLeDG5cw" class="bricks-form__label">
    Colégio aonde estuda*
  </label>
  <input
    id="rd-text_field-3z19SZAKBRR7FiYLeDG5cw"
    class="bricks-form__input required js-text js-field-cf_colegio_aonde_estuda"
    name="custom_fields[1168017]"
    type="text"
    data-use-type="STRING"
    placeholder="Colégio aonde estuda *"
    required="required"
    (change)= "changeField()"
    formControlName="turma">
</div>
<div class="bricks-form__field">
  <label for="rd-select_field-WN8Gz5DeafCKzKRoRGpDQA" class="bricks-form__label">
    Você é aluno(a) de*
  </label>

  
  
  <select
    id="rd-select_field-WN8Gz5DeafCKzKRoRGpDQA"
    class="bricks-form__input required js-field-cf_categoria_concoc_fato"
    data-use-type="STRING"
    (change)= "changeField()"
    formControlName="alunode" 
    name="custom_fields[1184295]"
    required="required">
    <option value="">
      Selecione
    </option>
      <option value="Escola Pública" value="Escola Pública" >Escola Pública</option>
      <option value="Escola Privada" value="Escola Privada">Escola Privada</option>
      <option value="Aluno(a) Fato" value="Aluno(a) Fato">Aluno(a) Fato</option>
    
  </select>
</div>  


<div class="bricks-form__field " >
  <div class="bricks-form__field__option">
    <label class="bricks-form__label">
      <input
        id="rd-checkbox_field-bc6yvqfIGLkQze1K5YfXJw"
        name="custom_fields[1161112]"
        type="checkbox"
        onclick="document.getElementById('rd-checkbox_field-bc6yvqfIGLkQze1K5YfXJw').checked = true;"
        (change)= "changeField()"
        value="1"
        data-use-type="STRING"
        data-always-visible=""
        data-legal-base-type=""
        class="bricks-form__field__option__input required js-field-cf_li_e_concordo_com_o_edital_do_concoc"
        required="required" checked readonly>
      Li e concordo com o edital do concurso de bolsas*
    </label>
  </div>
</div>
<div class="bricks-form__field">
  <label for="captcha" class="bricks-form__label">
    <span #mathexpression id="math_expression">1 + 1 = ?</span>
  </label>
  <input
    id="captcha" #captcha
    class="bricks-form__input math rd-bricks-captcha"
    (keyup)="checkCaptcha(mathexpression.innerHTML , captcha.value);"
    name="captcha"
    type="text"
    placeholder="1 + 1 = ?" formControlName="captcha">
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2w0 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2w0 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #11dd5e;
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      color: #ffffff;
      cursor: pointer;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 350px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2w0 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <div class="bricks-form__submit"  *ngIf="buttonActive">
    <button class="bricks--component bricks--component-button rd-button" id="rd-button-joq3m2w0"> QUERO PARTICIPAR!</button>
  </div>
  <div class="bricks-form__submit"  *ngIf="!buttonActive">
    <button class="bricks--component bricks--component-button rd-button" id="rd-button-joq3m2w0" (click)= "avisoCampos()"> QUERO PARTICIPAR!</button>
  </div>


    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>

  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1663268106"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1663268106"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1663268106"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/concurso-de-bolsas-colegio-fato-pre-vestibular/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/concurso-de-bolsas-colegio-fato-pre-vestibular/conversao'); } catch(err) { }
        }
      

      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


<style type="text/css" rel="stylesheet">
  
    #rd-text-kfh10oqu {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfh10oqu" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><strong style="font-family: 'Open Sans', sans-serif; color: rgb(56, 56, 56); font-size: 13px;">Confira o edital completo clicando </strong><a href="https://drive.google.com/file/d/1ZVORBSGARGZCvdX5ysk1McHAIxjS8cGw/view?usp=sharing" rel="noopener noreferrer" target="_blank" title="" style="font-family: 'Open Sans', sans-serif; color: rgb(56, 56, 56); font-size: 13px; background-color: transparent;"><strong><u>AQUI</u></strong></a></p>
</div>



</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-js4yspoe {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-js4yspoe > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-js4yspoe" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-js4yspof {
      min-height: 108px;
    }

    #rd-row-js4yspof .bricks--column > div {
      min-height: 108px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-js4yspof.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-js4yspof" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-julgvhmc {
      flex: 12 0 0;
    }

    #rd-column-julgvhmc > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-julgvhmc > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-julgvhmc" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfh1f7ce {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 684px;
      margin-top: 50px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfh1f7ce" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="color: rgb(54, 54, 54); font-family: 'Open Sans', sans-serif; font-size: 20px; background-color: transparent;"> SAIBA PORQUE O FATO É SURPREENDENTE! </strong></h1>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-l834yvox {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 684px;
      margin-top: 0px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l834yvox" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><span style="background-color: rgb(255, 255, 255); font-size: 16px; font-family: 'Open Sans', sans-serif; color: rgb(102, 102, 102);">Venha estudar no melhor cursinho de Cuiabá e se preparar da melhor maneira possível para a vida!</span></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kggsjw4e {
      min-height: 236px;
    }

    #rd-row-kggsjw4e .bricks--column > div {
      min-height: 236px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kggsjw4e.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kggsjw4e" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kggsjw4f {
      flex: 12 0 0;
    }

    #rd-column-kggsjw4f > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kggsjw4f > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-kggsjw4f" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-video-kggsjw4h {
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 500px;
      max-width: 100%;
    }

    #rd-video-kggsjw4h iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  

  
</style>

<div id="rd-video-kggsjw4h" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/dZAlukafLsk?&rel=0&autoplay=0&mute=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kggsl4e0 {
      min-height: 50px;
    }

    #rd-row-kggsl4e0 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kggsl4e0.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kggsl4e0" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kggsl4e1 {
      flex: 12 0 0;
    }

    #rd-column-kggsl4e1 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kggsl4e1 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-kggsl4e1" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-kgqhxy8b {
      display: flex;
      flex-direction: column;
    }

    #rd-button-kgqhxy8b {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(215,25,32,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 40px;
      margin-top: 30px;
      max-width: 275px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kgqhxy8b {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.fatoeducacional.com.br/site/"
    id="rd-button-kgqhxy8b"
    target="_blank"
    title=""
  >
    ACESSE O NOSSO SITE!
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-l83460p6 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l83460p6" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-jrux37c9 {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1663249893/$svhvk93t4q), linear-gradient(to bottom, rgba(143,0,143,1) 0%, rgba(143,0,143,1) 100%);
            background-position-x: 50%;
            background-position-y: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 1px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-jrux37c9 > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-jrux37c9" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-juljjjfd {
      min-height: 50px;
    }

    #rd-row-juljjjfd .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-juljjjfd.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-juljjjfd" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-juljjjfe {
      flex: 12 0 0;
    }

    #rd-column-juljjjfe > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-juljjjfe > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-juljjjfe" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-juljjlsc {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 451px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-juljjlsc" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Ubuntu, sans-serif; color: rgb(255, 255, 255); font-size: 12px;">® Colégio Fato Mais - Todos os direitos reservados - Feito por Nota Máxima</span></p>
</div>



  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="3455292" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', uuid, 'auto');
      ga('send', 'pageview');

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);

      if (idsDataset.experimentContentId) {
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    window.addEventListener('load', function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    })
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
    

  </body>
</html>