import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';
import { GtmService } from 'src/app/gtm.service';
export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}

@Component({
  selector: 'app-concoc-rio-do-sul-em',
  templateUrl: './concoc-rio-do-sul-em.component.html',
  styleUrls: ['./concoc-rio-do-sul-em.component.scss']
})
export class ConcocRioDoSulEmComponent implements OnInit {


  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;

  constructor(private route: ActivatedRoute,
    private router : Router,
    private formBuilder: FormBuilder,
    private  concocService: ConcocService,
    private gtmService: GtmService)
  { 
  this.landRoute          = "ef-concoc-2025-rsl";
  this.urlRedirect        = "https://plataforma.notamaxima.com.br";
  this.landRouteNavigate  = "agradecimento-concoc-2025-rsl";
  }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-KJ5WVH4N');
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      nomeResponsavel: new FormControl(''),
      turmaqueentra: new FormControl(''),
      nascimento: new FormControl(''),
      nome_escola: new FormControl(''),
      escola_publica_ou_particular: new FormControl(''),
      captcha: new FormControl('')
    });
  }

  async onSubmit() {
    setTimeout(() => {
      if(this.botaoEnviar){
        this.router.navigate( ['agradecimento-concoc-2025-rsl']);

        var data = this.profileForm.getRawValue();
        if(data.turmaqueentra == "5º ano do Ensino Fundamental") this.pv = 'c8b7e7ff1e24bf0852cd7e3cddc8a0f1';
        if(data.turmaqueentra == "6º ano do Ensino Fundamental") this.pv = '3d1c358bc0bd58a27e1f49af6be6e48a';
        if(data.turmaqueentra == "7º ano do Ensino Fundamental") this.pv = '6e6230b72e1cc48ebd8be91004001e09';
        if(data.turmaqueentra == "8º ano do Ensino Fundamental") this.pv = '1c7c2ac69e9b0b8a453ab0e4b4cc6ded';
        if(data.turmaqueentra == "9º ano do Ensino Fundamental") this.pv = 'dd1a0a16df9b77031364cb883d55f4fe';

        if(data.turmaqueentra == "1ª série do Ensino Médio") this.pv = 'd31e9fb72539ae780738184babee5970';
        if(data.turmaqueentra == "2ª série do Ensino Médio") this.pv = '9b63bc767d9a8bfd1ce4b0ec8eedc537';
        if(data.turmaqueentra == "Terceirão") this.pv = 'a7e01016c5aeac47675375047938d331';

        data.senha = 'alunoCOC2025';
        data.turma = this.pv;

        var jsondata = {
          name : data.nome,
          email: data.email, 
          pass: 'alunoCOC2025',   
          telefone: data.telefone,
          turmaqueentra: this.pv,

          studentFrom: data.nomeResponsavel,
          studentFrom1: data.nascimento,
          studentFrom2: data.nome_escola,
          studentFrom3: data.escola_publica_ou_particular, 

          urlRedirect: this.urlRedirect,
          pv: this.pv
        }
        this.concocService.registerSave(jsondata)
        .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
            let url:any = response.data; 
            this.router.navigate( ['agradecimento-concoc-2025-rsl'], {queryParams:{email:data.email, link : btoa(url.link) } })
          }
        }, err => console.log("Verifique sua conexão com a internet!"));
      }  
    }, 2000);
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    console.log(data);
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null && !this.hasDotCom(data.email)){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    if(value === Number(response)){
      this.changeField();
    }
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  hasDotCom(email) { 
    const atIndex = email.indexOf('.com');   
    if (atIndex !== -1) {
      const domain = email.slice(atIndex + 1);
      return domain.endsWith('.com');
    }
    const atIndex2= email.indexOf('.com.br');   
    if (atIndex2 !== -1) {
      const domain = email.slice(atIndex2 + 1);
      return domain.endsWith('.com.br');
    }     
    return false;
  }
}
