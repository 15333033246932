<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

      <meta property="og:title" content="Inscrição concluída!" />
      <meta name="twitter:title" content="Inscrição concluída!" />
    
      <meta name="twitter:card" content="summary" />
    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/set-simulado-acafe-agradecimento" />
    <title>Inscrição concluída!</title>

    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>
    <link href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet" />
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript"> </script>

    
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
    </script>
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11251484268"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-11251484268');
      gtag('event', 'conversion', {'send_to': 'AW-11251484268/lkamCOqot-EYEOyMkPUp'});
    </script>

    <!-- Meta Pixel Code -->
      <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1537842026968384');
      fbq('track', 'Lead');
      </script>
      <noscript>
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1537842026968384&ev=PageView&noscript=1" />
      </noscript>
    <!-- End Meta Pixel Code -->
  </head>
  <body>
    <script type="text/javascript">
      (function() {
        var scriptId = "ga-gtag";
  
        if (document.getElementById(scriptId)) return;
  
        var script = document.createElement("script");
        var trackingId = ""
        var lpId = "5029068"
        var options = { send_page_view: !false }
  
        script.setAttribute("id", scriptId);
        script.setAttribute("type", "text/javascript");
        script.setAttribute(
          "src",
          `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
        );
        script.setAttribute("async", "");
  
        document.head.insertBefore(script, document.head.firstChild);
  
        gtag("js", new Date());
        gtag("config", trackingId, options);
        gtag("event", "RD Landing Pages", {
          rd_asset_id: lpId,
          rd_filter: "",
          rd_action: "viewed"
        })
        var experimentContentId = ""
        if(experimentContentId){
          gtag("event", "RD Landing Pages", {
            rd_asset_id: lpId,
            rd_filter: "experiment:"+experimentContentId,
            rd_action: "viewed"
          })
        }
      }());
    </script>
    <div class="bricks--container">
      <div class="bricks--page">
        <style type="text/css" rel="stylesheet">
  
          #rd-section-joq3m2lv {
            
                  background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1693918479/$4r04rsgw192), linear-gradient(to bottom, transparent 0%, transparent 100%);
                  background-position-x: 50%;
                  background-position-y: 50%;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-attachment: fixed;
                
            border-bottom-style: none;
            border-color: #BBBBBB;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 0px;
          }
  
          #rd-section-joq3m2lv > div:not(.bricks--row) {
            min-height: 0px;
          }
  
  
  
        </style>
        <section id="rd-section-joq3m2lv" class="bricks--section rd-section">
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-row-l83b8bbp {
            min-height: 447px;
          }
  
          #rd-row-l83b8bbp .bricks--column > div {
            min-height: 447px;
          }
  
          @media screen and (max-width: 767px) {
            #rd-row-l83b8bbp.bricks--row.rd-row {
              min-height: auto;
            }
          }
  
        </style>
  
        <div id="rd-row-l83b8bbp" class="bricks--row rd-row">
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-column-l83b8bbt {
            flex: 12 0 0;
          }
  
          #rd-column-l83b8bbt > div {
            
                background-color: transparent;
              
            justify-content: center;
            padding-top: 0px;
            padding-right: 25px;
            padding-bottom: 0px;
            padding-left: 25px;
          }
          @media screen and (max-width: 767px) {
            #rd-column-l83b8bbt > div {
              min-height: auto !important;
            }
          }
  
  
  
        </style>
  
        <div id="rd-column-l83b8bbt" class="bricks--column rd-column">
        <div>
          
  
        <style type="text/css" rel="stylesheet">
  
          #rd-image-ll5epi1o {
            margin-top: 10px;
            margin-bottom: 10px;
            align-self: center;
            max-width: 230px;
          }
  
          #rd-image-ll5epi1o a,
          #rd-image-ll5epi1o img {
            width: 230px;
            max-width: 100%;
            height: auto;
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
          }
          @media screen and (max-width: 767px) {
            #rd-image-ll5epi1o {
              align-self: center;
            }
          }
  
  
  
        </style>
  
  
  
        <div id="rd-image-ll5epi1o" class="bricks--component bricks--component-image rd-image">
  
              <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1694540550/$vslu09e7gkn" alt="">
  
  
        </div>
  
        <style type="text/css" rel="stylesheet">
  
          #rd-text-l83b6lv6 {
            display: block;
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
            min-height: auto-136px;
            max-width: 613px;
            margin-top: 10px;
            margin-bottom: 10px;
            align-self: center;
            width: 100%;
            word-break: break-word;
          }
  
  
  
        </style>
  
        <div id="rd-text-l83b6lv6" class="bricks--component bricks--component-text rd-text">
        <p><br></p><p style="text-align: center;"><strong style="font-family: Ubuntu, sans-serif; font-size: 39px; color: rgb(255, 0, 0);">INSCRIÇÃO</strong><strong style="font-family: Ubuntu, sans-serif; font-size: 39px; color: rgb(255, 0, 0); background-color: transparent;"> CONCLUÍDA!</strong></p>
        </div>
  
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-text-l83b8e95 {
            display: block;
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
            min-height: auto0px;
            max-width: 659px;
            margin-top: 40px;
            margin-bottom: 10px;
            align-self: center;
            width: 100%;
            word-break: break-word;
          }
  
  
  
        </style>
  
        <div id="rd-text-l83b8e95" class="bricks--component bricks--component-text rd-text">
        <h1 style="line-height: 1; text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 29px; color: rgb(255, 255, 255);">﻿Verifique a sua </span><span style="font-family: Open Sans, sans-serif; font-size: 29px; color: rgb(255, 255, 255); background-color: rgb(255, 0, 0);">caixa de e-mail,</span><span style="font-family: Open Sans, sans-serif; font-size: 29px; color: rgb(255, 255, 255);"> por lá enviaremos o</span><span style="font-family: Open Sans, sans-serif; font-size: 29px; color: rgb(255, 255, 255); background-color: rgb(255, 0, 0);"> link para você acessar o simulado!</span></h1><p style="line-height: 1; text-align: center;"><br></p><p style="line-height: 1; text-align: center;"><br></p>
        </div>
  
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-text-llax0yyj {
            display: block;
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
            min-height: 0px;
            max-width: 200px;
            margin-top: 10px;
            margin-bottom: 10px;
            align-self: center;
            width: 100%;
            word-break: break-word;
          }
  
  
  
        </style>
  
        <div id="rd-text-llax0yyj" class="bricks--component bricks--component-text rd-text">
        <p><br></p>
        </div>
  
  
  
        </div>
        </div>
  
        </div>
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-row-llax09m8 {
            min-height: 50px;
          }
  
          #rd-row-llax09m8 .bricks--column > div {
            min-height: 50px;
          }
  
          @media screen and (max-width: 767px) {
            #rd-row-llax09m8.bricks--row.rd-row {
              min-height: auto;
            }
          }
  
        </style>
  
        <div id="rd-row-llax09m8" class="bricks--row rd-row">
  
  
        <style type="text/css" rel="stylesheet">
  
          #rd-column-llax09m9 {
            flex: 12 0 0;
          }
  
          #rd-column-llax09m9 > div {
            
                background-color: transparent;
              
            justify-content: center;
            padding-top: 0px;
            padding-right: 10px;
            padding-bottom: 0px;
            padding-left: 10px;
          }
          @media screen and (max-width: 767px) {
            #rd-column-llax09m9 > div {
              min-height: auto !important;
            }
          }
  
  
  
        </style>
  
        <div id="rd-column-llax09m9" class="bricks--column rd-column">
        <div>
          
        <style type="text/css" rel="stylesheet">
  
          #rd-text-llax0oen {
            display: block;
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
            min-height: 0px;
            max-width: 200px;
            margin-top: 10px;
            margin-bottom: 10px;
            align-self: center;
            width: 100%;
            word-break: break-word;
          }
  
  
  
        </style>
  
        <div id="rd-text-llax0oen" class="bricks--component bricks--component-text rd-text">
        <p><br></p>
        </div>
  
  
  
        </div>
        </div>
  
        </div>
  
        </section>
        <style type="text/css" rel="stylesheet">
  
          #rd-section-joq3m2na {
            
                  background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1693918479/$4r04rsgw192), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
                  background-position-x: 0%;
                  background-position-y: 0%;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-attachment: fixed;
                
            border-bottom-style: none;
            border-color: #000000;
            border-left-style: none;
            border-radius: 0px;
            border-right-style: none;
            border-top-style: none;
            border-width: 1px;
          }
  
          #rd-section-joq3m2na > div:not(.bricks--row) {
            min-height: 0px;
          }
  
  
  
        </style>
        <section id="rd-section-joq3m2na" class="bricks--section rd-section">
          <style type="text/css" rel="stylesheet">
  
            #rd-row-joq3m2nb {
              min-height: 50px;
            }
  
            #rd-row-joq3m2nb .bricks--column > div {
              min-height: 50px;
            }
  
            @media screen and (max-width: 767px) {
              #rd-row-joq3m2nb.bricks--row.rd-row {
                min-height: auto;
              }
            }
  
          </style>
          <div id="rd-row-joq3m2nb" class="bricks--row rd-row">
  
  
          <style type="text/css" rel="stylesheet">
  
            #rd-column-joq3m2nc {
              flex: 12 0 0;
            }
  
            #rd-column-joq3m2nc > div {
              
                  background-color: transparent;
                
              justify-content: flex-start;
              padding-top: 0px;
              padding-right: 10px;
              padding-bottom: 0px;
              padding-left: 10px;
            }
            @media screen and (max-width: 767px) {
              #rd-column-joq3m2nc > div {
                min-height: auto !important;
              }
            }
  
  
  
          </style>
  
          <div id="rd-column-joq3m2nc" class="bricks--column rd-column">
          <div>
            
          <style type="text/css" rel="stylesheet">
  
            #rd-text-joq3m2nd {
              display: block;
              border-bottom-style: none;
              border-color: #000000;
              border-left-style: none;
              border-radius: 0px;
              border-right-style: none;
              border-top-style: none;
              border-width: 1px;
              min-height: -1px;
              max-width: 400px;
              margin-top: 15px;
              margin-bottom: 15px;
              align-self: center;
              width: 100%;
              word-break: break-word;
            }
  
  
  
          </style>
  
          <div id="rd-text-joq3m2nd" class="bricks--component bricks--component-text rd-text">
          <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 12px;">Nota Máxima - Todos os direitos reservados</span></p>
          </div>
  
  
  
          </div>
          </div>
  
          </div>
        </section>
      </div>
    </div>  
    <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    <script id="landing-page-attributes" data-id="5029068" data-experiment-content-id=""></script>
    <script id="analytics_tracking_code" type="text/javascript">
      var uuid = 'UA-143747841-1';
      var domain = 'notamaxima.com.br';
  
      function initializeBricksGA() {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  
        ga('create', uuid, 'auto');
        ga('send', 'pageview');
  
        idsDataset = document.getElementById('landing-page-attributes').dataset;
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);
  
        if (idsDataset.experimentContentId) {
          ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
        }
      }
  
      var _gaq = _gaq || [];
  
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        initializeBricksGA();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
      }
    </script>
    <script id="user-ga-interaction" type="text/javascript">
      function addGoogleAnalyticsInteraction() {
        var tags = ['input', 'a', 'button'];
  
        function retrieveAllElements(tags) {
          return tags.reduce(function(prev, curr){
            return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
          }, []);
        }
  
        function addEvents(elements, event) {
          if(!elements) return;
          elements.forEach(function(element) {
            element.addEventListener(event, createInterativeCallback());
          });
        }
  
        function defineLabel(e) {
          var target = e.target || e.currentTarget
          var evtLabel = target.textContent || target.name || target.value || 'default';
          return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
        }
  
        function createInterativeCallback() {
          return function(e){
            var label = defineLabel(e);
             _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
          }
        }
  
        addEvents(retrieveAllElements(tags), 'click');
      }
  
      window.addEventListener('load', function() {
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          addGoogleAnalyticsInteraction();
        } else {
          document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
        }
      })
    </script>
    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
  </body>
</html>