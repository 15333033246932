
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Você se inscreveu para o Preparatório ENEM Gratuito do COC em parceria com o Nota Máxima!">
    

    
      <meta property="og:title" content="Preparatório ENEM Gratuito - Confirmação" />
      <meta name="twitter:title" content="Preparatório ENEM Gratuito - Confirmação" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="http://conteudos.coconline.com.br/confirmacao-enem-gratuito" />
    

    <title>Preparatório ENEM Gratuito - Confirmação</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
      }

      .bricks-form__field {
        width: 100%;
        flex-grow: 1;
      }

      /* Hide generated jQuery validator label */
      .bricks-form__field label.error {
        display: none !important;
      }

      .bricks-form__label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        line-height: 1.42857;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 10px;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .bricks-form__field__option + .bricks-form__field__option {
        margin-top: -5px;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript">
(function(n,v,g){o='Navegg';if(!n[o]){
a=v.createElement('script');a.src=g;b=document.getElementsByTagName('script')[0];
b.parentNode.insertBefore(a,b);n[o]=n[o]||function(parms){
n[o].q=n[o].q||[];n[o].q.push([this, parms])};} })
(window, document, 'https://tag.navdmp.com/universal.min.js');
window.naveggReady = window.naveggReady||[];
window.nvg66694 = new Navegg({
acc: 66694
});
</script>
<script type="text/javascript"> </script>

<!-- Facebook Pixel Code COC LAGES -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2147290972251787');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=2147290972251787&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->

<!-- Facebook Pixel Code COC RIO DO SUL -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '805460306613276');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=805460306613276&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->

<!-- Facebook Pixel Code COC BLUMENAU -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '837587790006952');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=837587790006952&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->

<!-- Facebook Pixel Code COC BC -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '791834984609995');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=791834984609995&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2lv {
      
          background-color: rgba(94,200,213,1);
        
      border-bottom-style: none;
      border-color: #BBBBBB;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
    }

    #rd-section-joq3m2lv > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2lv" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kb9tr38q {
      min-height: 50px;
    }

    #rd-row-kb9tr38q .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kb9tr38q" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kb9tr38r {
      flex: 12 0 0;
    }

    #rd-column-kb9tr38r > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kb9tr38r" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-k9oaa9p7 {
      margin-top: 30px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 539px;
    }

    #rd-image-k9oaa9p7 a,
    #rd-image-k9oaa9p7 img {
      width: 539px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

  
</style>



<div id="rd-image-k9oaa9p7" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1594383218/$upax6ysmcu" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdkx341j {
      min-height: 50px;
    }

    #rd-row-kdkx341j .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdkx341j" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdkx341k {
      flex: 12 0 0;
    }

    #rd-column-kdkx341k > div {
      
          background-color: transparent;
        
      justify-content: flex-end;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdkx341k" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdkx3kd2 {
      min-height: 50px;
    }

    #rd-row-kdkx3kd2 .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdkx3kd2" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdkx3kd3 {
      flex: 12 0 0;
    }

    #rd-column-kdkx3kd3 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdkx3kd3" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kdkx3kd4 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-45px;
      max-width: 872px;
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kdkx3kd4" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Obrigado pela sua inscrição!</strong><span style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;"> 💜</span></h1>
  
  <h1 style="text-align: center;"><span style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Em breve nossa equipe entrará em contato contigo!</span></h1>

  <a
    class="btn blink_me"
    [href]="link" 
    target="_blank"
    title="Any Title"
  > <span  class="btn btn-plataform"> Acesso a Plataforma AQUI!</span>
  </a>

</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdkx3sue {
      min-height: 50px;
    }

    #rd-row-kdkx3sue .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdkx3sue" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdkx3suf {
      flex: 12 0 0;
    }

    #rd-column-kdkx3suf > div {
      
          background-color: transparent;
        
      justify-content: flex-end;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdkx3suf" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdagceza {
      min-height: 50px;
    }

    #rd-row-kdagceza .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdagceza" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdagcezb {
      flex: 12 0 0;
    }

    #rd-column-kdagcezb > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdagcezb" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kdagch3f {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 68px;
    }

    #rd-image-kdagch3f a,
    #rd-image-kdagch3f img {
      width: 68px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

  
</style>



<div id="rd-image-kdagch3f" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1596846223/$38d1d5ypu46" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdaa4vz9 {
      min-height: 50px;
    }

    #rd-row-kdaa4vz9 .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdaa4vz9" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdaa4vza {
      flex: 12 0 0;
    }

    #rd-column-kdaa4vza > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdaa4vza" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kdaf5939 {
      min-height: 50px;
    }

    #rd-row-kdaf5939 .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-kdaf5939" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kdaf5emr {
      flex: 7 0 0;
    }

    #rd-column-kdaf5emr > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdaf5emr" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kdafj8wf {
      margin-top: 35px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 95px;
    }

    #rd-image-kdafj8wf a,
    #rd-image-kdafj8wf img {
      width: 95px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

  
</style>



<div id="rd-image-kdafj8wf" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1596211512/$z2eslwefis8" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kazyqj7h {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 400px;
      margin-top: 9px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kazyqj7h" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Não esqueça de participar do nosso canal exclusivo no Telegram, para ainda mais dicas para o ENEM e vestibulares!</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kazyqs21 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 400px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kazyqs21" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><span style="font-size: 14px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Clique no botão abaixo para participar e receber avisos e atualizações das aulas e novos conteúdos através do Telegram!</span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-kdafk3ot {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #1e96c8;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 40px;
      margin-top: 10px;
      max-width: 205px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://t.me/masterclassenem"
    id="rd-button-kdafk3ot"
    target="_blank"
    title="Any Title"
  >
    QUERO PARTICIPAR!
  </a>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kdaf593a {
      flex: 5 0 0;
    }

    #rd-column-kdaf593a > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 0px;
      padding-left: 30px;
    }
  
</style>

<div id="rd-column-kdaf593a" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kdaffcl3 {
      margin-top: 20px;
      margin-bottom: 40px;
      align-self: center;
      max-width: 250px;
    }

    #rd-image-kdaffcl3 a,
    #rd-image-kdaffcl3 img {
      width: 250px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

  
</style>



<div id="rd-image-kdaffcl3" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1596211512/$6k0y2llryln" alt="">

  
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2na {
      
          background-color: #574b90;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2na > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2na" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2nb {
      min-height: 50px;
    }

    #rd-row-joq3m2nb .bricks--column > div {
      min-height: 50px;
    }
  
</style>

<div id="rd-row-joq3m2nb" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2nc {
      flex: 12 0 0;
    }

    #rd-column-joq3m2nc > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
  
</style>

<div id="rd-column-joq3m2nc" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2nd {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 457px;
      margin-top: 15px;
      margin-bottom: 15px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2nd" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 12px; color: rgb(255, 255, 255);">Todos os direitos reservados - Feito por Nota Máxima</span></p>
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d24ecbab-6dfd-4592-b8fd-59cfba8ebcfb-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="1645184" data-experiment-content-id=""></script>

    <script id="analytics_tracking_code" type="text/javascript">
      var uuid = 'UA-137261937-5';
      var domain = 'coconline.com.br';

      function initializeBricksGA() {
        _gaq.push(['_setAccount', uuid]);
        if (document.domain.indexOf(domain) != -1) {
          _gaq.push(['_setDomainName', domain]);
        }
        _gaq.push(['_setAllowLinker', true]);
        _gaq.push(['_trackPageview']);

        (function() {
          var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
          ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
        })();

        idsDataset = document.getElementById('landing-page-attributes').dataset;
        _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id, null, true]);

        if (idsDataset.experimentContentId) {
          _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId, null, true]);
        }
      }

      var _gaq = _gaq || [];

      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        initializeBricksGA();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
      }
    </script>


      
    <script id="user-ga-interaction" type="text/javascript">
      function addGoogleAnalyticsInteraction() {
        var tags = ['input', 'a', 'button'];

        function retrieveAllElements(tags) {
          return tags.reduce(function(prev, curr){
            return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
          }, []);
        }

        function addEvents(elements, event) {
          if(!elements) return;
          elements.forEach(function(element) {
            element.addEventListener(event, createInterativeCallback());
          });
        }

        function defineLabel(e) {
          var target = e.target || e.currentTarget
          var evtLabel = target.textContent || target.name || target.value || 'default';
          return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
        }

        function createInterativeCallback() {
          return function(e){
            var label = defineLabel(e);
            _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
          }
        }

        addEvents(retrieveAllElements(tags), 'click');
      }

      $(window).on("load", function() {
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          addGoogleAnalyticsInteraction();
        } else {
          document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
        }
      });
    </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
  </body>
</html>
