import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcocService } from '../concoc.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concoc-enem-2022-rio-do-sul-confirmacao',
  templateUrl: './concoc-enem-2022-rio-do-sul-confirmacao.component.html',
  styleUrls: ['./concoc-enem-2022-rio-do-sul-confirmacao.component.scss']
})
export class ConcocEnem2022RioDoSulConfirmacaoComponent implements OnInit {

  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
    //this.link = this.route.queryParams ; 
  }

} 