import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colegio-santa-clara',
  templateUrl: './colegio-santa-clara.component.html',
  styleUrls: ['./colegio-santa-clara.component.scss']
})
export class ColegioSantaClaraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
