import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';
import { GtmService } from 'src/app/gtm.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
export interface Profile {
  nome: string;
  email: string;
  cf_nome_da_sua_escola: string;
  cf_sua_escola_e: string;
  cf_curso_superior: string;
  cf_como_voce_deseja_realizar_a_prova: string;
  cf_como_voce_deseja_cursar_o_preparatorio_enem: string;
  telefone: string;
  captcha: number;
}


@Component({
  selector: 'app-concurso-bolsas-op-enem2024',
  templateUrl: './concurso-bolsas-op-enem2024.component.html',
  styleUrls: ['./concurso-bolsas-op-enem2024.component.scss']
})
export class ConcursoBolsasOpEnem2024Component implements OnInit {
  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
    private router : Router,
    private formBuilder: FormBuilder,
    private  concocService: ConcocService,
    private gtmService: GtmService)
  { 
  this.landRoute          = "preparatorio-enem-gratuito-2024";
  this.urlRedirect        = "https://plataforma.notamaxima.com.br";
  this.landRouteNavigate  = "abr-2024-concoc-preparatorio-enem-agradecimento";
  }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-WFT3R45V');
    this.profileForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      cf_nome_da_sua_escola: ['', Validators.required],
      cf_curso_superior: ['', Validators.required],
      cf_como_voce_deseja_realizar_a_prova: ['', Validators.required],
      telefone: ['', Validators.required],
      captcha: ['', Validators.required]
      
    });
  }

  async onSubmit() {
    setTimeout(() => {
 
      if(this.botaoEnviar){
        this.router.navigate( ['abr-2024-concoc-preparatorio-enem-agradecimento']);
        var data = this.profileForm.getRawValue() as Profile;
        var jsondata = {
          name : data.nome,
          email: data.email, 
          pass: '12345',   
          telefone: data.telefone,
          turmaqueentra: '47cbd1674d027abe8fff4d442907db69',

          studentFrom: data.cf_nome_da_sua_escola,
          studentFrom2: data.cf_curso_superior,
          studentFrom3: data.cf_sua_escola_e,  
          studentFrom4: data.cf_como_voce_deseja_realizar_a_prova,  
          studentFrom5: data.cf_como_voce_deseja_cursar_o_preparatorio_enem,  


 
          urlRedirect: this.urlRedirect ,
          pv :'47cbd1674d027abe8fff4d442907db69'
        }

          this.concocService.registerSave(jsondata)
          .subscribe(async res => {
              const response = res.body as ResponseApi;
              if (!response.error) { 
                  let url:any = response.data; 
                  this.router.navigate( ['abr-2024-concoc-preparatorio-enem-agradecimento'], {queryParams:{email:data.email, link : btoa(url.link) } })
              }
          }, err => console.log("Verifique sua conexão com a internet!"));
        
        
      }  
    }, 2000);
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue() as Profile;
    console.log(data);
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null && !this.hasDotCom(data.email)){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
 
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  hasDotCom(email) { 
    const atIndex = email.indexOf('.com');   
    if (atIndex !== -1) {
      const domain = email.slice(atIndex + 1);
      return domain.endsWith('.com');
    }
    const atIndex2= email.indexOf('.com.br');   
    if (atIndex2 !== -1) {
      const domain = email.slice(atIndex2 + 1);
      return domain.endsWith('.com.br');
    }     
    return false;
  }
}
