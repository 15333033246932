import { Component, OnInit } from '@angular/core';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss']
})
export class ConfirmacaoComponent implements OnInit {

  constructor( 
    private gtmService: GtmService) { }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-KVCMW3WN');
  }

}