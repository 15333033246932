import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agradecimento-preparatorio-enem-gratuito2024',
  templateUrl: './agradecimento-preparatorio-enem-gratuito2024.component.html',
  styleUrls: ['./agradecimento-preparatorio-enem-gratuito2024.component.scss']
})
export class AgradecimentoPreparatorioEnemGratuito2024Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
