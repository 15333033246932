import { ConcocPv2022OnLineComponent } from './concoc-2022/concoc-pv2022-on-line/concoc-pv2022-on-line.component';
import { ConcocEm2022RioDoSulConfirmacaoComponent } from './concoc/concoc-em2022-rio-do-sul-confirmacao/concoc-em2022-rio-do-sul-confirmacao.component';
import { ConcocEm2022RioDoSulComponent } from './concoc/concoc-em2022-rio-do-sul/concoc-em2022-rio-do-sul.component';
import { ConcocEf2022RioDoSulConfirmacaoComponent } from './concoc/concoc-ef2022-rio-do-sul-confirmacao/concoc-ef2022-rio-do-sul-confirmacao.component';
import { ConcocEf2022RioDoSulComponent } from './concoc/concoc-ef2022-rio-do-sul/concoc-ef2022-rio-do-sul.component';
import { ConcocEm2022LagesConfirmacaoComponent } from './concoc/concoc-em2022-lages-confirmacao/concoc-em2022-lages-confirmacao.component';
import { ConcocEm2022LagesComponent } from './concoc/concoc-em2022-lages/concoc-em2022-lages.component';
import { ConcocEf2022LagesConfirmacaoComponent } from './concoc/concoc-ef2022-lages-confirmacao/concoc-ef2022-lages-confirmacao.component';
import { ConcocEf2022LagesComponent } from './concoc/concoc-ef2022-lages/concoc-ef2022-lages.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnemGratuitoRiodosulComponent } from './enem-gratuito/enem-gratuito-riodosul/enem-gratuito-riodosul.component';
import { EnemGratuitoBalnearioComponent } from './enem-gratuito/enem-gratuito-balneario/enem-gratuito-balneario.component';
import { EnemGratuitoBlumenauComponent } from './enem-gratuito/enem-gratuito-blumenau/enem-gratuito-blumenau.component';
import { EnemGratuitoLagesComponent } from './enem-gratuito/enem-gratuito-lages/enem-gratuito-lages.component';
import { ConfirmacaoEnemGratuitoComponent } from './enem-gratuito/confirmacao-enem-gratuito/confirmacao-enem-gratuito.component';
import { ConcocHawkingComponent } from './concoc/concoc-hawking/concoc-hawking.component';
import { ConcocMedioBalnearioComponent } from './concoc/concoc-medio-balneario/concoc-medio-balneario.component';
import { ConcocMedioLagesComponent } from './concoc/concoc-medio-lages/concoc-medio-lages.component';
import { ConcocMedioRiodosulComponent } from './concoc/concoc-medio-riodosul/concoc-medio-riodosul.component';
import { ConcocHawkingConfirmacaoComponent } from './concoc/concoc-hawking-confirmacao/concoc-hawking-confirmacao.component';
import { ConcocMedioBalnearioConfirmacaoComponent } from './concoc/concoc-medio-balneario-confirmacao/concoc-medio-balneario-confirmacao.component';
import { ConcocMedioLagesConfirmacaoComponent } from './concoc/concoc-medio-lages-confirmacao/concoc-medio-lages-confirmacao.component';
import { ConcocMedioRiodosulConfirmacaoComponent } from './concoc/concoc-medio-riodosul-confirmacao/concoc-medio-riodosul-confirmacao.component';
import { ConcocFundamentalLagesComponent } from './concoc/concoc-fundamental-lages/concoc-fundamental-lages.component';
import { ConcocFundamentalRiodosulComponent } from './concoc/concoc-fundamental-riodosul/concoc-fundamental-riodosul.component';
import { ConcocFundamentalRiodosulConfirmacaoComponent } from './concoc/concoc-fundamental-riodosul-confirmacao/concoc-fundamental-riodosul-confirmacao.component';
import { ConcocFundamentalLagesConfirmacaoComponent } from './concoc/concoc-fundamental-lages-confirmacao/concoc-fundamental-lages-confirmacao.component';
import { ConcocMedioBeraldoConfirmacaoComponent } from './concoc/concoc-medio-beraldo-confirmacao/concoc-medio-beraldo-confirmacao.component';
import { ConcocMedioBeraldoComponent } from './concoc/concoc-medio-beraldo/concoc-medio-beraldo.component';
import { ConcocFundamentalBeraldoComponent } from './concoc/concoc-fundamental-beraldo/concoc-fundamental-beraldo.component';
import { ConcocFundamentalBeraldoConfirmacaoComponent } from './concoc/concoc-fundamental-beraldo-confirmacao/concoc-fundamental-beraldo-confirmacao.component';
import { QuimicaInDozeComponent } from './quimica/quimica-in-doze/quimica-in-doze.component';
import { ConfirmacaoQuimicaindozeComponent } from './quimica/confirmacao-quimicaindoze/confirmacao-quimicaindoze.component';
import { ConcursoPrimeComponent } from './prime/concurso-prime/concurso-prime.component';
import { ConcursoPrimeConfirmacaoComponent } from './prime/concurso-prime-confirmacao/concurso-prime-confirmacao.component';
import { ConcursoMedioSigmaComponent } from './concurso-bolsas/concurso-medio-sigma/concurso-medio-sigma.component';
import { ConcursoMedioSigmaConfirmacaoComponent } from './concurso-bolsas/concurso-medio-sigma-confirmacao/concurso-medio-sigma-confirmacao.component';
/* import { ConcursoFundamentalFatoComponent } from './concurso-bolsas/concurso-fundamental-fato/concurso-fundamental-fato.component';
import { ConcursoFundamentalFatoConfirmacaoComponent } from './concurso-bolsas/concurso-fundamental-fato-confirmacao/concurso-fundamental-fato-confirmacao.component';
import { ConcursoMedioFatoComponent } from './concurso-bolsas/concurso-medio-fato/concurso-medio-fato.component';
import { ConcursoMedioFatoConfirmacaoComponent } from './concurso-bolsas/concurso-medio-fato-confirmacao/concurso-medio-fato-confirmacao.component';
import { SimuladoEnemOnlineComponent } from './concurso-bolsas/simulado-enem-online/simulado-enem-online.component';
import { SimuladoEnemOnlineConfirmacaoComponent } from './concurso-bolsas/simulado-enem-online-confirmacao/simulado-enem-online-confirmacao.component';
import { ConcocEf2AsterComponent } from './concurso-bolsas/concoc-ef2-aster/concoc-ef2-aster.component';
import { ConcocAsterEf2ConfirmacaoComponent } from './concurso-bolsas/concoc-aster-ef2-confirmacao/concoc-aster-ef2-confirmacao.component';
import { SimuladoUelSigmaComponent } from './concurso-bolsas/simulado-uel-sigma/simulado-uel-sigma.component';
import { SimuladoUelSigmaConfirmacaoComponent } from './concurso-bolsas/simulado-uel-sigma-confirmacao/simulado-uel-sigma-confirmacao.component';
import { ConcursoEinsteenComponent } from './concurso-bolsas/concurso-einsteen/concurso-einsteen.component';
import { ConcursoEinsteenConfirmacaoComponent } from './concurso-bolsas/concurso-einsteen-confirmacao/concurso-einsteen-confirmacao.component'; */
import { ConcursoMedioVisaoComponent } from './concurso-bolsas/concurso-medio-visao/concurso-medio-visao.component';
import { ConcursoMedioVisaoConfirmacaoComponent } from './concurso-bolsas/concurso-medio-visao-confirmacao/concurso-medio-visao-confirmacao.component';
//import { ConcursoMedioCsaComponent } from './concurso-bolsas/concurso-medio-csa/concurso-medio-csa.component';
//import { ConcursoMedioCsaConfirmacaoComponent } from './concurso-bolsas/concurso-medio-csa-confirmacao/concurso-medio-csa-confirmacao.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConcursoMedioVisao2021HomeComponent } from './concurso-bolsas/concurso-medio-visao2021-home/concurso-medio-visao2021-home.component';
import { ConcursoMedioVisao2021ConfirmacaoComponent } from './concurso-bolsas/concurso-medio-visao2021-confirmacao/concurso-medio-visao2021-confirmacao.component';
import { ConcocEnemBalnearioComponent } from './concoc/concoc-enem-balneario/concoc-enem-balneario.component';
import { ConcocEnem2022BalnearioComponent } from './concoc/concoc-enem-2022-balneario/concoc-enem-2022-balneario.component';
import { ConcocEnem2022BalnearioConfirmacaoComponent } from './concoc/concoc-enem-2022-balneario-confirmacao/concoc-enem-2022-balneario-confirmacao.component';
import { ConcocEnemBlumenauComponent } from './concoc/concoc-enem-blumenau/concoc-enem-blumenau.component';
import { ConcocEnemLagesComponent } from './concoc/concoc-enem-lages/concoc-enem-lages.component';
import { ConcocEnemRioDoSulComponent } from './concoc/concoc-enem-rio-do-sul/concoc-enem-rio-do-sul.component';
import { ConcocEnemBalnearioConfirmacaoComponent } from './concoc/concoc-enem-balneario-confirmacao/concoc-enem-balneario-confirmacao.component';
import { ConcocEnemBlumenauConfirmacaoComponent } from './concoc/concoc-enem-blumenau-confirmacao/concoc-enem-blumenau-confirmacao.component';
import { ConcocEnemLagesConfirmacaoComponent } from './concoc/concoc-enem-lages-confirmacao/concoc-enem-lages-confirmacao.component';
import { ConcocEnemRioDoSulConfirmacaoComponent } from './concoc/concoc-enem-rio-do-sul-confirmacao/concoc-enem-rio-do-sul-confirmacao.component';
//import { ConcursoSemiNotaMaximaComponent } from './concoc/concurso-semi-nota-maxima/concurso-semi-nota-maxima.component';
import { ConcursoSemiNotaMaximaConfirmacaoComponent } from './concoc/concurso-semi-nota-maxima-confirmacao/concurso-semi-nota-maxima-confirmacao.component';
import { CampanhaAlunoNotaDezComponent } from './concoc/campanha-aluno-nota-dez/campanha-aluno-nota-dez.component';
import { CampanhaAlunoNotaDezConfirmacaoComponent } from './concoc/campanha-aluno-nota-dez-confirmacao/campanha-aluno-nota-dez-confirmacao.component';
import { ConcocSemiBalnearioComponent } from './concoc/concoc-semi-balneario/concoc-semi-balneario.component';
import { ConcocSemiBalnearioConfirmacaoComponent } from './concoc/concoc-semi-balneario-confirmacao/concoc-semi-balneario-confirmacao.component';
import { ConcocSemiBlumenauComponent } from './concoc/concoc-semi-blumenau/concoc-semi-blumenau.component';
import { ConcocSemiBlumenauConfirmacaoComponent } from './concoc/concoc-semi-blumenau-confirmacao/concoc-semi-blumenau-confirmacao.component';
import { ConcocSemiLagesComponent } from './concoc/concoc-semi-lages/concoc-semi-lages.component';
import { ConcocSemiLagesConfirmacaoComponent } from './concoc/concoc-semi-lages-confirmacao/concoc-semi-lages-confirmacao.component';
import { ConcocSemiRioDoSulComponent } from './concoc/concoc-semi-rio-do-sul/concoc-semi-rio-do-sul.component';
import { ConcocSemiRioDoSulConfirmacaoComponent } from './concoc/concoc-semi-rio-do-sul-confirmacao/concoc-semi-rio-do-sul-confirmacao.component';
import { OutOfServiceComponent } from './out-of-service/out-of-service.component';
import { SimuladoNacionalEnemComponent } from './enem-gratuito/simulado-nacional-enem/simulado-nacional-enem.component';
import { SimuladoNacionalEnemConfirmacaoComponent } from './enem-gratuito/simulado-nacional-enem-confirmacao/simulado-nacional-enem-confirmacao.component';
import { ConcocPv2022OnLineConfirmacaoComponent } from './concoc-2022/concoc-pv2022-on-line-confirmacao/concoc-pv2022-on-line-confirmacao.component';
import { ConcocEnem2022BlumenauComponent } from './concoc/concoc-enem-2022-blumenau/concoc-enem-2022-blumenau.component';
import { ConcocEnem2022BlumenauConfirmacaoComponent } from './concoc/concoc-enem-2022-blumenau-confirmacao/concoc-enem-2022-blumenau-confirmacao.component';
import { ConcocEnem2022LagesComponent } from './concoc/concoc-enem-2022-lages/concoc-enem-2022-lages.component';
import { ConcocEnem2022LagesConfirmacaoComponent } from './concoc/concoc-enem-2022-lages-confirmacao/concoc-enem-2022-lages-confirmacao.component';
import { ConcocEnem2022RioDoSulComponent } from './concoc/concoc-enem-2022-rio-do-sul/concoc-enem-2022-rio-do-sul.component';
import { ConcocEnem2022RioDoSulConfirmacaoComponent } from './concoc/concoc-enem-2022-rio-do-sul-confirmacao/concoc-enem-2022-rio-do-sul-confirmacao.component';
import { ConcocEnsinoFundamental2023BalnearioComponent } from './concoc-2023/concoc-ensino-fundamental2023-balneario/concoc-ensino-fundamental2023-balneario.component';
import { ConcocEnsinoFundamental2023LagesComponent } from './concoc-2023/concoc-ensino-fundamental2023-lages/concoc-ensino-fundamental2023-lages.component';
import { ConcocEnsinoFundamental2023RioDoSulComponent } from './concoc-2023/concoc-ensino-fundamental2023-rio-do-sul/concoc-ensino-fundamental2023-rio-do-sul.component';
import { ConcocEnsinoMedio2023BalnearioComponent } from './concoc-2023/concoc-ensino-medio2023-balneario/concoc-ensino-medio2023-balneario.component';
import { ConcocEnsinoMedio2023BlumenauComponent } from './concoc-2023/concoc-ensino-medio2023-blumenau/concoc-ensino-medio2023-blumenau.component';
import { ConcocEnsinoMedio2023RioDoSulComponent } from './concoc-2023/concoc-ensino-medio2023-rio-do-sul/concoc-ensino-medio2023-rio-do-sul.component';
import { ConcocEnsinoMedio2023LagesComponent } from './concoc-2023/concoc-ensino-medio2023-lages/concoc-ensino-medio2023-lages.component';
import { Concoc2023ConfirmacaoComponent } from './concoc-2023/concoc2023-confirmacao/concoc2023-confirmacao.component';
import { ConcursoDeBolsasColegioFatoMedio2023Component } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-medio2023/concurso-de-bolsas-colegio-fato-medio2023.component';
import { ConcursoDeBolsasColegioFatoConfirmacaoComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-confirmacao/concurso-de-bolsas-colegio-fato-confirmacao.component';
import { ConcursoDeBolsasColegioFatoFundamentalComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-fundamental/concurso-de-bolsas-colegio-fato-fundamental.component';
import { ConcursoDeBolsasColegioFatoPreVestibularComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-pre-vestibular/concurso-de-bolsas-colegio-fato-pre-vestibular.component';
import { Set2023ConcocRioDoSulMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-rio-do-sul-medio/set2023-concoc-rio-do-sul-medio.component';
import { Set2023ConcocConfirmacaoProvaComponent } from './concurso-de-bolsas-2023/set2023-concoc-confirmacao-prova/set2023-concoc-confirmacao-prova.component';
import { Set2023ConcocBcMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-bc-medio/set2023-concoc-bc-medio.component';
import { Set2023ConcocBcEf2Component } from './concurso-de-bolsas-2023/set2023-concoc-bc-ef2/set2023-concoc-bc-ef2.component';
import { Set2023ConcocLagesEf2Component } from './concurso-de-bolsas-2023/set2023-concoc-lages-ef2/set2023-concoc-lages-ef2.component';
import { Set2023ConcocRioDoSulEf23Component } from './concurso-de-bolsas-2023/set2023-concoc-rio-do-sul-ef23/set2023-concoc-rio-do-sul-ef23.component';
import { Set2023ConcocBlumenauMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-blumenau-medio/set2023-concoc-blumenau-medio.component';
import { Set2023ConcocLagesMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-lages-medio/set2023-concoc-lages-medio.component';
import { SimuladoAcafeComponent } from './acafe/2023/simulado-acafe/simulado-acafe.component';
import { SimuladoAcafeAgradecimentoComponent } from './acafe/2023/simulado-acafe-agradecimento/simulado-acafe-agradecimento.component'; 
import { AcafesComponent } from './new-acafe/acafes/acafes.component';
import { AcafeAgradecimentoComponent } from './new-acafe/acafe-agradecimento/acafe-agradecimento.component';
import { Concursodebolsas2024Component } from './concursodebolsas2024/concursodebolsas2024.component';
import { Concursodebolsas2024AgradecimentoComponent } from './concursodebolsas2024-agradecimento/concursodebolsas2024-agradecimento.component';
import { OnlineComponent } from './concoc2024/online/online.component';
import { BlumenauComponent } from './concoc2024/presencial/blumenau/blumenau.component';
import { LagesComponent } from './concoc2024/presencial/lages/lages.component';
import { RiodosulComponent } from './concoc2024/presencial/riodosul/riodosul.component';
import { ConfirmacaoComponent } from './concoc2024/online/confirmacao/confirmacao.component';
import { ConfirmacaoPresencialComponent } from './concoc2024/presencial/confirmacao-presencial/confirmacao-presencial.component';
import { PreConfirmacaoComponent } from './concoc2024/online/pre-confirmacao/pre-confirmacao.component';
import { BlumenauPreConfirmacaoComponent } from './concoc2024/presencial/blumenau-pre-confirmacao/blumenau-pre-confirmacao.component';
import { LagesPreConfirmacaoComponent } from './concoc2024/presencial/lages-pre-confirmacao/lages-pre-confirmacao.component';
import { RiodosulPreConfirmacaoComponent } from './concoc2024/presencial/riodosul-pre-confirmacao/riodosul-pre-confirmacao.component';
import { BalnearioComponent } from './concoc2024/presencial/balneario/balneario.component';
import { BalnearioPreConfirmacaoComponent } from './concoc2024/presencial/balneario-pre-confirmacao/balneario-pre-confirmacao.component';
import { Simuladopcsc2024Component } from './simuladopcsc24/simuladopcsc2024/simuladopcsc2024.component';
import { AgradecimentoSimuladopcsc2024Component } from './simuladopcsc24/agradecimento-simuladopcsc2024/agradecimento-simuladopcsc2024.component';
import { PreparatorioEnemGratuito2024Component } from './preparatorio-enem-gratuito20241/preparatorio-enem-gratuito2024/preparatorio-enem-gratuito2024.component';
import { AgradecimentoPreparatorioEnemGratuito2024Component } from './preparatorio-enem-gratuito20241/agradecimento-preparatorio-enem-gratuito2024/agradecimento-preparatorio-enem-gratuito2024.component';
import { ConcursoBolsasOpEnem2024Component } from './concurso-bolsas-op-enem-2024/concurso-bolsas-op-enem2024/concurso-bolsas-op-enem2024.component';
import { AgradecimentoConcursoBolsasOpEnem2024Component } from './concurso-bolsas-op-enem-2024/agradecimento-concurso-bolsas-op-enem2024/agradecimento-concurso-bolsas-op-enem2024.component';
import { Concoclagesjun2024Component } from './concoc-semi-2024-2/concoclagesjun2024/concoclagesjun2024.component';
import { Agradecimentoconcoclagesjun2024Component } from './concoc-semi-2024-2/agradecimentoconcoclagesjun2024/agradecimentoconcoclagesjun2024.component';
import { Concocriodosuljun2024Component } from './concoc-semi-2024-2/concocriodosuljun2024/concocriodosuljun2024.component';
import { Concocblumenaujun2024Component } from './concoc-semi-2024-2/concocblumenaujun2024/concocblumenaujun2024.component';
import { Agradecimentoconcocblumenaujun2024Component } from './concoc-semi-2024-2/agradecimentoconcocblumenaujun2024/agradecimentoconcocblumenaujun2024.component';
import { Agradecimentoconcocbalneariojun2024Component } from './concoc-semi-2024-2/agradecimentoconcocbalneariojun2024/agradecimentoconcocbalneariojun2024.component';
import { Agradecimentoconcocriodosuljun2024Component } from './concoc-semi-2024-2/agradecimentoconcocriodosuljun2024/agradecimentoconcocriodosuljun2024.component';
import { Concocbalneariocamboriujun2024Component } from './concoc-semi-2024-2/concocbalneariocamboriujun2024/concocbalneariocamboriujun2024.component';
import { Concocsemiextensivo20242Component } from './concoc-semi-2024-2/concocsemiextensivo20242/concocsemiextensivo20242.component';
import { Agradecimentoconcoconline20242Component } from './concoc-semi-2024-2/agradecimentoconcoconline20242/agradecimentoconcoconline20242.component';
import { ConcursoDeBolsasSantaClaraChallengeAgradecimentoComponent } from './colegio-santa-clara/concurso-de-bolsas-santa-clara-challenge-agradecimento/concurso-de-bolsas-santa-clara-challenge-agradecimento.component';
import { ColegioSantaClaraComponent } from './colegio-santa-clara/colegio-santa-clara.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisComponent } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis/concurso-de-bolsas-santa-clara-challenge-assis.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisEf1Component } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis-ef1/concurso-de-bolsas-santa-clara-challenge-assis-ef1.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisEffEmComponent } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis-eff-em/concurso-de-bolsas-santa-clara-challenge-assis-eff-em.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaComponent } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaEf1Component } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaEffEmComponent } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em/concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em.component';
import { ConcocBalnearioFundamentalComponent } from './concoc-2025/coc-balneario/concoc-balneario-fundamental/concoc-balneario-fundamental.component';
import { AgradecimentoConcocBalnearioComponent } from './concoc-2025/coc-balneario/agradecimento-concoc-balneario/agradecimento-concoc-balneario.component';
import { ConcocBalnearioEmComponent } from './concoc-2025/coc-balneario/concoc-balneario-em/concoc-balneario-em.component';
import { ConcocBlumenauEmComponent } from './concoc-2025/coc-blumenau/concoc-blumenau-em/concoc-blumenau-em.component';
import { AgradecimentoConcocBlumenauComponent } from './concoc-2025/coc-blumenau/agradecimento-concoc-blumenau/agradecimento-concoc-blumenau.component';
import { ConcocLagesEmComponent } from './concoc-2025/coc-lages/concoc-lages-em/concoc-lages-em.component';
import { AgradecimentoConcocLagesComponent } from './concoc-2025/coc-lages/agradecimento-concoc-lages/agradecimento-concoc-lages.component';
import { ConcocLagesEfComponent } from './concoc-2025/coc-lages/concoc-lages-ef/concoc-lages-ef.component';
import { ConcocRioDoSulEfComponent } from './concoc-2025/coc-rio-do-sul/concoc-rio-do-sul-ef/concoc-rio-do-sul-ef.component';
import { ConcocRioDoSulEmComponent } from './concoc-2025/coc-rio-do-sul/concoc-rio-do-sul-em/concoc-rio-do-sul-em.component';
import { AgradecimentoConcocRdsComponent } from './concoc-2025/coc-rio-do-sul/agradecimento-concoc-rds/agradecimento-concoc-rds.component';


const routes: Routes = [
  {
    path: '',
    component: PageNotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: '2023-simulado-acafe',
    component:  AcafesComponent,
    pathMatch: 'full'
  },
  {
    path: '2023-simulado-acafe-agradecimento',
    component:  AcafeAgradecimentoComponent,
    pathMatch: 'full'
  } 
  ,{
    path: 'concoc-enem-2022-balneario-confirmacao',
    component:  ConcocEnem2022BalnearioConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-fundamental-2023-balneario',
    component:  ConcocEnsinoFundamental2023BalnearioComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-fundamental-2023-lages',
    component:  ConcocEnsinoFundamental2023LagesComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-fundamental-2023-rio-do-sul',
    component:  ConcocEnsinoFundamental2023RioDoSulComponent,
    pathMatch: 'full'
  },

  {
    path: 'concoc-ensino-medio-2023-balneario',
    component:  ConcocEnsinoMedio2023BalnearioComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-medio-2023-blumenau',
    component:  ConcocEnsinoMedio2023BlumenauComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-medio-2023-rio-do-sul',
    component:  ConcocEnsinoMedio2023RioDoSulComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-2023-confirmacao',
    component:  Concoc2023ConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-medio-2023-lages',
    component:  ConcocEnsinoMedio2023LagesComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-ensino-medio-2023-lages',
    component:  ConcocEnsinoMedio2023LagesComponent,
    pathMatch: 'full'
  },
  {
    path: 'concurso-de-bolsas-colegio-fato-medio-2023',
    component:  ConcursoDeBolsasColegioFatoMedio2023Component,
    pathMatch: 'full'
  },
  {
    path: 'concurso-de-bolsas-colegio-fato-confirmacao',
    component:  ConcursoDeBolsasColegioFatoConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concurso-de-bolsas-colegio-fato-fundamental',
    component:  ConcursoDeBolsasColegioFatoFundamentalComponent,
    pathMatch: 'full'
  },
  {
    path: 'concurso-de-bolsas-colegio-fato-pre-vestibular',
    component:  ConcursoDeBolsasColegioFatoPreVestibularComponent,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-rio-do-sul-medio',
    component:  Set2023ConcocRioDoSulMedioComponent,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-confirmacao-prova',
    component:  Set2023ConcocConfirmacaoProvaComponent,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-bc-medio',
    component:  Set2023ConcocBcMedioComponent,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-bc-ef2',
    component:  Set2023ConcocBcEf2Component,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-lages-ef2',
    component:  Set2023ConcocLagesEf2Component,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-rio-do-sul-ef2',
    component:  Set2023ConcocRioDoSulEf23Component,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-blumenau-medio',
    component:  Set2023ConcocBlumenauMedioComponent,
    pathMatch: 'full'
  },
  {
    path: 'set-2023-concoc-lages-medio',
    component:  Set2023ConcocLagesMedioComponent,
    pathMatch: 'full'
  },
  {
    path: 'dez-2023-concoc-pv-2024',
    component:  Concursodebolsas2024Component,
    pathMatch: 'full'
  },
  {
    path: 'dez-2023-concoc-pv-2024-agradecimento',
    component:  Concursodebolsas2024AgradecimentoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-online',
    component:  OnlineComponent,
    pathMatch: 'full'
  },

  {
    path: 'concoc-blumenau',
    component:  BlumenauComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-lages',
    component:  LagesComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-rio-do-sul',
    component:  RiodosulComponent,
    pathMatch: 'full'
  },
  {
    path: 'online/concoc-confirmacao',
    component:  ConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'online/concoc-confirmacao-online',
    component:  PreConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'presencial/concoc-confirmacao',
    component:  ConfirmacaoPresencialComponent,
    pathMatch: 'full'
  },
  {
    path: 'presencial/concoc-confirmacao-blumenau',
    component:  BlumenauPreConfirmacaoComponent,
    pathMatch: 'full'
  },

  {
    path: 'presencial/concoc-confirmacao-lages',
    component:  LagesPreConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'presencial/concoc-confirmacao-rio-do-sul',
    component:  RiodosulPreConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concoc-balneario-camboriu',
    component:  BalnearioComponent,
    pathMatch: 'full'
  },
  {
  path: 'presencial/concoc-confirmacao-balneario-camboriu',
  component:  BalnearioPreConfirmacaoComponent,
  pathMatch: 'full'
  },
  {
  path: 'simulado-pcsc-2024',
  component:  Simuladopcsc2024Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-simulado-pcsc-2024',
  component:  AgradecimentoSimuladopcsc2024Component,
  pathMatch: 'full'
  },
  {
  path: 'preparatorio-enem-gratuito-2024',
  component:  PreparatorioEnemGratuito2024Component,
  pathMatch: 'full'
  },
  {
  path: 'abr-2024-preparatorio-enem-gratuito-agradecimento',
  component:  AgradecimentoPreparatorioEnemGratuito2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concurso-bolsas-op-enem-2024',
  component:  ConcursoBolsasOpEnem2024Component,
  pathMatch: 'full'
  },
  {
  path: 'abr-2024-concoc-preparatorio-enem-agradecimento',
  component:  AgradecimentoConcursoBolsasOpEnem2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concoc-lages-jun-2024',
  component:  Concoclagesjun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-concoc-lages-jun2024',
  component:  Agradecimentoconcoclagesjun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concoc-rio-do-sul-jun2024',
  component:  Concocriodosuljun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-concoc-rio-do-sul-jun2024',
  component:  Agradecimentoconcocriodosuljun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concoc-blumenau-jun2024',
  component:  Concocblumenaujun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-concoc-blumenau-jun2024',
  component:  Agradecimentoconcocblumenaujun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-concoc-balneario-jun2024',
  component:  Agradecimentoconcocbalneariojun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concoc-balneario-jun2024',
  component:  Concocbalneariocamboriujun2024Component,
  pathMatch: 'full'
  },
  {
  path: 'concoc-semiextensivo-2024-2',
  component:  Concocsemiextensivo20242Component,
  pathMatch: 'full'
  },
  {
  path: 'agradecimento-concoc-online-2024-2',
  component:  Agradecimentoconcoconline20242Component,
  pathMatch: 'full'
  },

  //Verificar LPs anteriores se estão ativas
  {
    path: 'concurso-de-bolsas-santa-clara-challenge-agradecimento',
    component:  ConcursoDeBolsasSantaClaraChallengeAgradecimentoComponent,
    pathMatch: 'full'
  },
  {
    path: 'concurso-de-bolsas-santa-clara-challenge',
    component:  ColegioSantaClaraComponent,
    pathMatch: 'full'
  },
  {
    path: 'assis/concurso-de-bolsas-santa-clara-challenge-assis',
    component:  ConcursoDeBolsasSantaClaraChallengeAssisComponent,
    pathMatch: 'full'
  },
  {
    path: 'assis/concurso-de-bolsas-santa-clara-challenge-assis-ef1',
    component:  ConcursoDeBolsasSantaClaraChallengeAssisEf1Component,
    pathMatch: 'full'
  },
  {
    path: 'assis/concurso-de-bolsas-santa-clara-challenge-assis-eff-em',
    component:  ConcursoDeBolsasSantaClaraChallengeAssisEffEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota',
    component:  ConcursoDeBolsasSantaClaraChallengeCandidoMotaComponent,
    pathMatch: 'full'
  },
  {
    path: 'candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1',
    component:  ConcursoDeBolsasSantaClaraChallengeCandidoMotaEf1Component,
    pathMatch: 'full'
  },
  {
    path: 'candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1',
    component:  ConcursoDeBolsasSantaClaraChallengeCandidoMotaEf1Component,
    pathMatch: 'full'
  },
  {
    path: 'candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em',
    component:  ConcursoDeBolsasSantaClaraChallengeCandidoMotaEffEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'ef-concoc-2025-bc',
    component:  ConcocBalnearioFundamentalComponent,
    pathMatch: 'full'
  },
  {
    path: 'em-concoc-2025-bc',
    component:  ConcocBalnearioEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'agradecimento-concoc-2025-bc',
    component:  AgradecimentoConcocBalnearioComponent,
    pathMatch: 'full'
  },
  {
    path: 'em-concoc-2025-bnu',
    component:  ConcocBlumenauEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'agradecimento-concoc-2025-bnu',
    component:  AgradecimentoConcocBlumenauComponent,
    pathMatch: 'full'
  },
  {
    path: 'em-concoc-2025-lages',
    component:  ConcocLagesEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'ef-concoc-2025-lages',
    component:  ConcocLagesEfComponent,
    pathMatch: 'full'
  },
  {
    path: 'agradecimento-concoc-2025-lages',
    component:  AgradecimentoConcocLagesComponent,
    pathMatch: 'full'
  },
  {
    path: 'ef-concoc-2025-rsl',
    component:  ConcocRioDoSulEfComponent,
    pathMatch: 'full'
  },
  {
    path: 'em-concoc-2025-rsl',
    component:  ConcocRioDoSulEmComponent,
    pathMatch: 'full'
  },
  {
    path: 'agradecimento-concoc-2025-rsl',
    component:  AgradecimentoConcocRdsComponent,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
