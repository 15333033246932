import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcocService } from '../concoc.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concoc-fundamental-beraldo',
  templateUrl: './concoc-fundamental-beraldo.component.html',
  styleUrls: ['./concoc-fundamental-beraldo.component.scss']
})
export class ConcocFundamentalBeraldoComponent implements OnInit {

  profileForm: FormGroup;
  botaoEnviar: boolean;
  landClasses: any;
  constructor(  private route: ActivatedRoute,
                private router : Router,
                private concocService: ConcocService
  ) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl('')
    
    });  
    this.getLandClass();
  }
  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-kelhwh7n")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  getLandClass(){
    this.concocService.getLandRoute("concoc-fundamental-beraldo")
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {

            this.landClasses = response.data; 
            console.info(this.landClasses); 
        }
    }, err => console.log("a")); 
  }
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    var data = this.profileForm.getRawValue();
    
      
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          urlRedirect: "https://plataforma.cocberaldo.com.br/redirect",
          pv :data.turma
        } 
        this.concocService.register(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( ['/concoc-fundamental-beraldo-confirmacao'], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a")); 
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

}
