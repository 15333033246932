
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content=" Participe do CONCOC e tenha a chance de ganhar uma bolsa de estudo de até 100%!">
    

    
      <meta property="og:title" content="Concurso de bolsas 2025 - COC Lages" />
      <meta name="twitter:title" content="Concurso de bolsas 2025 - COC Lages" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://coclages.gruponotamaxima.com.br/em-concoc-2025-lages" />
    

    <title>Concurso de bolsas 2025 - COC Lages</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__field--hidden {
        display: none;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MQXLXM4G');</script>
<!-- End Google Tag Manager -->


    
  </head>
  <body>
    
      <input type="hidden" id="ga4-measurement-id" value="">
      <input type="hidden" id="has-gtm" value="false">
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-lzr23h7b {
      
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.53) 0%, rgba(255,255,255,0.53) 100%), url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1723470851/$w2f2bnoffca);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-lzr23h7b > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-lzr23h7b" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lzr23h7c {
      min-height: 50px;
    }

    #rd-row-lzr23h7c .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lzr23h7c.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lzr23h7c.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lzr23h7c" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lzr23h7d {
      flex: 12 0 0;
    }

    #rd-column-lzr23h7d > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lzr23h7d > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lzr23h7d" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lzr248pv {
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 112px;
    }

    #rd-image-lzr248pv a,
    #rd-image-lzr248pv img {
      width: 112px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzr248pv {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lzr248pv" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723552868/$4sjhndw9dlm" alt="">

  
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vm {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1723470851/$uc246edufeb), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #585858;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vm > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2vm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lq10aw0l {
      min-height: 50px;
    }

    #rd-row-lq10aw0l .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lq10aw0l.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lq10aw0l.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lq10aw0l" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lq10aw0m {
      flex: 12 0 0;
    }

    #rd-column-lq10aw0m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lq10aw0m > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-lq10aw0m > div {
          padding-top: 0px;
          padding-right: 10px;
          padding-bottom: 0px;
          padding-left: 10px;
        }
      }
    
  
</style>

<div id="rd-column-lq10aw0m" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lq10b43x {
      margin-top: 80px;
      margin-bottom: 30px;
      align-self: center;
      max-width: 385px;
    }

    #rd-image-lq10b43x a,
    #rd-image-lq10b43x img {
      width: 385px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lq10b43x {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lq10b43x {
          margin-top: 60px;
          margin-bottom: 30px;
          align-self: center;
          max-width: 387px;
        }

        #rd-image-lq10b43x a,
        #rd-image-lq10b43x img {
          width: 387px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lq10b43x" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723480456/$jtar0rug0um" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2vn {
      min-height: 408px;
    }

    #rd-row-joq3m2vn .bricks--column > div {
      min-height: 408px;
    }

    @media (max-width: 480px) {
      #rd-row-joq3m2vn.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2vn.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2vn" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jruwfhaa {
      flex: 6 0 0;
    }

    #rd-column-jruwfhaa > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jruwfhaa > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-jruwfhaa > div {
          padding-top: 0px;
          padding-right: 25px;
          padding-bottom: 0px;
          padding-left: 25px;
        }
      }
    
  
</style>

<div id="rd-column-jruwfhaa" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lzr2s6qb {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 169px;
    }

    #rd-image-lzr2s6qb a,
    #rd-image-lzr2s6qb img {
      width: 169px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzr2s6qb {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lzr2s6qb {
          margin-top: 10px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 174px;
        }

        #rd-image-lzr2s6qb a,
        #rd-image-lzr2s6qb img {
          width: 174px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lzr2s6qb" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723470851/$vb2ijbf7bx" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lq0zu11q {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-35px;
      max-width: 436px;
      margin-top: 20px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lq0zu11q.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lq0zu11q {
          display: none;
        }

        #rd-text-lq0zu11q.mobile {
          display: block;
          max-width: 456px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    
  
</style>

<div id="rd-text-lq0zu11q" class="bricks--component bricks--component-text rd-text">
  <h3 style="line-height: 1.25; text-align: center;"><strong style="color: rgb(0, 137, 16); font-size: 26px; font-family: Lato, sans-serif;">Participe do CONCOC e </strong><strong style="color: rgb(241, 132, 52); font-size: 26px; font-family: &quot;Arial Black&quot;, Gadget, sans-serif;">concorra a uma bolsa de estudo</strong><strong style="color: rgb(0, 137, 16); font-size: 26px; font-family: Lato, sans-serif;"> para o ano letivo de 2025 no </strong><strong style="color: rgb(9, 131, 18); font-size: 26px; font-family: Lato, sans-serif;">COC Lages!</strong></h3>
</div>


  <div id="rd-text-lq0zu11q" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center; line-height: 1.25;"><strong style="font-family: Lato, sans-serif; color: rgb(0, 137, 16); font-size: 25px;">Participe do CONCOC e </strong><strong style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; color: rgb(241, 132, 52); font-size: 25px;">concorra a uma bolsa de estudo</strong><strong style="font-family: Lato, sans-serif; color: rgb(0, 137, 16); font-size: 25px;"> para o ano letivo de 2025 no COC Lages!</strong></p>
  </div>



<style type="text/css" rel="stylesheet">
  
    #rd-image-lzheb4o7 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 345px;
    }

    #rd-image-lzheb4o7 a,
    #rd-image-lzheb4o7 img {
      width: 345px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzheb4o7 {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lzheb4o7 {
          margin-top: 10px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 333px;
        }

        #rd-image-lzheb4o7 a,
        #rd-image-lzheb4o7 img {
          width: 333px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lzheb4o7" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723487605/$caq77tga3zd" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-lp2zvf4i {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 328px;
    }

    #rd-image-lp2zvf4i a,
    #rd-image-lp2zvf4i img {
      width: 328px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lp2zvf4i {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lp2zvf4i {
          margin-top: 10px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 351px;
        }

        #rd-image-lp2zvf4i a,
        #rd-image-lp2zvf4i img {
          width: 351px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lp2zvf4i" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723485620/$9lxl7mz3bvw" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2vu {
      flex: 6 0 0;
    }

    #rd-column-joq3m2vu > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2vu > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-joq3m2vu > div {
          padding-top: 0px;
          padding-right: 25px;
          padding-bottom: 0px;
          padding-left: 25px;
        }
      }
    
  
</style>

<div id="rd-column-joq3m2vu" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-kfgt264b {
      
          background-color: #009739;
        
      border-bottom-style: solid;
      border-color: transparent;
      border-left-style: solid;
      border-radius: 15px;
      border-right-style: solid;
      border-top-style: solid;
      border-width: 1px;
      padding-top: 25px;
      padding-right: 30px;
      padding-bottom: 25px;
      padding-left: 30px;
      min-height: -1px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  

  
</style>

<div id="rd-box-kfgt264b" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt7h9g {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kfgt7h9g" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><strong style="font-size: 20px; font-family: &quot;Open Sans&quot;, sans-serif; color: rgb(255, 255, 255);">Preencha os campos abaixo para</strong></p><p style="line-height: 1.25; text-align: center;"><strong style="font-size: 20px; font-family: &quot;Open Sans&quot;, sans-serif; color: rgb(255, 255, 255);">se cadastrar gratuitamente!</strong></p>
</div>






<style type="text/css" rel="stylesheet">
  

  
    #rd-form-joq3m2vy {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2vy .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2vy .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2vy .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2vy .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2vy .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2vy .bricks-form__static {
      color: #f9f9f9;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2vy .bricks-form__static a {
      color: #f9f9f9;
      text-decoration: underline;
    }

    #rd-form-joq3m2vy .bricks-form__label {
      color: #f9f9f9;
      display: inline-block;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      text-decoration: none;
    }

    #rd-form-joq3m2vy label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2vy .bricks-form__label.always-visible,
    #rd-form-joq3m2vy .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2vy .bricks-form__field[data-step].step-hidden {
      display: none;
    }

    #rd-form-joq3m2vy .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #a73131;
      border-radius: 4px;
      border-width: 0px;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        ;
    }

    #rd-form-joq3m2vy .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2vy .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Open Sans, sans-serif !important;
      font-size: 15px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2vy .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2vy .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  

  
</style>


<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2vy input[type="date"] {
      -webkit-appearance: none;
    }
  
</style>

<div
  id="rd-form-joq3m2vy"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup]="profileForm" (ngSubmit)="onSubmit()"       
    novalidate="novalidate"
    action="https://cta-redirect.rdstation.com/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb2NsYWdlcy5ncnVwb25vdGFtYXhpbWEuY29tLmJyL2FncmFk
ZWNpbWVudG8tY29uY29jLTIwMjUtbGFnZXM=
"
    data-ga-ua-id="UA-143747841-1"
    data-recaptcha-public-token=""
  >
    <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
    <input type="hidden" name="conversion_identifier" value="em-concoc-2025-lages">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    

    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
  data-multi-step="false"
>
  

<div
  class="bricks-form__field   "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-text_field-lzty5ayc" class="bricks-form__label">
    Nome completo*
  </label>
  <input
    id="rd-text_field-lzty5ayc"
    class="bricks-form__input required js-text js-field-cf_nome_completo"
    name="custom_fields[865761]"
    type="text"
    data-use-type="STRING"
    placeholder="Nome completo *"
    required="required"
    (change)= "changeField()" formControlName="nome"
  >
</div>


<div
  class="bricks-form__field  "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-date_field-lzty5ayd" class="bricks-form__label">
    Data de Aniversário*
  </label>
  <input
    id="rd-date_field-lzty5ayd"
    class="bricks-form__input required js-text js-field-birthdate"
    name="birthdate"
    type="date"
    data-use-type="TIMESTAMP"
    data-placeholder="Data de Aniversário *"
    required="required"
    required="required" (change)= "changeField()" formControlName="nascimento"
  >
</div>


<div
  class="bricks-form__field  "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-email_field-lzty5aye" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-lzty5aye"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    data-always-visible="true"
    data-input-mask=""
    placeholder="Email *"
    required="required"
    (change)= "changeField()" formControlName="email"
  >
</div>


<div
  class="bricks-form__field  with-select-flags "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-phone_field-lzty5ayf" class="bricks-form__label">
    Celular*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-lzty5ayf"
      class="bricks-form__input required phone js-phone js-field-mobile_phone"
      name="mobile_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Celular *"
      required="required"
      (change)= "changeField()" formControlName="telefone"
    >
  </div>
</div>


<div
  class="bricks-form__field   "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-text_field-lzty5ayg" class="bricks-form__label">
    Nome do Responsável*
  </label>
  <input
    id="rd-text_field-lzty5ayg"
    class="bricks-form__input required js-text js-field-cf_nome_do_responsavel"
    name="custom_fields[1184401]"
    type="text"
    data-use-type="STRING"
    placeholder="Nome do Responsável *"
    required="required"
    (change)= "changeField()" formControlName="nomeResponsavel"
    required="required"
  >
</div>


<div
  class="bricks-form__field  "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-select_field-lzty5ayh" class="bricks-form__label">
    Qual série você vai cursar em 2025?*
  </label>

  

  <select
    id="rd-select_field-lzty5ayh"
    class="bricks-form__input required js-field-cf_ensino_medio_qual_serie_o_aluno_vai_cursar_em_2025"
    data-use-type="STRING"
    name="custom_fields[2763893]"
    required="required"
    (change)= "changeField()" formControlName="turmaqueentra"
  >
    <option value="">
      
      Selecione
    </option>
    
      <option value="1ª série do Ensino Médio">1ª série do Ensino Médio</option>
    
      <option value="2ª série do Ensino Médio">2ª série do Ensino Médio</option>
    
      <option value="Terceirão">Terceirão</option>
    
  </select>
</div>


<div
  class="bricks-form__field  "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-select_field-lzty5ayi" class="bricks-form__label">
    Em qual modalidade a sua escola se encaixa?*
  </label>

  

  <select
    id="rd-select_field-lzty5ayi"
    class="bricks-form__input required js-field-cf_modalidade_da_escola_concoc"
    data-use-type="STRING"
    name="custom_fields[2763714]"
    required="required"
    (change)= "changeField()"
    formControlName="escola_publica_ou_particular"
  >
    <option value="">
      
      Selecione
    </option>
    
      <option value="Pública">Pública</option>
    
      <option value="Particular">Particular</option>
    
      <option value="Sou aluno do COC">Sou aluno do COC</option>
    
  </select>
</div>


<div
  class="bricks-form__field   "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label for="rd-text_field-lzty5ayj" class="bricks-form__label">
    Qual o nome da sua escola?*
  </label>
  <input
    id="rd-text_field-lzty5ayj"
    class="bricks-form__input required js-text js-field-cf_nome_da_sua_escola"
    name="custom_fields[2632145]"
    type="text"
    data-use-type="STRING"
    placeholder="Qual o nome da sua escola? *"
    required="required"
    required="required"
    formControlName="nome_escola" (change)= "changeField()"
  >
</div>


<div
  class="bricks-form__field  "
  data-step="1"
  data-is-conditional="false"
  data-conditional-rules='W10=
'
>
  <label class="bricks-form__label always-visible">
    Sobre o edital da campanha:*
  </label>
  
    <div class="bricks-form__field__option">
      <label class="bricks-form__label">
        <input
          id="rd-radio_buttons_field-lzty5ayk_Li_e_concordo_com_o_edital"
          name="custom_fields[2046326]"
          type="radio" (change)= "changeField()"
          value="Li e concordo com o edital"
          class="bricks-form__field__option__input required js-field-cf_leitura_do_edital_da_campanha"
          data-use-type="STRING"
          required="required"
        >
        Li e concordo com o edital
      </label>
    </div>
  
</div>




  <div class="bricks-form__field  "  data-step="1">
    <label for="captcha" class="bricks-form__label">
      <span #mathexpression  id="math_expression">1 + 1 = ?</span>
    </label>
    <input
      id="captcha" #captcha
      class="bricks-form__input math rd-bricks-captcha"
      name="captcha"
      type="text"
      placeholder="1 + 1 = ?"
      formControlName="captcha"
      (keyup)="checkCaptcha(mathexpression.innerHTML, captcha.value);"
    >
  </div>


</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2w0 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2w0.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #a0ff17;
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      
          box-shadow: 0px
                      0px
                      20px
                      0px
                      #a0ff17;
        ;
      color: #005821;
      cursor: pointer;
      font-family: Arial Black, Gadget, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 20px;
      max-width: 368px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2w0 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>



  

  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2w0"
    >
      QUERO PARTICIPAR!
    </button>
  </div>
  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2w0" (click)="avisoCampos()"
    >
      QUERO PARTICIPAR!
    </button>
  </div>



    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>
  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1723721593"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1723721593"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1723721593"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo && redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lp78waxj {
      min-height: 50px;
    }

    #rd-row-lp78waxj .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lp78waxj.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lp78waxj.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lp78waxj" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lp78waxk {
      flex: 12 0 0;
    }

    #rd-column-lp78waxk > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lp78waxk > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lp78waxk" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-lp794x96 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-lp794x96.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #f18434;
      border-bottom-style: none;
      border-color: #002a18;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 4px;
      
          box-shadow: 4px
                      4px
                      7px
                      0px
                      #939393;
        ;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Arial Black, Gadget, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      height: 51px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 404px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-lp794x96 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-button-lp794x96.bricks--component-button {
          # max-width: 332px;
          # margin-top: 30px;
          # margin-bottom: 20px;

          align-self: center;
          background-color: #f18434;
          border-bottom-style: none;
          border-color: #002a18;
          border-left-style: none;
          border-radius: 100px;
          border-right-style: none;
          border-top-style: none;
          border-width: 4px;
          
          box-shadow: 4px
                      4px
                      7px
                      0px
                      #939393;
        ;
          color: #FFFFFF;
          font-family: Arial Black, Gadget, sans-serif;
          font-size: 19px;
          font-style: normal;
          font-weight: bold;
          margin-bottom: 20px;
          margin-top: 30px;
          max-width: 332px;
          text-align: center;
          text-decoration: none;
        }
      }
    
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://drive.google.com/file/d/1ZhPgeRwvVE7Ooc-RhhI3eS3v-uHL1qlf/view?usp=drive_link"
    id="rd-button-lp794x96"
    target="_blank"
    title="Confira o edital completo"
  >
    Confira o edital completo
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lp7ablfm {
      min-height: 83px;
    }

    #rd-row-lp7ablfm .bricks--column > div {
      min-height: 83px;
    }

    @media (max-width: 480px) {
      #rd-row-lp7ablfm.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lp7ablfm.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lp7ablfm" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lp7ablfn {
      flex: 12 0 0;
    }

    #rd-column-lp7ablfn > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lp7ablfn > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lp7ablfn" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lq10f61j {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lq10f61j" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-jrux37c9 {
      
          background-color: #ffffff;
        
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 1px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-jrux37c9 > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-jrux37c9" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-juljjjfd {
      min-height: 50px;
    }

    #rd-row-juljjjfd .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-juljjjfd.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-juljjjfd.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-juljjjfd" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lxvrwnuj {
      flex: 12 0 0;
    }

    #rd-column-lxvrwnuj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lxvrwnuj > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lxvrwnuj" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lzr2u1xo {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 312px;
    }

    #rd-image-lzr2u1xo a,
    #rd-image-lzr2u1xo img {
      width: 312px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzr2u1xo {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lzr2u1xo" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723470851/$mn6coa53soe" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6621551" data-experiment-content-id=""></script>

    <div data-editable="true" data-field-name="landing_page"></div>
    <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MQXLXM4G"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    <style>
      #rd-button-joq3m2w0:hover 

{ background-color: #b55510; 

transition: 0.5s;

opacity: 10;

}

#rd-button-lp794x96:hover {

    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.6); 

}
    </style>
    

  </body>
</html>
