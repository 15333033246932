import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-concoc2023-confirmacao',
  templateUrl: './concoc2023-confirmacao.component.html',
  styleUrls: ['./concoc2023-confirmacao.component.scss']
})
export class Concoc2023ConfirmacaoComponent implements OnInit {
  
  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
    //this.link = this.route.queryParams ; 
  }

}
