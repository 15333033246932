import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmacao-enem-gratuito',
  templateUrl: './confirmacao-enem-gratuito.component.html',
  styleUrls: ['./confirmacao-enem-gratuito.component.scss']
})
export class ConfirmacaoEnemGratuitoComponent implements OnInit {
  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
    //this.link = this.route.queryParams ; 
  }

}
