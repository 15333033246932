import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concurso-de-bolsas-santa-clara-challenge-agradecimento',
  templateUrl: './concurso-de-bolsas-santa-clara-challenge-agradecimento.component.html',
  styleUrls: ['./concurso-de-bolsas-santa-clara-challenge-agradecimento.component.scss']
})
export class ConcursoDeBolsasSantaClaraChallengeAgradecimentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
