
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Participe do Concurso de Bolsas do Colégio Visão que dará bolsas de até 100% para você estudar em um Colégio de qualidade">
    

    
      <meta property="og:title" content="Concurso de Bolsas Ensino Médio - Colégio Visão" />
      <meta name="twitter:title" content="Concurso de Bolsas Ensino Médio - Colégio Visão" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    
      <meta property="og:description" content="Participe do Concurso de Bolsas do Colégio Visão que dará bolsas de até 100% para você estudar em um Colégio de qualidade" />
      <meta name="twitter:description" content="Participe do Concurso de Bolsas do Colégio Visão que dará bolsas de até 100% para você estudar em um Colégio de qualidade" />
    

    
      <link rel="canonical" href="http://conteudos.notamaxima.com.br/concurso-medio-visao-2021" />
    

    <title>Concurso de Bolsas Ensino Médio - Colégio Visão</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript"> </script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vh {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$8f492dcrcov), linear-gradient(to bottom, #8f008f 0%, #8f008f 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vh > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2vh" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2vi {
      min-height: 101px;
    }

    #rd-row-joq3m2vi .bricks--column > div {
      min-height: 101px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2vi.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2vi" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-js37p5vj {
      flex: 3 0 0;
    }

    #rd-column-js37p5vj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-js37p5vj > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-js37p5vj" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kfgt0rfb {
      margin-top: 40px;
      margin-bottom: 40px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kfgt0rfb a,
    #rd-image-kfgt0rfb img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kfgt0rfb {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kfgt0rfb" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://www.colegiolondrinense.com.br/images/diferenciais/sistema-anglo.png" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-ki6j33bn {
      flex: 6 0 0;
    }

    #rd-column-ki6j33bn > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-ki6j33bn > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-ki6j33bn" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-ki6j33bo {
      margin-top: 40px;
      margin-bottom: 40px;
      align-self: center;
      max-width: 601px;
    }

    #rd-image-ki6j33bo a,
    #rd-image-ki6j33bo img {
      width: 601px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-ki6j33bo {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-ki6j33bo" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$zz3kcgy0h6d" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-ki6j416b {
      flex: 3 0 0;
    }

    #rd-column-ki6j416b > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-ki6j416b > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-ki6j416b" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-ki8x3w0i {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 129px;
    }

    #rd-image-ki8x3w0i a,
    #rd-image-ki8x3w0i img {
      width: 129px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-ki8x3w0i {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-ki8x3w0i" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$brsk8tqv5s4" alt="">

  
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vm {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #585858;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vm > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2vm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2vn {
      min-height: 408px;
    }

    #rd-row-joq3m2vn .bricks--column > div {
      min-height: 408px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2vn.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2vn" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jruwfhaa {
      flex: 6 0 0;
    }

    #rd-column-jruwfhaa > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jruwfhaa > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-jruwfhaa" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kfgt3iub {
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 323px;
    }

    #rd-image-kfgt3iub a,
    #rd-image-kfgt3iub img {
      width: 323px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kfgt3iub {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kfgt3iub" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$12bqsj25fzk" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt40fg {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 460px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfgt40fg" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 0, 0); font-size: 18px;">2º Concurso de Bolsas para novos alunos do Ensino Médio Rede Visão Anglo - 1º, 2º e 3º ano</strong></h4>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt4xaq {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfgt4xaq" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 14px;">⚠ Atenção, concurso válido apenas para alunos de outras escolas, que não estejam matriculados em nenhuma turma do Colégio Visão para 2021. ⚠ </span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-box-kfpf77gs {
      
          background-color: #f16600;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: auto-48px;
      margin-top: 20px;
      margin-bottom: 10px;
      max-width: 425px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-kfpf77gs" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfpf40bp {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfpf40bp" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 16px;">Bolsas de 100% e 50% para você!</strong></h4>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kjiwxa3e {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kjiwxa3e" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">Participe do Concurso de Bolsas do Colégio Visão, concorra a bolsas de até 100% e estude em um colégio de qualidade, com toda a infraestrutura e tecnologia para garantir o seu futuro!</span></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt588q {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfgt588q" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="color: rgb(0, 0, 0); font-family: Open Sans, sans-serif; font-size: 14px;">Inscrições até dia 20 de Janeiro de 2021</strong></h4><h4 style="line-height: 1.25; text-align: center;"><strong style="color: rgb(0, 0, 0); font-family: Open Sans, sans-serif; font-size: 14px;">Prova ONLINE dia 21 de Janeiro, das 15h00 às 17h00</strong></h4>
</div>


<div id="rd-html-kfgt5fta" class="bricks--component bricks--component-html rd-html">
  <div style="margin-top: 10px; margin-bottom:10px; text-align: center;">
<img width="250"src="http://gen.sendtric.com/countdown/3ga60t7d0m" style="display: inline-block;" />
</div>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2vu {
      flex: 6 0 0;
    }

    #rd-column-joq3m2vu > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2vu > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2vu" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-kfgt264b {
      
          background-color: #3b4693;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 6px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 25px;
      padding-right: 30px;
      padding-bottom: 25px;
      padding-left: 30px;
      min-height: -1px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-kfgt264b" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt7h9g {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfgt7h9g" class="bricks--component bricks--component-text rd-text">
  <h2 style="line-height: 1; text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 18px;">Preencha o formulário abaixo, participe do concurso e tenha a chance de ganhar</strong></h2>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfgt9jfg {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfgt9jfg" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="font-size: 25px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; background-color: rgb(241, 102, 0);"> BOLSAS DE ATÉ 100% </strong></h2>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2vy {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2vy .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2vy .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2vy .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2vy .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2vy .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2vy .bricks-form__static {
      color: #ffffff;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2vy .bricks-form__static a {
      color: #ffffff;
      text-decoration: underline;
    }

    #rd-form-joq3m2vy .bricks-form__label {
      color: #ffffff;
      display: inline-block;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2vy label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2vy .bricks-form__label.always-visible,
    #rd-form-joq3m2vy .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2vy .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #a73131;
      border-radius: 4px;
      border-width: 0px;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        
    }

    #rd-form-joq3m2vy .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2vy .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Open Sans, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2vy .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2vy .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2vy .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2vy .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  
</style>


<div
  id="rd-form-joq3m2vy"
  class="bricks--component bricks-form rd-form"
>

  <form
    [formGroup] = "profileForm"
    (ngSubmit)="onSubmit()"
    id="conversion-form"
    novalidate="novalidate"
    action="https://www.rdstation.com.br/api/1.2/conversions"
    data-typed-fields=""
    data-lang="pt-BR"
  >

    <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
    <input type="hidden" name="identificador" value="concurso-medio-visao-2021">
    <input type="hidden" name="_is" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="redirect_to" value="https://conteudos.notamaxima.com.br/concurso-medio-visao-2021-confirmacao"/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    


    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
>
  <div class="bricks-form__field">
  <label for="rd-text_field-yhM3wApwDefvP9x06UCxCQ" class="bricks-form__label">
    Nome*
  </label>
  <input
    (change)= "changeField()"
    formControlName="nome"
    id="rd-text_field-yhM3wApwDefvP9x06UCxCQ"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
  >
</div>
<div class="bricks-form__field">
  <label for="rd-email_field-UuH0NzMgk00hB8m26-j21A" class="bricks-form__label">
    Email*
  </label>
  <input
    (change)= "changeField()"
    formControlName="email"
    required="required"
    id="rd-email_field-UuH0NzMgk00hB8m26-j21A"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    placeholder="Email *"
    
  >
</div>
<div class="bricks-form__field">
  <label for="rd-senha_field-kcng8yh5" class="bricks-form__label">
    Crie a sua senha*
  </label>
  <input
    (change)= "changeField()"
    formControlName="senha"
    id="rd-senha_field-kcng8yh5"
    class="bricks-form__input required js-senha js-field-senha"
    name="senha"
    type="senha"
    data-use-type="STRING"
    placeholder="Senha *"
    required="required"
  >
</div> 
<div class="bricks-form__field with-select-flags">
  <label for="rd-phone_field-4y3yP4qr6eb4Uu1WENAoNA" class="bricks-form__label">
    Telefone*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      (change)= "changeField()"
      formControlName="telefone"
      id="rd-phone_field-4y3yP4qr6eb4Uu1WENAoNA"
      class="bricks-form__input required phone js-phone js-field-personal_phone"
      name="personal_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Telefone *"
      required="required"
    >
  </div>
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-sx1IDZuTLeYMjCUNkrT_JA" class="bricks-form__label">
    Colégio aonde estuda*
  </label>
  <input
    (change)= "changeField()"
    formControlName="colegio"
    id="rd-text_field-sx1IDZuTLeYMjCUNkrT_JA"
    class="bricks-form__input required js-text js-field-cf_colegio_aonde_estuda"
    name="custom_fields[1168017]"
    type="text"
    data-use-type="STRING"
    placeholder="Colégio aonde estuda *"
    required="required"
  >
</div>
<div class="bricks-form__field">
  <label for="rd-select_field-EQOhiJrjI2fCn-LSOOzKtg" class="bricks-form__label">
    Você é aluno(a) de*
  </label>
  <select
    (change)= "changeField()" 
    formControlName="alunode"
    id="rd-select_field-EQOhiJrjI2fCn-LSOOzKtg"
    class="bricks-form__input required js-field-cf_voce_e_aluno_a_de_colegio_visao"
    data-use-type="STRING"
    name="custom_fields[1235438]"
    required="required"
  >
    <option  disabled selected>
      Selecione
    </option>
    
      <option value="Escola Pública">Escola Pública</option>
    
      <option value="Escola Particular">Escola Particular</option>
    
  </select>
</div>
<div class="bricks-form__field">
  <label for="rd-turma_field-kcng8yh5" class="bricks-form__label">
    Série que irá frequentar em 2021*
  </label>
  <select (change)= "getNameClass();changeField()"
          formControlName="turma"  
          name="turma" 
          id="turma"  
          required="required" 
          class="bricks-form__input required "
        
    >
    <option disabled selected>Selecione Uma Turma</option> 
    <option *ngFor="let class of landClasses" [value]="class.token" >{{class.nome_turma}}</option> 
  
  </select>
  <input
    (change)= "changeField()"
    id="rd-select_field-kelhwh7o"
    style="display: none;" 
    class="bricks-form__input  js-field-cf_serie_que_frequenta_no_ano_atual" 
    data-use-type="STRING" 
    required="required" 
    name="custom_fields[1128266]"
    value=""
  >
  
</div>
<div class="bricks-form__field">
  <label for="rd-select_field-4Nqr8H-hyUs5z7pW5dKN_g" class="bricks-form__label">
    Unidade do Visão que pretende estudar*
  </label>
  <select
    (change)= "changeField()" 
    formControlName="unidadequevaiestudar"
    id="rd-select_field-4Nqr8H-hyUs5z7pW5dKN_g"
    class="bricks-form__input required js-field-cf_unidade_do_visao_que_pretende_estudar"
    data-use-type="STRING"
    name="custom_fields[1235453]"
    required="required"
  >
    <option  disabled selected>
      Selecione
    </option>
    
      <option value="Coqueiros">Coqueiros</option>
    
      <option value="Kobrasol">Kobrasol</option>
    
      <option value="Pedra Branca">Pedra Branca</option>
    
  </select>
</div>


<div class="bricks-form__field " >
  
    <input type="hidden" name="custom_fields[1161112]" value="0">
  
  <div class="bricks-form__field__option">
    <label class="bricks-form__label">
      <input
        (change)= "changeField()"
        id="rd-checkbox_field-WjusADQmuup1W106adP7TA"
        name="custom_fields[1161112]"
        type="checkbox"
        value="1"
        data-use-type="STRING"
        data-always-visible=""
        class="bricks-form__field__option__input required js-field-cf_li_e_concordo_com_o_edital_do_concoc"
        required="required"
      >
      Li e concordo com o edital do concurso de bolsas*
    </label>
  </div>
</div>
<div class="bricks-form__field">
  <label for="captcha" class="bricks-form__label">
    <span id="math_expression">1 + 1 = ?</span>
  </label>
  <input
    (change)= "changeField()" 
    formControlName="captcha"
    id="captcha"
    class="bricks-form__input math rd-bricks-captcha"
    name="captcha"
    type="text"
    placeholder="1 + 1 = ?"
  >
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-joq3m2w0 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #ffdc00;
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      color: #380039;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 350px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    #rd-button-disabled {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #ffdc00;
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      color: #380039;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 350px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2w0 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2w0"
    >
      QUERO PARTICIPAR!
    </button>
  </div>

  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-disabled"
      (click)= "avisoCampos()"
    >
      QUERO PARTICIPAR!
    </button>
  </div>



    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>

  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1609793614"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1609793614"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1609793614"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/concurso-medio-visao-2021/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/concurso-medio-visao-2021/conversao'); } catch(err) { }
        }
      

      

      try {
        var jsonApiResponse = JSON.parse(resp.responseText);
        if (jsonApiResponse['result'] === 'double_opt_in_email_sent') {
          top.location.href = jsonApiResponse['redirect_to'];

          return;
        }
      } catch(err) {
        console.log(err, resp)
      }

      var redirectTo = $("input[name='redirect_to']");

      if (redirectTo.length && redirectTo.val().length) {
        top.location.href = $("input[name='redirect_to']").val();
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


<style type="text/css" rel="stylesheet">
  
    #rd-text-kfh10oqu {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-24px;
      max-width: 375px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfh10oqu" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 13px;">Confira o edital completo clicando </strong><a href="https://drive.google.com/file/d/1xLlFmh7dR_OZrjF8tkAMIn2W6lKw9A9F/view?usp=sharing" rel="noopener noreferrer" target="_blank" title="" style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 13px; background-color: transparent;"><strong><u>AQUI</u></strong></a></p>
</div>

</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-js4yspoe {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$8f492dcrcov), linear-gradient(to bottom, rgba(143,0,143,1) 0%, rgba(143,0,143,1) 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-js4yspoe > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-js4yspoe" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-js4yspof {
      min-height: 108px;
    }

    #rd-row-js4yspof .bricks--column > div {
      min-height: 108px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-js4yspof.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-js4yspof" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-julgvhmc {
      flex: 12 0 0;
    }

    #rd-column-julgvhmc > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-julgvhmc > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-julgvhmc" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfh1f7ce {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 684px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfh1f7ce" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 20px; background-color: rgb(3, 65, 164);"> SAIBA PORQUE O VISÃO É SURPREENDENTE! </strong></h1>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kggsjw4e {
      min-height: 236px;
    }

    #rd-row-kggsjw4e .bricks--column > div {
      min-height: 236px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kggsjw4e.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kggsjw4e" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kggskg06 {
      flex: 12 0 0;
    }

    #rd-column-kggskg06 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kggskg06 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kggskg06" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-video-kggskg07 {
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 500px;
      max-width: 100%;
    }

    #rd-video-kggskg07 iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kggskg07" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/Kn3HKzYDCS8?autoplay=0&rel=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kggsl4e0 {
      min-height: 50px;
    }

    #rd-row-kggsl4e0 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kggsl4e0.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kggsl4e0" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kggsl4e1 {
      flex: 12 0 0;
    }

    #rd-column-kggsl4e1 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kggsl4e1 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kggsl4e1" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kfgwci7x {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kfgwci7x > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kfgwci7x" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kfgwci7y {
      min-height: 236px;
    }

    #rd-row-kfgwci7y .bricks--column > div {
      min-height: 236px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kfgwci7y.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kfgwci7y" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kfgwci7z {
      flex: 12 0 0;
    }

    #rd-column-kfgwci7z > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kfgwci7z > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kfgwci7z" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kfh1jj59 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 360px;
      margin-top: 40px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfh1jj59" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 20px; font-family: Open Sans, sans-serif; background-color: rgb(3, 65, 164);"> ENTENDA COMO FUNCIONA! </strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kfpft4l9 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-117px;
      max-width: 688px;
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kfpft4l9" class="bricks--component bricks--component-text rd-text">
  <ul><li style="text-align: left; line-height: 1.5;">Primeiro, realize a inscrição no formulário acima;</li><li style="text-align: left; line-height: 1.5;">Depois disso, você terá até dia 20 de janeiro, às 15h, para fazer o envio do arquivo do seu boletim do ano letivo de 2019 (em imagem ou pdf), para o e-mail: <strong>tatiane.mattiazzi@colegiovisao.com.br</strong>;</li><li style="text-align: left; line-height: 1.5;">Logo após a sua inscrição nessa página, você receberá em seu e-mail o link da plataforma com login e senha. Recomendamos que você teste seu login com antecedência;</li><li style="text-align: left; line-height: 1.5;">Depois é só esperar pelo dia da prova, que será totalmente online e estará disponível no dia 21/01/2021, exatamente às 15:00h, na aba de "simulados" dentro da plataforma;</li><li style="text-align: left; line-height: 1.5;">Na prova, serão 30 questões sobre Língua Portuguesa, Matemática, Física, Química, Biologia, História, Geografia, Sociologia e Filosofia;</li><li style="text-align: left; line-height: 1.5;">Para a premiação, levaremos em conta a nota da sua prova online e a média do seu boletim escolar de 2019, tendo alguns critérios de desempate que você pode conferir no edital;</li><li style="text-align: left; line-height: 1.5;">O gabarito estará disponível dentro da plataforma, no mesmo local que você realizou o simulado, 24h após a finalização da prova;</li><li style="text-align: left; line-height: 1.5;">O resultado do concurso será divulgado individualmente no dia 22/01/2021, a partir das 18h00;</li><li style="text-align: left; line-height: 1.5;">Caso você encontre algum problema ou tenha alguma dúvida, <strong>entre em contato conosco pelo WhatsApp: (48) 3722-3100</strong></li><li style="text-align: left; line-height: 1.5;">Ah, lembre-se de conferir o edital pelo link abaixo, para entender como funcionará o concurso.</li></ul>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-kfh1gywm {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: rgba(3,65,164,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 40px;
      margin-top: 20px;
      max-width: 275px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kfh1gywm {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://drive.google.com/file/d/1xLlFmh7dR_OZrjF8tkAMIn2W6lKw9A9F/view?usp=sharing"
    id="rd-button-kfh1gywm"
    target="_blank"
    title=""
  >
    CONFIRA O EDITAL COMPLETO
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-jrux37c9 {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1607003484/$8f492dcrcov), linear-gradient(to bottom, rgba(143,0,143,1) 0%, rgba(143,0,143,1) 100%);
            background-position-x: 50%;
            background-position-y: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 1px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-jrux37c9 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-jrux37c9" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-juljjjfd {
      min-height: 50px;
    }

    #rd-row-juljjjfd .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-juljjjfd.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-juljjjfd" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-juljjjfe {
      flex: 12 0 0;
    }

    #rd-column-juljjjfe > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-juljjjfe > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-juljjjfe" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-juljjlsc {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 451px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-juljjlsc" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 12px; color: rgb(255, 255, 255); font-family: Ubuntu, sans-serif;">® Colégio Visão - Todos os direitos reservados - Feito por Nota Máxima</span></p>
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="1911101" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      _gaq.push(['_setAccount', uuid]);
      if (document.domain.indexOf(domain) != -1) {
        _gaq.push(['_setDomainName', domain]);
      }
      _gaq.push(['_setAllowLinker', true]);
      _gaq.push(['_trackPageview']);

      (function() {
        var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
        ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      })();

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id, null, true]);

      if (idsDataset.experimentContentId) {
        _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId, null, true]);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    $(window).on("load", function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    });
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
  </body>
</html>
