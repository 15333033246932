
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content=" Participe do Concurso de Bolsas e tenha a chance de ganhar até 100%!">
    

    
      <meta property="og:title" content="Concurso de Bolsas Santa Clara Challenge!" />
      <meta name="twitter:title" content="Concurso de Bolsas Santa Clara Challenge!" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/concurso-de-bolsas-santa-clara-challenge-assis" />
    

    <title>Concurso de Bolsas Santa Clara Challenge!</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__field--hidden {
        display: none;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript"> </script>

    
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
    </script>
    
  </head>
  <body>
    
      
        <script type="text/javascript">
          (function() {
            var scriptId = "ga-gtag";

            if (document.getElementById(scriptId)) return;

            var script = document.createElement("script");
            var trackingId = ""
            var lpId = "6583807"
            var options = { send_page_view: !false }

            script.setAttribute("id", scriptId);
            script.setAttribute("type", "text/javascript");
            script.setAttribute(
              "src",
              `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
            );
            script.setAttribute("async", "");

            document.head.insertBefore(script, document.head.firstChild);

            gtag("js", new Date());
            gtag("config", trackingId, options);
            gtag("event", "RD Landing Pages", {
              rd_asset_id: lpId,
              rd_filter: "",
              rd_action: "viewed"
            })
            var experimentContentId = ""
            if(experimentContentId){
              gtag("event", "RD Landing Pages", {
                rd_asset_id: lpId,
                rd_filter: "experiment:"+experimentContentId,
                rd_action: "viewed"
              })
            }
          }());
        </script>
      
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vm {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1722622880/$lgxu2oa4th9), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
            background-position-x: 50%;
            background-position-y: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #585858;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vm > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2vm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lp7aj25c {
      min-height: 50px;
    }

    #rd-row-lp7aj25c .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lp7aj25c.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lp7aj25c.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lp7aj25c" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lp7aj25d {
      flex: 12 0 0;
    }

    #rd-column-lp7aj25d > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lp7aj25d > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lp7aj25d" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lp7aj66k {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lp7aj66k" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lzd1ku6j {
      min-height: 50px;
    }

    #rd-row-lzd1ku6j .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lzd1ku6j.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lzd1ku6j.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lzd1ku6j" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lzd1ku6k {
      flex: 12 0 0;
    }

    #rd-column-lzd1ku6k > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lzd1ku6k > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lzd1ku6k" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lq10aw0l {
      min-height: 50px;
    }

    #rd-row-lq10aw0l .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lq10aw0l.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lq10aw0l.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lq10aw0l" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lq10aw0m {
      flex: 12 0 0;
    }

    #rd-column-lq10aw0m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lq10aw0m > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lq10aw0m" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-lzd1knj8 {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 200px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  

  
</style>

<div id="rd-box-lzd1knj8" class="bricks--component bricks--component-box rd-box">
  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lzd1ki3n {
      min-height: 50px;
    }

    #rd-row-lzd1ki3n .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lzd1ki3n.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lzd1ki3n.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lzd1ki3n" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lzd1ki3o {
      flex: 12 0 0;
    }

    #rd-column-lzd1ki3o > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lzd1ki3o > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lzd1ki3o" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lq10b43x {
      margin-top: 20px;
      margin-bottom: 30px;
      align-self: center;
      max-width: 296px;
    }

    #rd-image-lq10b43x a,
    #rd-image-lq10b43x img {
      width: 296px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lq10b43x {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lq10b43x" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1722963678/$dxdmvi3bwdq" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lzgwgq3r {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lzgwgq3r" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-lzd3ilx3 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 940px;
      margin-top: 10px;
      margin-bottom: 17px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lzd3ilx3" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><span style="background-color: transparent; color: rgb(0, 94, 52); font-size: 27px; font-family: 'Arial Black', Gadget, sans-serif;">Selecione abaixo o seu&nbsp;</span><u style="background-color: transparent; color: rgb(160, 190, 91); font-size: 27px; font-family: 'Arial Black', Gadget, sans-serif;">nível de ensino</u><span style="background-color: transparent; color: rgb(0, 94, 52); font-size: 27px; font-family: 'Arial Black', Gadget, sans-serif;">&nbsp;em 2025:</span></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lzd4pp5p {
      min-height: 50px;
    }

    #rd-row-lzd4pp5p .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lzd4pp5p.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lzd4pp5p.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lzd4pp5p" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lzd4pp5q {
      flex: 12 0 0;
    }

    #rd-column-lzd4pp5q > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lzd4pp5q > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lzd4pp5q" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lzgw7bwi {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lzgw7bwi" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>




<style type="text/css" rel="stylesheet">
  
    a#rd-button-lzgw2maq {
      display: flex;
      flex-direction: column;
    }

    #rd-button-lzgw2maq.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #ec7825;
      border-bottom-style: none;
      border-color: #ffffff;
      border-left-style: none;
      border-radius: 10px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Arial, sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 236px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-lzgw2maq {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://conteudos.notamaxima.com.br/concurso-de-bolsas-santa-clara-challenge-assis-ef1"
    id="rd-button-lzgw2maq"
    target="_blank"
    title="Aluno de 3º a 5º ano"
  >
    Aluno de 3º a 5º ano
  </a>



<style type="text/css" rel="stylesheet">
  
    a#rd-button-lzgw5huk {
      display: flex;
      flex-direction: column;
    }

    #rd-button-lzgw5huk.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #ec7825;
      border-bottom-style: none;
      border-color: #ffffff;
      border-left-style: none;
      border-radius: 10px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Arial, sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 279px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-lzgw5huk {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="assis/concurso-de-bolsas-santa-clara-challenge-assis-eff-em"
    id="rd-button-lzgw5huk"
    target="_blank"
    title="Aluno de 6º ano a 3ª série EM"
  >
    Aluno de 6º ano a 3ª série EM
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lp78waxj {
      min-height: 50px;
    }

    #rd-row-lp78waxj .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lp78waxj.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lp78waxj.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lp78waxj" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lp78waxk {
      flex: 12 0 0;
    }

    #rd-column-lp78waxk > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lp78waxk > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lp78waxk" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lzgwfojj {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lzgwfojj" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-image-lp2zvf4i {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 142px;
    }

    #rd-image-lp2zvf4i a,
    #rd-image-lp2zvf4i img {
      width: 142px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lp2zvf4i {
        align-self: center;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-image-lp2zvf4i {
          margin-top: 25px;
          margin-bottom: 10px;
          align-self: center;
          max-width: 319px;
        }

        #rd-image-lp2zvf4i a,
        #rd-image-lp2zvf4i img {
          width: 319px;
          max-width: 100%;
          height: auto;
          border-bottom-style: none;
          border-color: #000000;
          border-left-style: none;
          border-radius: 0px;
          border-right-style: none;
          border-top-style: none;
          border-width: 1px;
        }
      }
    
  
</style>



<div id="rd-image-lp2zvf4i" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1722622880/$jft2y7zo9k" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lp7ablfm {
      min-height: 50px;
    }

    #rd-row-lp7ablfm .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lp7ablfm.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lp7ablfm.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lp7ablfm" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lp7ablfn {
      flex: 12 0 0;
    }

    #rd-column-lp7ablfn > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lp7ablfn > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lp7ablfn" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lq10f61j {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lq10f61j" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6583807" data-experiment-content-id=""></script>

    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
    

  </body>
</html>
