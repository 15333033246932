import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnemGratuitoRiodosulComponent } from './enem-gratuito/enem-gratuito-riodosul/enem-gratuito-riodosul.component';
import { EnemGratuitoLagesComponent } from './enem-gratuito/enem-gratuito-lages/enem-gratuito-lages.component';
import { EnemGratuitoBalnearioComponent } from './enem-gratuito/enem-gratuito-balneario/enem-gratuito-balneario.component';
import { EnemGratuitoBlumenauComponent } from './enem-gratuito/enem-gratuito-blumenau/enem-gratuito-blumenau.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmacaoEnemGratuitoComponent } from './enem-gratuito/confirmacao-enem-gratuito/confirmacao-enem-gratuito.component';
import { HttpClientModule } from '@angular/common/http';
import { ConcocHawkingComponent } from './concoc/concoc-hawking/concoc-hawking.component';
import { ConcocMedioBalnearioComponent } from './concoc/concoc-medio-balneario/concoc-medio-balneario.component';
import { ConcocMedioLagesComponent } from './concoc/concoc-medio-lages/concoc-medio-lages.component';
import { ConcocMedioRiodosulComponent } from './concoc/concoc-medio-riodosul/concoc-medio-riodosul.component';
import { ConcocHawkingConfirmacaoComponent } from './concoc/concoc-hawking-confirmacao/concoc-hawking-confirmacao.component';
import { ConcocComponent } from './concoc/concoc.component';
import { ConcocMedioRiodosulConfirmacaoComponent } from './concoc/concoc-medio-riodosul-confirmacao/concoc-medio-riodosul-confirmacao.component';
import { ConcocMedioBalnearioConfirmacaoComponent } from './concoc/concoc-medio-balneario-confirmacao/concoc-medio-balneario-confirmacao.component';
import { ConcocMedioLagesConfirmacaoComponent } from './concoc/concoc-medio-lages-confirmacao/concoc-medio-lages-confirmacao.component';
import { ConcocFundamentalRiodosulComponent } from './concoc/concoc-fundamental-riodosul/concoc-fundamental-riodosul.component';
import { ConcocFundamentalRiodosulConfirmacaoComponent } from './concoc/concoc-fundamental-riodosul-confirmacao/concoc-fundamental-riodosul-confirmacao.component';
import { ConcocFundamentalLagesComponent } from './concoc/concoc-fundamental-lages/concoc-fundamental-lages.component';
import { ConcocFundamentalLagesConfirmacaoComponent } from './concoc/concoc-fundamental-lages-confirmacao/concoc-fundamental-lages-confirmacao.component';
import { ConcocMedioBeraldoComponent } from './concoc/concoc-medio-beraldo/concoc-medio-beraldo.component';
import { ConcocMedioBeraldoConfirmacaoComponent } from './concoc/concoc-medio-beraldo-confirmacao/concoc-medio-beraldo-confirmacao.component';
import { ConcocFundamentalBeraldoComponent } from './concoc/concoc-fundamental-beraldo/concoc-fundamental-beraldo.component';
import { ConcocFundamentalBeraldoConfirmacaoComponent } from './concoc/concoc-fundamental-beraldo-confirmacao/concoc-fundamental-beraldo-confirmacao.component';
import { QuimicaInDozeComponent } from './quimica/quimica-in-doze/quimica-in-doze.component';
import { ConfirmacaoQuimicaindozeComponent } from './quimica/confirmacao-quimicaindoze/confirmacao-quimicaindoze.component';
import { ConcursoPrimeComponent } from './prime/concurso-prime/concurso-prime.component';
import { ConcursoPrimeConfirmacaoComponent } from './prime/concurso-prime-confirmacao/concurso-prime-confirmacao.component';
import { ConcursoMedioSigmaComponent } from './concurso-bolsas/concurso-medio-sigma/concurso-medio-sigma.component';
import { ConcursoMedioSigmaConfirmacaoComponent } from './concurso-bolsas/concurso-medio-sigma-confirmacao/concurso-medio-sigma-confirmacao.component';
import { ConcursoMedioFatoComponent } from './concurso-bolsas/concurso-medio-fato/concurso-medio-fato.component';
import { ConcursoMedioFatoConfirmacaoComponent } from './concurso-bolsas/concurso-medio-fato-confirmacao/concurso-medio-fato-confirmacao.component';
import { ConcursoFundamentalFatoComponent } from './concurso-bolsas/concurso-fundamental-fato/concurso-fundamental-fato.component';
import { ConcursoFundamentalFatoConfirmacaoComponent } from './concurso-bolsas/concurso-fundamental-fato-confirmacao/concurso-fundamental-fato-confirmacao.component';
import { SimuladoEnemOnlineComponent } from './concurso-bolsas/simulado-enem-online/simulado-enem-online.component';
import { SimuladoEnemOnlineConfirmacaoComponent } from './concurso-bolsas/simulado-enem-online-confirmacao/simulado-enem-online-confirmacao.component';
import { ConcocEf2AsterComponent } from './concurso-bolsas/concoc-ef2-aster/concoc-ef2-aster.component';
import { ConcocAsterEf2ConfirmacaoComponent } from './concurso-bolsas/concoc-aster-ef2-confirmacao/concoc-aster-ef2-confirmacao.component';
import { SimuladoUelSigmaComponent } from './concurso-bolsas/simulado-uel-sigma/simulado-uel-sigma.component';
import { SimuladoUelSigmaConfirmacaoComponent } from './concurso-bolsas/simulado-uel-sigma-confirmacao/simulado-uel-sigma-confirmacao.component';
import { ConcursoEinsteenComponent } from './concurso-bolsas/concurso-einsteen/concurso-einsteen.component';
import { ConcursoEinsteenConfirmacaoComponent } from './concurso-bolsas/concurso-einsteen-confirmacao/concurso-einsteen-confirmacao.component';
import { ConcursoMedioVisaoComponent } from './concurso-bolsas/concurso-medio-visao/concurso-medio-visao.component';
import { ConcursoMedioVisaoConfirmacaoComponent } from './concurso-bolsas/concurso-medio-visao-confirmacao/concurso-medio-visao-confirmacao.component';
import { ConcursoMedioCsaComponent } from './concurso-bolsas/concurso-medio-csa/concurso-medio-csa.component';
import { ConcursoMedioCsaConfirmacaoComponent } from './concurso-bolsas/concurso-medio-csa-confirmacao/concurso-medio-csa-confirmacao.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConcursoMedioVisao2021HomeComponent } from './concurso-bolsas/concurso-medio-visao2021-home/concurso-medio-visao2021-home.component';
import { ConcursoMedioVisao2021ConfirmacaoComponent } from './concurso-bolsas/concurso-medio-visao2021-confirmacao/concurso-medio-visao2021-confirmacao.component';
import { ConcocEnemBalnearioComponent } from './concoc/concoc-enem-balneario/concoc-enem-balneario.component';
import { ConcocEnemBalnearioConfirmacaoComponent } from './concoc/concoc-enem-balneario-confirmacao/concoc-enem-balneario-confirmacao.component';
import { ConcocEnemBlumenauComponent } from './concoc/concoc-enem-blumenau/concoc-enem-blumenau.component';
import { ConcocEnemLagesComponent } from './concoc/concoc-enem-lages/concoc-enem-lages.component';
import { ConcocEnemRioDoSulComponent } from './concoc/concoc-enem-rio-do-sul/concoc-enem-rio-do-sul.component';
import { ConcocEnemBlumenauConfirmacaoComponent } from './concoc/concoc-enem-blumenau-confirmacao/concoc-enem-blumenau-confirmacao.component';
import { ConcocEnemLagesConfirmacaoComponent } from './concoc/concoc-enem-lages-confirmacao/concoc-enem-lages-confirmacao.component';
import { ConcocEnemRioDoSulConfirmacaoComponent } from './concoc/concoc-enem-rio-do-sul-confirmacao/concoc-enem-rio-do-sul-confirmacao.component';
import { ConcursoSemiNotaMaximaComponent } from './concoc/concurso-semi-nota-maxima/concurso-semi-nota-maxima.component';
import { ConcursoSemiNotaMaximaConfirmacaoComponent } from './concoc/concurso-semi-nota-maxima-confirmacao/concurso-semi-nota-maxima-confirmacao.component';
import { CampanhaAlunoNotaDezComponent } from './concoc/campanha-aluno-nota-dez/campanha-aluno-nota-dez.component';
import { CampanhaAlunoNotaDezConfirmacaoComponent } from './concoc/campanha-aluno-nota-dez-confirmacao/campanha-aluno-nota-dez-confirmacao.component';
import { ConcocSemiBalnearioComponent } from './concoc/concoc-semi-balneario/concoc-semi-balneario.component';
import { ConcocSemiBalnearioConfirmacaoComponent } from './concoc/concoc-semi-balneario-confirmacao/concoc-semi-balneario-confirmacao.component';
import { ConcocSemiBlumenauComponent } from './concoc/concoc-semi-blumenau/concoc-semi-blumenau.component';
import { ConcocSemiBlumenauConfirmacaoComponent } from './concoc/concoc-semi-blumenau-confirmacao/concoc-semi-blumenau-confirmacao.component';
import { ConcocSemiLagesComponent } from './concoc/concoc-semi-lages/concoc-semi-lages.component';
import { ConcocSemiLagesConfirmacaoComponent } from './concoc/concoc-semi-lages-confirmacao/concoc-semi-lages-confirmacao.component';
import { ConcocSemiRioDoSulComponent } from './concoc/concoc-semi-rio-do-sul/concoc-semi-rio-do-sul.component';
import { ConcocSemiRioDoSulConfirmacaoComponent } from './concoc/concoc-semi-rio-do-sul-confirmacao/concoc-semi-rio-do-sul-confirmacao.component';
import { OutOfServiceComponent } from './out-of-service/out-of-service.component';
import { SimuladoNacionalEnemComponent } from './enem-gratuito/simulado-nacional-enem/simulado-nacional-enem.component';
import { SimuladoNacionalEnemConfirmacaoComponent } from './enem-gratuito/simulado-nacional-enem-confirmacao/simulado-nacional-enem-confirmacao.component';
import { ConcocEf2022LagesComponent } from './concoc/concoc-ef2022-lages/concoc-ef2022-lages.component';
import { ConcocEf2022LagesConfirmacaoComponent } from './concoc/concoc-ef2022-lages-confirmacao/concoc-ef2022-lages-confirmacao.component';
import { ConcocEm2022LagesComponent } from './concoc/concoc-em2022-lages/concoc-em2022-lages.component';
import { ConcocEm2022LagesConfirmacaoComponent } from './concoc/concoc-em2022-lages-confirmacao/concoc-em2022-lages-confirmacao.component';
import { ConcocEf2022RioDoSulComponent } from './concoc/concoc-ef2022-rio-do-sul/concoc-ef2022-rio-do-sul.component';
import { ConcocEf2022RioDoSulConfirmacaoComponent } from './concoc/concoc-ef2022-rio-do-sul-confirmacao/concoc-ef2022-rio-do-sul-confirmacao.component';
import { ConcocEm2022RioDoSulComponent } from './concoc/concoc-em2022-rio-do-sul/concoc-em2022-rio-do-sul.component';
import { ConcocEm2022RioDoSulConfirmacaoComponent } from './concoc/concoc-em2022-rio-do-sul-confirmacao/concoc-em2022-rio-do-sul-confirmacao.component';  
import { ConcocPv2022OnLineComponent } from './concoc-2022/concoc-pv2022-on-line/concoc-pv2022-on-line.component';
import { ConcocPv2022OnLineConfirmacaoComponent } from './concoc-2022/concoc-pv2022-on-line-confirmacao/concoc-pv2022-on-line-confirmacao.component'; 
import { ConcocEnem2022BalnearioComponent } from './concoc/concoc-enem-2022-balneario/concoc-enem-2022-balneario.component';
import { ConcocEnem2022BalnearioConfirmacaoComponent } from './concoc/concoc-enem-2022-balneario-confirmacao/concoc-enem-2022-balneario-confirmacao.component';
import { ConcocEnem2022BlumenauComponent } from './concoc/concoc-enem-2022-blumenau/concoc-enem-2022-blumenau.component';
import { ConcocEnem2022BlumenauConfirmacaoComponent } from './concoc/concoc-enem-2022-blumenau-confirmacao/concoc-enem-2022-blumenau-confirmacao.component';
import { ConcocEnem2022LagesComponent } from './concoc/concoc-enem-2022-lages/concoc-enem-2022-lages.component';
import { ConcocEnem2022LagesConfirmacaoComponent } from './concoc/concoc-enem-2022-lages-confirmacao/concoc-enem-2022-lages-confirmacao.component';
import { ConcocEnem2022RioDoSulComponent } from './concoc/concoc-enem-2022-rio-do-sul/concoc-enem-2022-rio-do-sul.component';
import { ConcocEnem2022RioDoSulConfirmacaoComponent } from './concoc/concoc-enem-2022-rio-do-sul-confirmacao/concoc-enem-2022-rio-do-sul-confirmacao.component';
 
import { ConcocEnsinoFundamental2023BalnearioComponent } from './concoc-2023/concoc-ensino-fundamental2023-balneario/concoc-ensino-fundamental2023-balneario.component';
import { ConcocEnsinoFundamental2023LagesComponent } from './concoc-2023/concoc-ensino-fundamental2023-lages/concoc-ensino-fundamental2023-lages.component';
import { ConcocEnsinoFundamental2023RioDoSulComponent } from './concoc-2023/concoc-ensino-fundamental2023-rio-do-sul/concoc-ensino-fundamental2023-rio-do-sul.component';
import { ConcocEnsinoMedio2023RioDoSulComponent } from './concoc-2023/concoc-ensino-medio2023-rio-do-sul/concoc-ensino-medio2023-rio-do-sul.component';
import { ConcocEnsinoMedio2023BalnearioComponent } from './concoc-2023/concoc-ensino-medio2023-balneario/concoc-ensino-medio2023-balneario.component';
import { ConcocEnsinoMedio2023LagesComponent } from './concoc-2023/concoc-ensino-medio2023-lages/concoc-ensino-medio2023-lages.component';
import { ConcocEnsinoMedio2023BlumenauComponent } from './concoc-2023/concoc-ensino-medio2023-blumenau/concoc-ensino-medio2023-blumenau.component';
import { Concoc2023ConfirmacaoComponent } from './concoc-2023/concoc2023-confirmacao/concoc2023-confirmacao.component';
import { ConcursoDeBolsasColegioFatoFundamentalComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-fundamental/concurso-de-bolsas-colegio-fato-fundamental.component';
import { ConcursoDeBolsasColegioFatoPreVestibularComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-pre-vestibular/concurso-de-bolsas-colegio-fato-pre-vestibular.component';
import { ConcursoDeBolsasColegioFatoConfirmacaoComponent } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-confirmacao/concurso-de-bolsas-colegio-fato-confirmacao.component';
import { ConcursoDeBolsasColegioFatoMedio2023Component } from './concurso-de-bolsas-2023/concurso-de-bolsas-colegio-fato-medio2023/concurso-de-bolsas-colegio-fato-medio2023.component';
import { Set2023ConcocRioDoSulMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-rio-do-sul-medio/set2023-concoc-rio-do-sul-medio.component';
import { Set2023ConcocConfirmacaoProvaComponent } from './concurso-de-bolsas-2023/set2023-concoc-confirmacao-prova/set2023-concoc-confirmacao-prova.component';
import { Set2023ConcocBcMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-bc-medio/set2023-concoc-bc-medio.component';
import { Set2023ConcocBcEf2Component } from './concurso-de-bolsas-2023/set2023-concoc-bc-ef2/set2023-concoc-bc-ef2.component';
import { Set2023ConcocLagesEf2Component } from './concurso-de-bolsas-2023/set2023-concoc-lages-ef2/set2023-concoc-lages-ef2.component';
import { Set2023ConcocRioDoSulEf23Component } from './concurso-de-bolsas-2023/set2023-concoc-rio-do-sul-ef23/set2023-concoc-rio-do-sul-ef23.component';
import { Set2023ConcocBlumenauMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-blumenau-medio/set2023-concoc-blumenau-medio.component';
import { Set2023ConcocLagesMedioComponent } from './concurso-de-bolsas-2023/set2023-concoc-lages-medio/set2023-concoc-lages-medio.component';
import { SimuladoAcafeComponent } from './acafe/2023/simulado-acafe/simulado-acafe.component';
import { SimuladoAcafeAgradecimentoComponent } from './acafe/2023/simulado-acafe-agradecimento/simulado-acafe-agradecimento.component'; 
import { AcafesComponent } from './new-acafe/acafes/acafes.component';
import { AcafeAgradecimentoComponent } from './new-acafe/acafe-agradecimento/acafe-agradecimento.component';
import { Concursodebolsas2024Component } from './concursodebolsas2024/concursodebolsas2024.component';
import { Concursodebolsas2024AgradecimentoComponent } from './concursodebolsas2024-agradecimento/concursodebolsas2024-agradecimento.component';
import { OnlineComponent } from './concoc2024/online/online.component';
import { BlumenauComponent } from './concoc2024/presencial/blumenau/blumenau.component';
import { LagesComponent } from './concoc2024/presencial/lages/lages.component';
import { RiodosulComponent } from './concoc2024/presencial/riodosul/riodosul.component';
import { ConfirmacaoComponent } from './concoc2024/online/confirmacao/confirmacao.component';
import { ConfirmacaoPresencialComponent } from './concoc2024/presencial/confirmacao-presencial/confirmacao-presencial.component';
import { PreConfirmacaoComponent } from './concoc2024/online/pre-confirmacao/pre-confirmacao.component';
import { BlumenauPreConfirmacaoComponent } from './concoc2024/presencial/blumenau-pre-confirmacao/blumenau-pre-confirmacao.component';
import { LagesPreConfirmacaoComponent } from './concoc2024/presencial/lages-pre-confirmacao/lages-pre-confirmacao.component';
import { RiodosulPreConfirmacaoComponent } from './concoc2024/presencial/riodosul-pre-confirmacao/riodosul-pre-confirmacao.component';
import { BalnearioComponent } from './concoc2024/presencial/balneario/balneario.component';
import { BalnearioPreConfirmacaoComponent } from './concoc2024/presencial/balneario-pre-confirmacao/balneario-pre-confirmacao.component';
import { Simuladopcsc2024Component } from './simuladopcsc24/simuladopcsc2024/simuladopcsc2024.component';
import { AgradecimentoSimuladopcsc2024Component } from './simuladopcsc24/agradecimento-simuladopcsc2024/agradecimento-simuladopcsc2024.component';
import { PreparatorioEnemGratuito2024Component } from './preparatorio-enem-gratuito20241/preparatorio-enem-gratuito2024/preparatorio-enem-gratuito2024.component';
import { AgradecimentoPreparatorioEnemGratuito2024Component } from './preparatorio-enem-gratuito20241/agradecimento-preparatorio-enem-gratuito2024/agradecimento-preparatorio-enem-gratuito2024.component';
import { ConcursoBolsasOpEnem2024Component } from './concurso-bolsas-op-enem-2024/concurso-bolsas-op-enem2024/concurso-bolsas-op-enem2024.component';
import { Concoclagesjun2024Component } from './concoc-semi-2024-2/concoclagesjun2024/concoclagesjun2024.component';
import { Agradecimentoconcoclagesjun2024Component } from './concoc-semi-2024-2/agradecimentoconcoclagesjun2024/agradecimentoconcoclagesjun2024.component';
import { Concocriodosuljun2024Component } from './concoc-semi-2024-2/concocriodosuljun2024/concocriodosuljun2024.component';
import { Agradecimentoconcocriodosuljun2024Component } from './concoc-semi-2024-2/agradecimentoconcocriodosuljun2024/agradecimentoconcocriodosuljun2024.component';
import { Concocblumenaujun2024Component } from './concoc-semi-2024-2/concocblumenaujun2024/concocblumenaujun2024.component';
import { Agradecimentoconcocblumenaujun2024Component } from './concoc-semi-2024-2/agradecimentoconcocblumenaujun2024/agradecimentoconcocblumenaujun2024.component';
import { Agradecimentoconcocbalneariojun2024Component } from './concoc-semi-2024-2/agradecimentoconcocbalneariojun2024/agradecimentoconcocbalneariojun2024.component';
import { Concocbalneariocamboriujun2024Component } from './concoc-semi-2024-2/concocbalneariocamboriujun2024/concocbalneariocamboriujun2024.component';
import { Concocsemiextensivo20242Component } from './concoc-semi-2024-2/concocsemiextensivo20242/concocsemiextensivo20242.component';
import { Agradecimentoconcoconline20242Component } from './concoc-semi-2024-2/agradecimentoconcoconline20242/agradecimentoconcoconline20242.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisEffEmComponent } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis-eff-em/concurso-de-bolsas-santa-clara-challenge-assis-eff-em.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisEf1Component } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis-ef1/concurso-de-bolsas-santa-clara-challenge-assis-ef1.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaComponent } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaEf1Component } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1/concurso-de-bolsas-santa-clara-challenge-candido-mota-ef1.component';
import { ConcursoDeBolsasSantaClaraChallengeCandidoMotaEffEmComponent } from './colegio-santa-clara/candido-mota/concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em/concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em.component';
import { ConcursoDeBolsasSantaClaraChallengeAssisComponent } from './colegio-santa-clara/assis/concurso-de-bolsas-santa-clara-challenge-assis/concurso-de-bolsas-santa-clara-challenge-assis.component';
import { ColegioSantaClaraComponent } from './colegio-santa-clara/colegio-santa-clara.component';
import { ConcursoDeBolsasSantaClaraChallengeAgradecimentoComponent } from './colegio-santa-clara/concurso-de-bolsas-santa-clara-challenge-agradecimento/concurso-de-bolsas-santa-clara-challenge-agradecimento.component';
import { ConcocBalnearioFundamentalComponent } from './concoc-2025/coc-balneario/concoc-balneario-fundamental/concoc-balneario-fundamental.component';
import { AgradecimentoConcocBalnearioComponent } from './concoc-2025/coc-balneario/agradecimento-concoc-balneario/agradecimento-concoc-balneario.component';
import { ConcocBalnearioEmComponent } from './concoc-2025/coc-balneario/concoc-balneario-em/concoc-balneario-em.component';
import { ConcocBlumenauEmComponent } from './concoc-2025/coc-blumenau/concoc-blumenau-em/concoc-blumenau-em.component';
import { AgradecimentoConcocBlumenauComponent } from './concoc-2025/coc-blumenau/agradecimento-concoc-blumenau/agradecimento-concoc-blumenau.component';
import { ConcocLagesEmComponent } from './concoc-2025/coc-lages/concoc-lages-em/concoc-lages-em.component';
import { AgradecimentoConcocLagesEmComponent } from './agradecimento-concoc-lages-em/agradecimento-concoc-lages-em.component';
import { AgradecimentoConcocLagesComponent } from './concoc-2025/coc-lages/agradecimento-concoc-lages/agradecimento-concoc-lages.component';
import { ConcocLagesEfComponent } from './concoc-2025/coc-lages/concoc-lages-ef/concoc-lages-ef.component';
import { ConcocRioDoSulEfComponent } from './concoc-2025/coc-rio-do-sul/concoc-rio-do-sul-ef/concoc-rio-do-sul-ef.component';
import { ConcocRioDoSulEmComponent } from './concoc-2025/coc-rio-do-sul/concoc-rio-do-sul-em/concoc-rio-do-sul-em.component';
import { AgradecimentoConcocRdsComponent } from './concoc-2025/coc-rio-do-sul/agradecimento-concoc-rds/agradecimento-concoc-rds.component';


 

@NgModule({
  declarations: [
    AppComponent,
    EnemGratuitoRiodosulComponent,
    EnemGratuitoLagesComponent,
    EnemGratuitoBalnearioComponent,
    EnemGratuitoBlumenauComponent,
    ConfirmacaoEnemGratuitoComponent,
    ConcocHawkingComponent,
    ConcocMedioBalnearioComponent,
    ConcocMedioLagesComponent,
    ConcocMedioRiodosulComponent,
    ConcocHawkingConfirmacaoComponent,
    ConcocComponent,
    ConcocMedioRiodosulConfirmacaoComponent,
    ConcocMedioBalnearioConfirmacaoComponent,
    ConcocMedioLagesConfirmacaoComponent,
    ConcocFundamentalRiodosulComponent,
    ConcocFundamentalRiodosulConfirmacaoComponent,
    ConcocFundamentalLagesComponent,
    ConcocFundamentalLagesConfirmacaoComponent,
    ConcocMedioBeraldoComponent,
    ConcocMedioBeraldoConfirmacaoComponent,
    ConcocFundamentalBeraldoComponent,
    ConcocFundamentalBeraldoConfirmacaoComponent,
    QuimicaInDozeComponent,
    ConfirmacaoQuimicaindozeComponent,
    ConcursoPrimeComponent,
    ConcursoPrimeConfirmacaoComponent,
    ConcursoMedioSigmaComponent,
    ConcursoMedioSigmaConfirmacaoComponent,
    ConcursoMedioFatoComponent,
    ConcursoMedioFatoConfirmacaoComponent,
    ConcursoFundamentalFatoComponent, 
    ConcursoFundamentalFatoConfirmacaoComponent, 
    SimuladoEnemOnlineComponent, 
    SimuladoEnemOnlineConfirmacaoComponent, 
    ConcocEf2AsterComponent, 
    ConcocAsterEf2ConfirmacaoComponent, 
    SimuladoUelSigmaComponent, 
    SimuladoUelSigmaConfirmacaoComponent, 
    ConcursoEinsteenComponent, 
    ConcursoEinsteenConfirmacaoComponent, 
    ConcursoMedioVisaoComponent, 
    ConcursoMedioVisaoConfirmacaoComponent, 
    ConcursoMedioCsaComponent, 
    ConcursoMedioCsaConfirmacaoComponent, 
    PageNotFoundComponent, 
    ConcursoMedioVisao2021HomeComponent, 
    ConcursoMedioVisao2021ConfirmacaoComponent, 
    ConcocEnemBalnearioComponent, 
    ConcocEnemBalnearioConfirmacaoComponent, 
    ConcocEnemBlumenauComponent, 
    ConcocEnemLagesComponent, 
    ConcocEnemRioDoSulComponent,
    ConcocEnemBlumenauConfirmacaoComponent, 
    ConcocEnemLagesConfirmacaoComponent, 
    ConcocEnemRioDoSulConfirmacaoComponent, 
    ConcursoSemiNotaMaximaComponent, 
    ConcursoSemiNotaMaximaConfirmacaoComponent, 
    CampanhaAlunoNotaDezComponent, 
    CampanhaAlunoNotaDezConfirmacaoComponent, 
    ConcocSemiBalnearioComponent, 
    ConcocSemiBalnearioConfirmacaoComponent, 
    ConcocSemiBlumenauComponent,
     ConcocSemiBlumenauConfirmacaoComponent, 
     ConcocSemiLagesComponent, 
     ConcocSemiLagesConfirmacaoComponent, 
     ConcocSemiRioDoSulComponent, 
     ConcocSemiRioDoSulConfirmacaoComponent, 
     OutOfServiceComponent, 
     SimuladoNacionalEnemComponent, 
     SimuladoNacionalEnemConfirmacaoComponent, 
     ConcocEf2022LagesComponent, 
     ConcocEf2022LagesConfirmacaoComponent, 
     ConcocEm2022LagesComponent, 
     ConcocEm2022LagesConfirmacaoComponent, 
     ConcocEf2022RioDoSulComponent,
      ConcocEf2022RioDoSulConfirmacaoComponent, 
      ConcocEm2022RioDoSulComponent, 
      ConcocEm2022RioDoSulConfirmacaoComponent,  
       ConcocPv2022OnLineComponent, 
       ConcocPv2022OnLineConfirmacaoComponent,
     ConcocEnem2022BalnearioComponent,
     ConcocEnem2022BalnearioConfirmacaoComponent,
     ConcocEnem2022BlumenauComponent,
     ConcocEnem2022BlumenauConfirmacaoComponent,
     ConcocEnem2022LagesComponent,
     ConcocEnem2022LagesConfirmacaoComponent,
     ConcocEnem2022RioDoSulComponent,
     ConcocEnem2022RioDoSulConfirmacaoComponent, 
     ConcocEnsinoFundamental2023BalnearioComponent,  
     ConcocEnsinoFundamental2023LagesComponent,
     ConcocEnsinoFundamental2023RioDoSulComponent,
     ConcocEnsinoMedio2023RioDoSulComponent,
     ConcocEnsinoMedio2023BalnearioComponent,
     ConcocEnsinoMedio2023LagesComponent,
     ConcocEnsinoMedio2023BlumenauComponent,
     Concoc2023ConfirmacaoComponent,
     ConcursoDeBolsasColegioFatoMedio2023Component,
     ConcursoDeBolsasColegioFatoFundamentalComponent,
     ConcursoDeBolsasColegioFatoPreVestibularComponent,
     ConcursoDeBolsasColegioFatoConfirmacaoComponent,
     Set2023ConcocRioDoSulMedioComponent,
     Set2023ConcocConfirmacaoProvaComponent,
     Set2023ConcocBcMedioComponent,
     Set2023ConcocBcEf2Component,
     Set2023ConcocLagesEf2Component,
     Set2023ConcocRioDoSulEf23Component,
     Set2023ConcocBlumenauMedioComponent,
     Set2023ConcocLagesMedioComponent,
     SimuladoAcafeComponent,
     SimuladoAcafeAgradecimentoComponent, 
     AcafesComponent,
     AcafeAgradecimentoComponent,
     Concursodebolsas2024Component,
     Concursodebolsas2024AgradecimentoComponent,
     OnlineComponent,
     BlumenauComponent,
     LagesComponent,
     RiodosulComponent,
     ConfirmacaoComponent,
     PreConfirmacaoComponent,
     ConfirmacaoPresencialComponent,
     BlumenauPreConfirmacaoComponent,
     LagesPreConfirmacaoComponent,
     RiodosulPreConfirmacaoComponent,
     BalnearioComponent,
     BalnearioPreConfirmacaoComponent,
     Simuladopcsc2024Component,
     AgradecimentoSimuladopcsc2024Component,
     PreparatorioEnemGratuito2024Component,
     AgradecimentoPreparatorioEnemGratuito2024Component,
     ConcursoBolsasOpEnem2024Component,
     Concoclagesjun2024Component,
     Agradecimentoconcoclagesjun2024Component,
     Concocriodosuljun2024Component,
     Agradecimentoconcocriodosuljun2024Component,
     Concocblumenaujun2024Component,
     Agradecimentoconcocblumenaujun2024Component,
     Agradecimentoconcocbalneariojun2024Component,
     Concocbalneariocamboriujun2024Component,
     Concocsemiextensivo20242Component,
     Agradecimentoconcoconline20242Component,
     ConcursoDeBolsasSantaClaraChallengeAssisEffEmComponent,
     ConcursoDeBolsasSantaClaraChallengeAssisEf1Component,
     ConcursoDeBolsasSantaClaraChallengeCandidoMotaComponent,
     ConcursoDeBolsasSantaClaraChallengeCandidoMotaEf1Component,
     ConcursoDeBolsasSantaClaraChallengeCandidoMotaEffEmComponent,
     ConcursoDeBolsasSantaClaraChallengeAssisComponent,
     ColegioSantaClaraComponent,
     ConcursoDeBolsasSantaClaraChallengeAgradecimentoComponent,
     ConcocBalnearioFundamentalComponent,
     AgradecimentoConcocBalnearioComponent,
     ConcocBalnearioEmComponent,
     ConcocBlumenauEmComponent,
     AgradecimentoConcocBlumenauComponent,
     ConcocLagesEmComponent,
     AgradecimentoConcocLagesEmComponent,
     AgradecimentoConcocLagesComponent,
     ConcocLagesEfComponent,
     ConcocRioDoSulEfComponent,
     ConcocRioDoSulEmComponent,
     AgradecimentoConcocRdsComponent,
      
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
