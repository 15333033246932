import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ConcocService } from '../concoc.service';
import { Router, ActivatedRoute } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concoc-enem-2022-lages',
  templateUrl: './concoc-enem-2022-lages.component.html',
  styleUrls: ['./concoc-enem-2022-lages.component.scss']
})
export class ConcocEnem2022LagesComponent implements OnInit {

  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  public buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService)
  {
    this.landRoute          = "concoc-enem-2022-lages";
    this.urlRedirect        = "https://plataforma.coclages.com.br/redirect";
    this.landRouteNavigate  = "/concoc-enem-2022-lages-confirmacao";
   }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''), 
      senha: new FormControl(''),     
      telefone: new FormControl(''),
      alunode: new FormControl(''),
      presencial: new FormControl(''),
     /*  online: new FormControl(''), */
      captcha: new FormControl('')
    });
  }

  async onSubmit() {
    if(this.botaoEnviar){ 
      // TODO: Use EventEmitter with form value
      var data = this.profileForm.getRawValue();
      let colegio = '';
      if(data.presencial){colegio = 'Presencial';}
    /*  if(data.presencial && !data.online){colegio = 'Presencial';}
       if(data.online && !data.presencial){ colegio = 'Online';}
      if(data.online && data.presencial){ colegio = 'Presencial e Online';} */
      var jsondata = {
        name : data.nome,
        email: data.email, 
        senha: data.senha, 
        telefone: data.telefone,
        alunode :data.alunode,
        colegio: colegio,
        urlRedirect: this.urlRedirect,
        pv :"121017914916d9bbbca8fbf393875397"
      }
      this.concocService.registerSave(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
              let url:any = response.data;
              this.router.navigate( ['/concoc-enem-2022-lages-confirmacao'], {queryParams:{ link : btoa(url.link) } })
              //this.router.navigate( [this.landRouteNavigate ], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    if(data.nome != null && data.email != null && data.email != null &&
      data.telefone != null && data.alunode != null){ 
        this.buttonActive = true;
        this.botaoEnviar = true;
        console.log(data.presencial);
        console.log(data.online);
    }
  }
}
