import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-simulado-nacional-enem-confirmacao',
  templateUrl: './simulado-nacional-enem-confirmacao.component.html',
  styleUrls: ['./simulado-nacional-enem-confirmacao.component.scss']
})
export class SimuladoNacionalEnemConfirmacaoComponent implements OnInit {

  
  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
    //this.link = this.route.queryParams ; 
  }

}
