import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';
import { GtmService } from 'src/app/gtm.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
export interface Profile {
  nome: string;
  email: string;
  cf_cpf: string;
  cf_nome_da_sua_escola: string;
  city_id: string;
  cf_escola_publica_ou_particular: string;
  telefone: string;
  captcha: number;
}


@Component({
  selector: 'app-concocbalneariocamboriujun2024',
  templateUrl: './concocbalneariocamboriujun2024.component.html',
  styleUrls: ['./concocbalneariocamboriujun2024.component.scss']
})
export class Concocbalneariocamboriujun2024Component implements OnInit {
  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
    private router : Router,
    private formBuilder: FormBuilder,
    private  concocService: ConcocService,
    private gtmService: GtmService)
  { 
  this.landRoute          = "concoc-balneario-jun2024";
  this.urlRedirect        = "https://plataforma.notamaxima.com.br";
  this.landRouteNavigate  = "agradecimento-concoc-balneario-jun2024";
  }

  ngOnInit(): void {
    this.gtmService.insertGtmScript('GTM-WCVBBKZ9');
    this.profileForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      cf_cpf: ['', Validators.required],
      cf_nome_da_sua_escola: ['', Validators.required],
      city_id: ['', Validators.required],
      cf_escola_publica_ou_particular: ['', Validators.required],
      cf_como_voce_deseja_realizar_a_prova: ['', Validators.required],
      cf_qual_o_colegio_coc_mais_proximo: ['', Validators.required],
      telefone: ['', Validators.required],
      captcha: ['', Validators.required]

      
    });
  }

  async onSubmit() {
    setTimeout(() => {
 
      if(this.botaoEnviar){
        this.router.navigate( ['agradecimento-concoc-balneario-jun2024']);
        var data = this.profileForm.getRawValue() as Profile;
        var jsondata = {
          name : data.nome,
          email: data.email, 
          pass: '12345',   
          telefone: data.telefone,
          turmaqueentra: '9b7d66c65790d828745e91aff4ec60dc',

          
          studentFrom: data.cf_cpf,
          studentFrom2: data.cf_nome_da_sua_escola,
          studentFrom3: data.city_id, 
          studentFrom4: data.cf_escola_publica_ou_particular, 
          urlRedirect: this.urlRedirect ,
          pv :'9b7d66c65790d828745e91aff4ec60dc'
        }

          this.concocService.registerSave(jsondata)
          .subscribe(async res => {
              const response = res.body as ResponseApi;
              if (!response.error) { 
                  let url:any = response.data; 
                  this.router.navigate( ['agradecimento-concoc-balneario-jun2024'], {queryParams:{email:data.email, link : btoa(url.link) } })
              }
          }, err => console.log("Verifique sua conexão com a internet!"));
        
        
      }  
    }, 2000);
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue() as Profile;
    console.log(data);
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null && !this.hasDotCom(data.email)){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
 
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  hasDotCom(email) { 
    const atIndex = email.indexOf('.com');   
    if (atIndex !== -1) {
      const domain = email.slice(atIndex + 1);
      return domain.endsWith('.com');
    }
    const atIndex2= email.indexOf('.com.br');   
    if (atIndex2 !== -1) {
      const domain = email.slice(atIndex2 + 1);
      return domain.endsWith('.com.br');
    }     
    return false;
  }
}