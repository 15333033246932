import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concoc-ensino-medio2023-blumenau',
  templateUrl: './concoc-ensino-medio2023-blumenau.component.html',
  styleUrls: ['./concoc-ensino-medio2023-blumenau.component.scss']
})
export class ConcocEnsinoMedio2023BlumenauComponent implements OnInit {

  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService)
  { 
    this.landRoute          = "concoc-ensino-medio-2023-blumenau";
    this.urlRedirect        = "https://plataforma.cocblumenau.com.br/redirect";
    this.landRouteNavigate  = "./concoc-2023-confirmacao";
    }

    ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      cf_voce_e_aluno_de: new FormControl(''),
      alunode: new FormControl(''), 
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')
    
    });
    this.getLandClass();
  }
  getLandClass(){
    this.concocService.getLandRoute(this.landRoute)
    .subscribe(async res => {
        const response = res.body as ResponseApi; 
        if (!response.error) {
          this.landClasses = response.data;  
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-kelhwh7o")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {
    if(this.botaoEnviar){
      // TODO: Use EventEmitter with form value
      var data = this.profileForm.getRawValue(); 
      if(data.turmaqueentra == "CONCOC 2023 - 1ª série E.M."){
        this.pv = this.landClasses[0].token;
      }else if(data.turmaqueentra == "CONCOC 2023 - 2ª série E.M."){
        this.pv = this.landClasses[1].token;
      }else if(data.turmaqueentra == "CONCOC 2023 - 3ª série E.M."){
        this.pv = this.landClasses[2].token;
      }      
      var jsondata = {
        name : data.nome,
        email: data.email,  
        telefone: data.telefone,
        alunode :data.cf_voce_e_aluno_de,          
        colegio: data.turma, 
        turmaqueentra: data.turmaqueentra,
        urlRedirect: this.urlRedirect ,
        pv :this.pv
      } 
      this.concocService.registerSave(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
              let url:any = response.data; 
              //await localStorage.setItem('link',url.link);
              this.router.navigate( ['./concoc-2023-confirmacao'], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    if(data.nome != null && data.email != null  && data.telefone != null && data.turmaqueentra != null && data.captcha != null){
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }
  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    
    if(value === Number(response)){
      this.changeField();
    }
     
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}