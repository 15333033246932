import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}
@Component({
  selector: 'app-set2023-concoc-confirmacao-prova',
  templateUrl: './set2023-concoc-confirmacao-prova.component.html',
  styleUrls: ['./set2023-concoc-confirmacao-prova.component.scss']
})
export class Set2023ConcocConfirmacaoProvaComponent implements OnInit {
  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
  }

}
