import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcursoBolsasService } from '../concurso-bolsas.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-simulado-enem-online',
  templateUrl: './simulado-enem-online.component.html',
  styleUrls: ['./simulado-enem-online.component.scss']
})
export class SimuladoEnemOnlineComponent implements OnInit {

  profileForm: FormGroup;
  botaoEnviar: boolean;
  landClasses: any;
  constructor(  private route: ActivatedRoute,
                private router : Router,
                private  concursoBolsasService: ConcursoBolsasService
  ) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      colegio: new FormControl(''),
      alunode: new FormControl(''), 
      nomeResponsavel: new FormControl(''), 
      telefoneResponsavel: new FormControl(''), 
      turmaqueentra: new FormControl('')
    
    });
    this.getLandClass();
  }

  getLandClass(){
    this.concursoBolsasService.getLandRoute("simulado-enem-online")
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {

            this.landClasses = response.data; 
            console.info(this.landClasses); 
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-kelhwh7o")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    var data = this.profileForm.getRawValue();
    
      
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          telefone: data.telefone,
          alunode :data.alunode,
          telefoneResponsavel : data.telefoneResponsavel,
          nomeResponsavel : data.nomeResponsavel, 
          colegio: data.colegio, 
          urlRedirect: "https://sistema.notamaxima.com.br/redirect",
          pv : "336ade76085e5d84ff1c6f3d6176cc72"
        } 
        this.concursoBolsasService.register(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( ['/simulado-enem-online-confirmacao'], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a")); 
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

}
