import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';
import { GtmService } from 'src/app/gtm.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}

@Component({
  selector: 'app-concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em',
  templateUrl: './concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em.component.html',
  styleUrls: ['./concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em.component.scss']
})
export class ConcursoDeBolsasSantaClaraChallengeCandidoMotaEffEmComponent implements OnInit {

  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;

  constructor(private route: ActivatedRoute,
    private router : Router,
    private formBuilder: FormBuilder,
    private  concocService: ConcocService,
    private gtmService: GtmService)
  { 
    this.landRoute          = "concurso-de-bolsas-santa-clara-challenge-candido-mota-eff-em";
    this.urlRedirect        = "https://plataforma.notamaxima.com.br";
    this.landRouteNavigate  = "concurso-de-bolsas-santa-clara-challenge-agradecimento";
  }

  ngOnInit(): void {
    //this.gtmService.insertGtmScript('GTM-WCVBBKZ9');
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      nomeResponsavel: new FormControl(''),
      turmaqueentra: new FormControl(''),
      nascimento: new FormControl(''),
      nome_escola: new FormControl(''),
      escola_publica_ou_particular: new FormControl(''),
      captcha: new FormControl('')
    });
  }

  async onSubmit() {
    setTimeout(() => {
      if(this.botaoEnviar){
        this.router.navigate( ['concurso-de-bolsas-santa-clara-challenge-agradecimento']);

        var data = this.profileForm.getRawValue();
        if(data.turmaqueentra == "6° ano do Ensino Fundamental") this.pv = '72fcd1efd547a7184e112ee32d5889de';
        if(data.turmaqueentra == "7° ano do Ensino Fundamental") this.pv = 'c494a5d2e8541ae8bf6ee3bce970e618';
        if(data.turmaqueentra == "8° ano do Ensino Fundamental") this.pv = '26d177c7114fb08a0a18105ae42de78a';
        if(data.turmaqueentra == "9° ano do Ensino Fundamental") this.pv = 'f4f279b0ae4b6e37c9dbf5166a0f4196';

        if(data.turmaqueentra == "1ª série do Ensino Médio") this.pv = '037c3b93ddf89bc65bada5dddb8f9875';
        if(data.turmaqueentra == "2ª série do Ensino Médio") this.pv = '1f785466f059be3216a67695584f8353';
        if(data.turmaqueentra == "3ª série do Ensino Médio") this.pv = '165828b9b0b5e1f05d241682b33c5b33';

        data.senha = 'ALUNOSANTA@2024';
        data.turma = this.pv;

        var jsondata = {
          name : data.nome,
          email: data.email, 
          pass: 'ALUNOSANTA@2024',   
          telefone: data.telefone,
          turmaqueentra: this.pv,

          nameParent: data.nomeResponsavel,
          studentFrom1: data.nascimento,
          whereYouStudy: data.nome_escola,
          studentFrom: data.escola_publica_ou_particular, 

          urlRedirect: this.urlRedirect,
          pv: this.pv
        }
        this.concocService.registerSave(jsondata)
        .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
            let url:any = response.data; 
            this.router.navigate( ['concurso-de-bolsas-santa-clara-challenge-agradecimento'], {queryParams:{email:data.email, link : btoa(url.link) } })
          }
        }, err => console.log("Verifique sua conexão com a internet!"));
      }  
    }, 2000);
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    console.log(data);
    if(data.nome != null && data.email != null && data.telefone != null && data.captcha != null && !this.hasDotCom(data.email)){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    if(value === Number(response)){
      this.changeField();
    }
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  hasDotCom(email) { 
    const atIndex = email.indexOf('.com');   
    if (atIndex !== -1) {
      const domain = email.slice(atIndex + 1);
      return domain.endsWith('.com');
    }
    const atIndex2= email.indexOf('.com.br');   
    if (atIndex2 !== -1) {
      const domain = email.slice(atIndex2 + 1);
      return domain.endsWith('.com.br');
    }     
    return false;
  }
}