import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}

@Component({
  selector: 'app-simulado-acafe-agradecimento',
  templateUrl: './simulado-acafe-agradecimento.component.html',
  styleUrls: ['./simulado-acafe-agradecimento.component.scss']
})
export class SimuladoAcafeAgradecimentoComponent implements OnInit {
  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
   /*  this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      }); */
  }

}
