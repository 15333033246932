import { Injectable } from '@angular/core'; 
import { BehaviorSubject } from 'rxjs'; 
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'; 

@Injectable({
  providedIn: 'root'
})
export class ConcursoPrimeService {

 
  private API = environment.ApiUrl; 

  constructor(private http: HttpClient) {}

  register(json: any) {
     return this.http.post(this.API + '/pagamento/registerUser', json, {observe: 'response'});
  }
  
  getLandRoute(route:string) {
      return this.http.post(this.API + '/pagamento/landroute',{route}, {observe: 'response'});
  } 
}
