import { Injectable } from '@angular/core'; 
import { BehaviorSubject } from 'rxjs'; 
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'; 


@Injectable({
  providedIn: 'root'
})
export class QuimicaService {

  private API = environment.ApiUrl; 

  constructor(private http: HttpClient) {}

  register(json: any) {
     return this.http.post(this.API + '/pagamento/registerUserExpirePlan', json, {observe: 'response'});
  }
   
}
