<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Participe do CONCOC e tenha a chance de ganhar até 100% de bolsas!">
    

    
      <meta property="og:title" content="CONCOC Rio do Sul - Ensino Médio 2023" />
      <meta name="twitter:title" content="CONCOC Rio do Sul - Ensino Médio 2023" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    
      <meta property="og:description" content="Participe do CONCOC e tenha a chance de ganhar até 100% de bolsas!" />
      <meta name="twitter:description" content="Participe do CONCOC e tenha a chance de ganhar até 100% de bolsas!" />
    

    
      <link rel="canonical" href="https://conteudos.coconline.com.br/concoc-ensino-medio-2023-rio-do-sul" />
    

    <title>CONCOC Rio do Sul - Ensino Médio 2023</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
      #flags .height-30{
        height: 31px !important;
        line-height: 40px !important;
    }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
    #flags .height-30{
        height: 31px !important;
        line-height: 40px !important;
    }
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '805460306613276');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=805460306613276&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kppthk7k {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$fhwpwuxx0pb), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kppthk7k > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-kppthk7k" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kppygs21 {
      min-height: 680px;
    }

    #rd-row-kppygs21 .bricks--column > div {
      min-height: 680px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kppygs21.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kppygs21" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45w82ct {
      flex: 6 0 0;
    }

    #rd-column-l45w82ct > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45w82ct > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l45w82ct" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l45zngm5 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 439px;
    }

    #rd-image-l45zngm5 a,
    #rd-image-l45zngm5 img {
      width: 439px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l45zngm5 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l45zngm5" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$fdzt7pytw8o" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kylxv2au {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 0px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kylxv2au" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><span style="font-family: 'Source Sans Pro', sans-serif; font-size: 20px; color: rgb(255, 255, 255);">Preencha o formulário, participe do CONCOC e tenha a chance de ganhar até 100% de desconto.</span></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-image-l74zyiob {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 250px;
    }

    #rd-image-l74zyiob a,
    #rd-image-l74zyiob img {
      width: 250px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l74zyiob {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l74zyiob" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661207431/$3sq2kyi9zzu" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-l74zzmgs {
      margin-top: 37px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 259px;
    }

    #rd-image-l74zzmgs a,
    #rd-image-l74zzmgs img {
      width: 259px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l74zzmgs {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l74zzmgs" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661207431/$ladwny7zhh" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-l7537rzt {
      display: flex;
      flex-direction: column;
    }

    #rd-button-l7537rzt {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(126,211,33,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 4px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 30px;
      max-width: 201px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-l7537rzt {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://docs.google.com/document/d/1aXjBdQvEfo8z16iSD3glUvG_hnWDzjiv0KCbbZIJD6U/edit?usp=sharing"
    id="rd-button-l7537rzt"
    target="_blank"
    title="Any Title"
  >
    Clique e confira o edital
  </a>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kppygs22 {
      flex: 6 0 0;
    }

    #rd-column-kppygs22 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kppygs22 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-kppygs22" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-joq3m2m2 {
      
          background-color: rgba(126,211,33,1);
        
      border-bottom-style: none;
      border-color: #4b5677;
      border-left-style: none;
      border-radius: 6px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      min-height: auto0px;
      margin-top: 48px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-joq3m2m2" class="bricks--component bricks--component-box rd-box">
  

<style type="text/css" rel="stylesheet">
  
    #rd-image-l4d6786y {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 238px;
    }

    #rd-image-l4d6786y a,
    #rd-image-l4d6786y img {
      width: 238px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l4d6786y {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l4d6786y" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661208911/$qdtao15yhib" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m3 {
      display: block;
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 360px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-joq3m2m3" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 30px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">Preencha o formulário</strong></h2>
</div>






<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #417505;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #417505;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #417505;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      text-decoration: none;
    }

    #rd-form-joq3m2m5 label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #ffffff;
      border-radius: 13px;
      border-width: 1px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        ;
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  

  
</style>


<div
  id="rd-form-joq3m2m5"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup]="profileForm"
    (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    action="https://gyruss.rdops.systems/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb250ZXVkb3MuY29jb25saW5lLmNvbS5ici9zZXQtMjAyMi1j
b25jb2MtY29uZmlybWFjYW8=
"
  >

    <input type="hidden" name="token_rdstation" value="fc4e496744bb9cfcf9fd52eb4269d348">
    <input type="hidden" name="conversion_identifier" value="concoc-ensino-medio-2023-rio-do-sul">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    


    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
>
  <div class="bricks-form__field">
  <label for="rd-text_field-l75di163" class="bricks-form__label">
    Nome*
  </label>
  <input
    id="rd-text_field-l75di163"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
    (change)= "changeField()"
    formControlName="nome">
</div>
<div class="bricks-form__field">
  <label for="rd-email_field-l75di164" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-l75di164"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    placeholder="Email *"
    required="required"
    (change)= "changeField()"
    formControlName="email">
</div>
<div class="bricks-form__field with-select-flags">
  <label for="rd-phone_field-l75di165" class="bricks-form__label">
    Celular*
  </label>
  <div class="phone-input-group" id="flags">
    
      <div class="form-control phone-country height-30" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-l75di165"
      class="bricks-form__input required phone js-phone js-field-mobile_phone"
      name="mobile_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      onclick="document.getElementById('rd-radio_buttons_field-l75di166_Escola_Pública').checked = true;"
      placeholder="Celular *"
      required="required"
      (change)= "changeField()"
      formControlName="telefone">
  </div>
</div>
<div class="bricks-form__field">
  <label class="bricks-form__label always-visible">
    Você é aluno de:
  </label>
  
    <div class="bricks-form__field__option">
      <label class="bricks-form__label">
        <input
          id="rd-radio_buttons_field-l75di166_Escola_Pública"
          name="cf_voce_e_aluno_de_coc"
          type="radio"
          value="Escola Pública" formControlName="cf_voce_e_aluno_de_coc" (change)= "changeField()"
          class="bricks-form__field__option__input  js-field-cf_voce_e_aluno_de_coc"
          data-use-type="STRING"
          checked="true">
        Escola Pública
      </label>
    </div>
  
    <div class="bricks-form__field__option">
      <label class="bricks-form__label">
        <input
          id="rd-radio_buttons_field-l75di166_Escola_Particular"
          name="cf_voce_e_aluno_de_coc"
          type="radio"
          value="Escola Particular" formControlName="cf_voce_e_aluno_de_coc" (change)= "changeField()"
          class="bricks-form__field__option__input  js-field-cf_voce_e_aluno_de_coc"
          data-use-type="STRING">
        Escola Particular
      </label>
    </div>
  
    <div class="bricks-form__field__option">
      <label class="bricks-form__label">
        <input
          id="rd-radio_buttons_field-l75di166_Aluno_COC"
          name="cf_voce_e_aluno_de_coc"
          type="radio"
          value="Aluno COC" formControlName="cf_voce_e_aluno_de_coc" (change)= "changeField()"
          class="bricks-form__field__option__input  js-field-cf_voce_e_aluno_de_coc"
          data-use-type="STRING">
        Aluno COC
      </label>
    </div>
  
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-l75di167" class="bricks-form__label">
    Escola onde estuda atualmente*
  </label>
  <input
    id="rd-text_field-l75di167"
    class="bricks-form__input required js-text js-field-cf_escola_onde_estuda_atualmente_medio"
    name="cf_escola_onde_estuda_atualmente_medio"
    type="text"
    data-use-type="STRING"
    placeholder="Escola onde estuda atualmente *"
    required="required"
    (change)= "changeField()"
    formControlName="turma">
</div>
<div class="bricks-form__field">
  <label for="rd-select_field-l75di168" class="bricks-form__label">
    Série que cursará em 2023?*
  </label>

  
  
  <select
    id="rd-select_field-l75di168"
    class="bricks-form__input required js-field-cf_serie_que_cursara_em_2023_medio"
    data-use-type="STRING"
    name="cf_serie_que_cursara_em_2023_medio"
    required="required"
    (change)= "changeField()"
    formControlName="turmaqueentra">
    <option value="">
      
      Selecione
    </option>
    
      <option value="CONCOC 2023 - 1ª série E.M.">CONCOC 2023 - 1ª série E.M.</option>
    
      <option value="CONCOC 2023 - 2ª série E.M.">CONCOC 2023 - 2ª série E.M.</option>
    
      <option value="CONCOC 2023 - 3ª série E.M.">CONCOC 2023 - 3ª série E.M.</option>
    
  </select>
</div>  


<div class="bricks-form__field " >
  
  <div class="bricks-form__field__option">
    <label class="bricks-form__label">
      <input
        id="rd-checkbox_field-l75di169"
        name="cf_li_e_aceito_edital_do_concurso_de_bolsas"
        type="checkbox"
        value="1"
        data-use-type="STRING"
        data-always-visible=""
        data-legal-base-type=""
        class="bricks-form__field__option__input required js-field-cf_li_e_aceito_edital_do_concurso_de_bolsas"
        required="required"
        (change)= "changeField()">
      Li e concordo com o edital do concurso de bolsas*
    </label>
  </div>
</div>
<div class="bricks-form__field">
  <label for="captcha" class="bricks-form__label">
    <span #mathexpression  id="math_expression">1 + 1 = ?</span>
  </label>
  <input
    id="captcha"
    #captcha
    class="bricks-form__input math rd-bricks-captcha"
    name="captcha"
    (keyup)="checkCaptcha(mathexpression.innerHTML , captcha.value);"
    type="text"
    placeholder="1 + 1 = ?"
    formControlName="captcha">
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2m7 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2m7 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(65,117,5,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 280px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2m7 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>

  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7">
      Fazer a minha inscrição!
    </button>
  </div>

  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7"
      (click)= "avisoCampos()">
      Fazer a minha inscrição!
    </button>
  </div>


    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>

  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1661224443"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1661224443"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1661224443"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/concoc-ensino-medio-2023-rio-do-sul/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/concoc-ensino-medio-2023-rio-do-sul/conversao'); } catch(err) { }
        }
      

      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l45wanhf {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l45wanhf > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l45wanhf" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l462bc57 {
      min-height: 50px;
    }

    #rd-row-l462bc57 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l462bc57.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l462bc57" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l462bc58 {
      flex: 12 0 0;
    }

    #rd-column-l462bc58 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l462bc58 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l462bc58" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l462bdqz {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l462bdqz" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l4crc8bu {
      min-height: 50px;
    }

    #rd-row-l4crc8bu .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l4crc8bu.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l4crc8bu" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l4crc8bv {
      flex: 12 0 0;
    }

    #rd-column-l4crc8bv > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l4crc8bv > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l4crc8bv" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l4crcabk {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-60px;
      max-width: 761px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-l4crcabk.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-l4crcabk {
          display: none;
        }

        #rd-text-l4crcabk.mobile {
          display: block;
          max-width: 400px;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    
  
</style>

<div id="rd-text-l4crcabk" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center; line-height: 1.25;"><strong style="background-color: transparent; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5); font-size: 48px;">Tradição que se renova no presente!</strong></h1>
</div>


  <div id="rd-text-l4crcabk" class="bricks--component bricks--component-text mobile rd-text">
    <h1 style="text-align: center; line-height: 1.25;"><strong style="background-color: transparent; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5); font-size: 48px;">Tradição que se renova no presente!</strong></h1><p style="text-align: center; line-height: 1.25;"><br></p>
  </div>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l45wanhj {
      min-height: 357px;
    }

    #rd-row-l45wanhj .bricks--column > div {
      min-height: 357px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l45wanhj.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l45wanhj" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45wanhk {
      flex: 6 0 0;
    }

    #rd-column-l45wanhk > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45wanhk > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l45wanhk" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-l7516nia {
      display: flex;
      flex-direction: column;
    }

    #rd-button-l7516nia {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(65,117,5,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 4px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: bold;
      height: 61px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 277px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-l7516nia {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="btnClick('rd-image-l4d6786y')"
    id="rd-button-l7516nia"
    target="_self"
    title="Fazer a minha inscrição!"
  >
    Fazer a minha inscrição!
  </a>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l74vr65m {
      flex: 6 0 0;
    }

    #rd-column-l74vr65m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74vr65m > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74vr65m" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-video-l7510vf6 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 500px;
      max-width: 100%;
    }

    #rd-video-l7510vf6 iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  

  
</style>

<div id="rd-video-l7510vf6" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/xG59SLycjRM?&rel=0&autoplay=0&mute=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l462b57y {
      min-height: 57px;
    }

    #rd-row-l462b57y .bricks--column > div {
      min-height: 57px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l462b57y.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l462b57y" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l462b57z {
      flex: 12 0 0;
    }

    #rd-column-l462b57z > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l462b57z > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l462b57z" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l74w7h2q {
      
          background-color: rgba(65,117,5,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l74w7h2q > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l74w7h2q" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l74w7h2r {
      min-height: 50px;
    }

    #rd-row-l74w7h2r .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l74w7h2r.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l74w7h2r" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l74w7h2s {
      flex: 4 0 0;
    }

    #rd-column-l74w7h2s > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74w7h2s > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74w7h2s" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l460ciat {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 190px;
    }

    #rd-image-l460ciat a,
    #rd-image-l460ciat img {
      width: 190px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l460ciat {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l460ciat" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$m1g0bkt84ir" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l74w88yw {
      flex: 8 0 0;
    }

    #rd-column-l74w88yw > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74w88yw > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74w88yw" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l74ywvpa {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-46px;
      max-width: 430px;
      margin-top: 72px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l74ywvpa" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 24px; font-family: 'Source Sans Pro', sans-serif;">A premiação do Ensino Médio corresponde a cada série (1ª, 2ª e 3ª).</span></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-image-l74w8chk {
      margin-top: -152px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 418px;
    }

    #rd-image-l74w8chk a,
    #rd-image-l74w8chk img {
      width: 418px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l74w8chk {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l74w8chk" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$t9xbhrcll7n" alt="">

  
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l74vy63n {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l74vy63n > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l74vy63n" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l74vy63o {
      min-height: 50px;
    }

    #rd-row-l74vy63o .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l74vy63o.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l74vy63o" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l74vyebl {
      flex: 4 0 0;
    }

    #rd-column-l74vyebl > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74vyebl > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74vyebl" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l74vz3ax {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-382px;
      max-width: 405px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l74vz3ax" class="bricks--component bricks--component-text rd-text">
  <p><br></p><p><br></p><p style="text-align: center; line-height: 1;"><strong style="font-size: 24px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">﻿</strong><strong style="font-size: 25px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">Alunos de </strong></p><p style="text-align: center; line-height: 1;"><strong style="font-size: 25px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">Escolas Públicas</strong></p><p style="text-align: center;"><br></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">1◦ Lugar – Bolsa de Estudos de 100%</span></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">2◦ Lugar – Bolsa de Estudos de 60%</span></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">3◦ Lugar – Bolsa de Estudos de 50%</span></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">4◦ Lugar – Bolsa de Estudos de 40%</span></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">5◦ Lugar – Bolsa de Estudos de 30%</span></p>
</div>



  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l74vy63p {
      flex: 4 0 0;
    }

    #rd-column-l74vy63p > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74vy63p > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74vy63p" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l75coal5 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-382px;
      max-width: 405px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l75coal5" class="bricks--component bricks--component-text rd-text">
  <p><br></p><p><br></p><p style="line-height: 1; text-align: center;"><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 24px;">﻿</strong><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 25px;">Alunos de </strong></p><p style="line-height: 1; text-align: center;"><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 25px;">Escolas Particulares</strong></p><p style="text-align: center;"><br></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">1◦ Lugar – Bolsa de Estudos de 100%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">2◦ Lugar – Bolsa de Estudos de 60%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">3◦ Lugar – Bolsa de Estudos de 50%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">4◦ Lugar – Bolsa de Estudos de 40%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">5◦ Lugar – Bolsa de Estudos de 30%</span></p>
</div>



  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l75cnhde {
      flex: 4 0 0;
    }

    #rd-column-l75cnhde > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l75cnhde > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l75cnhde" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l75cor4p {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-382px;
      max-width: 405px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l75cor4p" class="bricks--component bricks--component-text rd-text">
  <p><br></p><p><br></p><p style="line-height: 1; text-align: center;"><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 24px;">﻿</strong><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 25px;">Alunos </strong></p><p style="line-height: 1; text-align: center;"><strong style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 25px;">COC Rio do Sul</strong></p><p style="text-align: center;"><br></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">1◦ Lugar – Bolsa de Estudos de 100%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">2◦ Lugar – Bolsa de Estudos de 60%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">3◦ Lugar – Bolsa de Estudos de 50%</span></p><p><span style="color: rgb(65, 117, 5); font-family: 'Source Sans Pro', sans-serif; font-size: 18px;">4◦ Lugar – Bolsa de Estudos de 40%</span></p><p><span style="font-size: 18px; font-family: 'Source Sans Pro', sans-serif; color: rgb(65, 117, 5);">5◦ Lugar – Bolsa de Estudos de 30%</span></p>
</div>



  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l751ky8k {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l751ky8k > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l751ky8k" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l751ky8l {
      min-height: 50px;
    }

    #rd-row-l751ky8l .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l751ky8l.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l751ky8l" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l751ky8m {
      flex: 12 0 0;
    }

    #rd-column-l751ky8m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l751ky8m > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l751ky8m" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l74vr157 {
      
          background-color: rgba(65,117,5,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l74vr157 > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l74vr157" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l74vr158 {
      min-height: 189px;
    }

    #rd-row-l74vr158 .bricks--column > div {
      min-height: 189px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l74vr158.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l74vr158" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l74z2ysl {
      flex: 3 0 0;
    }

    #rd-column-l74z2ysl > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74z2ysl > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74z2ysl" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l74z32yd {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 126px;
    }

    #rd-image-l74z32yd a,
    #rd-image-l74z32yd img {
      width: 126px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l74z32yd {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l74z32yd" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$7jjkqjh2jp8" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l74vr159 {
      flex: 9 0 0;
    }

    #rd-column-l74vr159 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l74vr159 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l74vr159" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l74w2o55 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 672px;
      margin-top: 4px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l74w2o55" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center; line-height: 1;"><strong style="font-size: 48px; background-color: transparent; font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255);">Atenção!</strong></h1><p style="text-align: center; line-height: 1;"><br></p><p style="text-align: center;"><span style="font-size: 22px; background-color: transparent; color: rgb(255, 255, 255); font-family: 'Source Sans Pro', sans-serif;">﻿</span><span style="font-size: 20px; color: rgb(255, 255, 255); font-family: 'Source Sans Pro', sans-serif;">Verifique o seu e-mail constantemente. Todas as informações importantes referentes ao CONCOC serão enviadas para lá.</span></p><p style="text-align: center; line-height: 1;"><br></p>
</div>



  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l45w9hah {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l45w9hah > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l45w9hah" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l462lwm3 {
      min-height: 50px;
    }

    #rd-row-l462lwm3 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l462lwm3.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l462lwm3" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l462lwm4 {
      flex: 12 0 0;
    }

    #rd-column-l462lwm4 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l462lwm4 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l462lwm4" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l462lzor {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l462lzor" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l45w9hai {
      min-height: 50px;
    }

    #rd-row-l45w9hai .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l45w9hai.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l45w9hai" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45w9haj {
      flex: 12 0 0;
    }

    #rd-column-l45w9haj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45w9haj > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l45w9haj" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l45w9hak {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-23px;
      max-width: 920px;
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l45w9hak" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-family: 'Source Sans Pro', sans-serif; background-color: transparent; color: rgb(65, 117, 5); font-size: 48px;">Entenda como funciona o CONCOC</strong></h1><p style="text-align: center; line-height: 1.25;"><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l45w9hal {
      min-height: 331px;
    }

    #rd-row-l45w9hal .bricks--column > div {
      min-height: 331px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l45w9hal.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l45w9hal" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45w9har {
      flex: 4 0 0;
    }

    #rd-column-l45w9har > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45w9har > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l45w9har" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-l45w9has {
      
          background-color: rgba(126,211,33,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 36px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: auto0px;
      margin-top: -1px;
      margin-bottom: 10px;
      max-width: 231px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-l45w9has" class="bricks--component bricks--component-box rd-box">
  

<style type="text/css" rel="stylesheet">
  
    #rd-image-l45w9hat {
      margin-top: -6px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 100px;
    }

    #rd-image-l45w9hat a,
    #rd-image-l45w9hat img {
      width: 100px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l45w9hat {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l45w9hat" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$p40k4upxdy" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-l45w9hau {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-41px;
      max-width: 289px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l45w9hau" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 36px; font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255);">Cadastre-se</strong></h2>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-l45w9hav {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 274px;
      margin-top: 23px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l45w9hav" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 20px; font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255);">Após o cadastro, você receberá um e-mail com os dados de acesso à plataforma.</span></p><p style="text-align: justify;"><strong>&nbsp;</strong></p><p style="text-align: center;"><br></p>
</div>



</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l752xzav {
      flex: 4 0 0;
    }

    #rd-column-l752xzav > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l752xzav > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l752xzav" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-l752xzaw {
      
          background-color: rgba(126,211,33,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 36px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: auto0px;
      margin-top: -1px;
      margin-bottom: 10px;
      max-width: 231px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-l752xzaw" class="bricks--component bricks--component-box rd-box">
  

<style type="text/css" rel="stylesheet">
  
    #rd-image-l752xzax {
      margin-top: -6px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 100px;
    }

    #rd-image-l752xzax a,
    #rd-image-l752xzax img {
      width: 100px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l752xzax {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l752xzax" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$7h5htm1pg3j" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-l752xzay {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-41px;
      max-width: 289px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l752xzay" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 36px; font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255);">Prepare-se</strong></h2>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-l752xzaz {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 274px;
      margin-top: 23px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l752xzaz" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255); font-size: 20px;">Com os dados, você terá acesso às aulas gravadas para estudar e se preparar para a prova presencial.</span></p><p style="text-align: center;"><br></p>
</div>



</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l752x65t {
      flex: 4 0 0;
    }

    #rd-column-l752x65t > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l752x65t > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l752x65t" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-l752x65u {
      
          background-color: rgba(126,211,33,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 36px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: auto0px;
      margin-top: -1px;
      margin-bottom: 10px;
      max-width: 231px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-l752x65u" class="bricks--component bricks--component-box rd-box">
  

<style type="text/css" rel="stylesheet">
  
    #rd-image-l752x65v {
      margin-top: -6px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 100px;
    }

    #rd-image-l752x65v a,
    #rd-image-l752x65v img {
      width: 100px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l752x65v {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l752x65v" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$6puij0ty6jo" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-l752x65w {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-41px;
      max-width: 289px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l752x65w" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 36px; font-family: 'Source Sans Pro', sans-serif; color: rgb(255, 255, 255);">Faça a prova</strong></h2>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-l752x65x {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 274px;
      margin-top: 23px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l752x65x" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: 'Source Sans Pro', sans-serif; font-size: 20px;">A prova será presencial, no dia 24/09, após um aulão de revisão com início às 14h. </span></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: 'Source Sans Pro', sans-serif; font-size: 20px;">Confira o local da prova no edital.</span></p>
</div>



</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l45w9hb1 {
      min-height: 50px;
    }

    #rd-row-l45w9hb1 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l45w9hb1.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l45w9hb1" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l45w9hb2 {
      flex: 12 0 0;
    }

    #rd-column-l45w9hb2 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l45w9hb2 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l45w9hb2" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l45w9hb3 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l45w9hb3" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-l46367mc {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1661179545/$wascbi22q9), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 100%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-l46367mc > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-l46367mc" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-l46367md {
      min-height: 50px;
    }

    #rd-row-l46367md .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l46367md.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l46367md" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l46367me {
      flex: 12 0 0;
    }

    #rd-column-l46367me > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l46367me > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l46367me" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l46367mf {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l46367mf" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l46367mg {
      min-height: 50px;
    }

    #rd-row-l46367mg .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l46367mg.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l46367mg" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l46367mh {
      flex: 8 0 0;
    }

    #rd-column-l46367mh > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l46367mh > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l46367mh" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l46367mi {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-40px;
      max-width: 555px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-l46367mi.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-l46367mi {
          display: none;
        }

        #rd-text-l46367mi.mobile {
          display: block;
          max-width: 555px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    
  
</style>

<div id="rd-text-l46367mi" class="bricks--component bricks--component-text rd-text">
  <h2><strong style="font-family: Lato, sans-serif; font-size: 36px; color: rgb(255, 255, 255);">Acesse o edital completo para ter acesso a todas as informações!</strong></h2>
</div>


  <div id="rd-text-l46367mi" class="bricks--component bricks--component-text mobile rd-text">
    <h2 style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 36px; font-family: Lato, sans-serif;">Conheça cada profissão de forma prática e descubra o seu propósito!</strong></h2>
  </div>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-l46367mj {
      flex: 4 0 0;
    }

    #rd-column-l46367mj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l46367mj > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l46367mj" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-l46367mk {
      display: flex;
      flex-direction: column;
    }

    #rd-button-l46367mk {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(126,211,33,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 4px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 238px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-l46367mk {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://docs.google.com/document/d/1aXjBdQvEfo8z16iSD3glUvG_hnWDzjiv0KCbbZIJD6U/edit?usp=sharing"
    id="rd-button-l46367mk"
    target="_blank"
    title="Any Title"
  >
    Clique e acesse o edital
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l46367ml {
      min-height: 50px;
    }

    #rd-row-l46367ml .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l46367ml.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l46367ml" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l46367mm {
      flex: 12 0 0;
    }

    #rd-column-l46367mm > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l46367mm > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l46367mm" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-l46367mn {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-l46367mn" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-l75d59b8 {
      min-height: 91px;
    }

    #rd-row-l75d59b8 .bricks--column > div {
      min-height: 91px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-l75d59b8.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-l75d59b8" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-l75d59b9 {
      flex: 12 0 0;
    }

    #rd-column-l75d59b9 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-l75d59b9 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-l75d59b9" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-l75d5c43 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-l75d5c43 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(126,211,33,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 4px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 315px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-l75d5c43 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="btnClick('rd-image-l4d6786y')"
    id="rd-button-l75d5c43"
    target="_self"
    title="Fazer a minha inscrição!"
  >
    Fazer a minha inscrição!
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2na {
      
          background-color: #255600;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2na > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2na" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2nb {
      min-height: 50px;
    }

    #rd-row-joq3m2nb .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2nb.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2nb" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2nc {
      flex: 12 0 0;
    }

    #rd-column-joq3m2nc > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2nc > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-joq3m2nc" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l4d5pzl1 {
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 183px;
    }

    #rd-image-l4d5pzl1 a,
    #rd-image-l4d5pzl1 img {
      width: 183px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l4d5pzl1 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l4d5pzl1" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1661208911/$e8y7msriala" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d24ecbab-6dfd-4592-b8fd-59cfba8ebcfb-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="3383476" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-137261937-5';
    var domain = 'coconline.com.br';

    function initializeBricksGA() {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', uuid, 'auto');
      ga('send', 'pageview');

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);

      if (idsDataset.experimentContentId) {
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    window.addEventListener('load', function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    })
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      /* FONTE DO TÍTULO*/
@font-face {
    font-family: 'trashhandregular';
    src: local('TrashHand'), url('https://aletech.com.br/trashhand.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#rd-text-kppt77vn h1 span{
    font-family: 'trashhandregular'!important;
    font-size: 79px!important;
    margin-bottom: 12px;
    background: -webkit-linear-gradient(#5548FF, #0A7EFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/*FIM DA FONTE DO TÍTULO*/

/*BOX DA PRIMEIRA SEÇÃO E DA ÚLTIMA SEÇÃO*/

#rd-box-l45zpsgd, #rd-box-l470thna, #rd-box-l470tpm1, #rd-box-l470trgj{
    overflow:hidden;
    border-radius: 15px;
}

/*FIM DO BOX DA PRIMEIRA SEÇÃO*/
#rd-section-l46zb1jt{
    background: linear-gradient(#64c500, #509600);
}

@media (max-width: 600px)
{
  #rd-section-l460eoho, #rd-section-l46367mc{
        background-position:center center;
    }
}

#rd-row-kylxpp37 .rd-box:hover img{
    transform: rotate(720deg) scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover{
    transform: scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box{
    box-shadow: 20px 20px 20px #333;
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover span, #rd-row-kylxpp37 .rd-box:hover strong{
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box, #rd-row-kylxpp37 .rd-box img, #rd-row-kylxpp37 .rd-box span, #rd-row-kylxpp37 .rd-box strong{
    transition: 0.5s all;
}
    </style>
    

  </body>
</html>