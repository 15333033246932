import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcursoBolsasService } from '../concurso-bolsas.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 


export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concurso-medio-visao2021-home',
  templateUrl: './concurso-medio-visao2021-home.component.html',
  styleUrls: ['./concurso-medio-visao2021-home.component.scss']
})
export class ConcursoMedioVisao2021HomeComponent implements OnInit {

  profileForm: FormGroup;
  botaoEnviar: boolean = false;
  landClasses: any;
  buttonActive: boolean  = false;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concursoBolsasService: ConcursoBolsasService)
  { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      colegio: new FormControl(''),
      alunode: new FormControl(''), 
      unidadequevaiestudar: new FormControl(''), 
      nomeResponsavel: new FormControl(''), 
      telefoneResponsavel: new FormControl(''), 
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')
    
    });
    this.getLandClass();
  }
  getLandClass(){
    this.concursoBolsasService.getLandRoute("concurso-medio-visao-2021")
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {

            this.landClasses = response.data; 
            console.info(this.landClasses); 
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-kelhwh7o")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {

    if(this.botaoEnviar){ 
     
        // TODO: Use EventEmitter with form value
        var data = this.profileForm.getRawValue(); 
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          telefone: data.telefone,
          alunode :data.alunode,
          telefoneResponsavel : data.telefoneResponsavel,
          nomeResponsavel : data.nomeResponsavel, 
          unidadequevaiestudar : data.unidadequevaiestudar,
          colegio: data.colegio, 
          urlRedirect: "https://visao.notamaxima.com.br/redirect",
          pv :data.turma
        } 
        this.concursoBolsasService.register(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( ['/concurso-medio-visao-2021-confirmacao'], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    console.info(data);
    if(data.nome != null && data.email != null && 
      data.senha != null && data.telefone != null && 
      data.colegio != null && data.alunode != null  &&
      data.turma != null &&    data.turma != "Selecione Uma Turma" 
      && data.unidadequevaiestudar != null && data.captcha != null){ 
      this.buttonActive = true;
      this.botaoEnviar = true;
      
      }
  }


}
