
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Garanta até 100% de desconto no Preparatório ENEM do COC Rio do Sul, o cursinho que mais aprova em Santa Catarina!">
    

    
      <meta property="og:title" content="CONCOC Preparatório ENEM - COC Rio do Sul" />
      <meta name="twitter:title" content="CONCOC Preparatório ENEM - COC Rio do Sul" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.coconline.com.br/concoc-enem-rio-do-sul" />
    

    <title>CONCOC Preparatório ENEM - COC Rio do Sul</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <script type="text/javascript"> </script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kc1suo7j {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1616442361/$dl8ffww8zwt), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kc1suo7j > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kc1suo7j" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kc1suo7k {
      min-height: 155px;
    }

    #rd-row-kc1suo7k .bricks--column > div {
      min-height: 155px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kc1suo7k.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kc1suo7k" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kc1suo7l {
      flex: 12 0 0;
    }

    #rd-column-kc1suo7l > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kc1suo7l > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kc1suo7l" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-joq3m2lu {
      margin-top: 30px;
      margin-bottom: 30px;
      align-self: center;
      max-width: 154px;
    }

    #rd-image-joq3m2lu a,
    #rd-image-joq3m2lu img {
      width: 154px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-joq3m2lu {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-joq3m2lu" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1616442361/$etx24slf75l" alt="">

  
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2lv {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #BBBBBB;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
    }

    #rd-section-joq3m2lv > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2lv" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2lw {
      min-height: 50px;
    }

    #rd-row-joq3m2lw .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2lw.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2lw" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2lx {
      flex: 6 0 0;
    }

    #rd-column-joq3m2lx > div {
      
            background-image: url(), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: initial;
            background-attachment: scroll;
          
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2lx > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2lx" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-kebff52m {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 200px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-kebff52m" class="bricks--component bricks--component-box rd-box">
  
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-keago4yh {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 251px;
    }

    #rd-image-keago4yh a,
    #rd-image-keago4yh img {
      width: 251px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-keago4yh {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-keago4yh" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1616446473/$730xbjhpiv" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kedap4fm {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 460px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kedap4fm" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 0, 0); font-size: 18px;">Concurso de Bolsas Preparatório ENEM 2021 - Prova on-line</strong></h4>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2ly {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 460px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2ly" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">Participe do CONCOC: o Concurso de Bolsas do COC Rio do Sul que dará bolsas de até 100% para você se matricular no melhor curso preparatório para o ENEM de Santa Catarina!</span></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2lz {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2lz" class="bricks--component bricks--component-text rd-text">
  <h4 style="line-height: 1.25; text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 0, 0); font-size: 14px;">Inscrições até dia 09 de abril de 2021</strong></h4>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-image-keah8qif {
      margin-top: 25px;
      margin-bottom: 25px;
      align-self: center;
      max-width: 393px;
    }

    #rd-image-keah8qif a,
    #rd-image-keah8qif img {
      width: 393px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-keah8qif {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-keah8qif" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1616444834/$2y74dfj6no4" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2m1 {
      flex: 6 0 0;
    }

    #rd-column-joq3m2m1 > div {
      
            background-image: url(), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: initial;
            background-attachment: scroll;
          
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2m1 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2m1" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-joq3m2m2 {
      
          background-color: rgba(69,149,158,1);
        
      border-bottom-style: solid;
      border-color: #4b5677;
      border-left-style: solid;
      border-radius: 6px;
      border-right-style: solid;
      border-top-style: solid;
      border-width: 1px;
      padding-top: 25px;
      padding-right: 30px;
      padding-bottom: 25px;
      padding-left: 30px;
      min-height: auto0px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 460px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-joq3m2m2" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m3 {
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 358px;
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2m3" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 18px;">Preencha o formulário abaixo, participe do CONCOC e tenha a chance de ganhar</strong></h2>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ked4yp8x {
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 358px;
      margin-top: 5px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-ked4yp8x" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="color: rgb(255, 255, 0); font-family: Open Sans, sans-serif; font-size: 22px; background-color: transparent;"> BOLSAS DE ATÉ 100%!</strong></h2>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #FFFFFF;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #FFFFFF;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #FFFFFF;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #a73131;
      border-radius: 4px;
      border-width: 0px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  
</style>


<div
  id="rd-form-joq3m2m5"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form"
    [formGroup] = "profileForm"
    (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    action="https://www.rdstation.com.br/api/1.2/conversions"
    data-typed-fields=""
    data-lang="pt-BR"
  >

    <input type="hidden" name="token_rdstation" value="fc4e496744bb9cfcf9fd52eb4269d348">
    <input type="hidden" name="identificador" value="concoc-enem-rio-do-sul">
    <input type="hidden" name="_is" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="redirect_to" value="https://conteudos.coconline.com.br/concoc-enem-rio-do-sul-confirmacao"/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    


    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
>
  <div class="bricks-form__field">
  <label for="rd-text_field-kml0e3u4" class="bricks-form__label">
    Nome Completo*
  </label>
  <input
    id="rd-text_field-kml0e3u4"
    (change)= "changeField()"
    formControlName="nome"
    class="bricks-form__input required js-text js-field-cf_nome_completo"
    name="custom_fields[863337]"
    type="text"
    data-use-type="STRING"
    placeholder="Nome Completo *"
    required="required"
  >
</div>
<div class="bricks-form__field">
  <label for="rd-email_field-kml0e3u5" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-kml0e3u5"
    (change)= "changeField()"
    formControlName="email"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    placeholder="Email *"
    required="required"
  >
</div>
<div class="bricks-form__field">
  <label for="rd-senha_field-kcng8yh5" class="bricks-form__label">
    Crie a sua senha*
  </label>
  <input
    (change)= "changeField()"
    formControlName="senha"
    id="rd-senha_field-kcng8yh5"
    class="bricks-form__input required js-senha js-field-senha"
    name="senha"
    type="senha"
    data-use-type="STRING"
    placeholder="Senha *"
    required="required"
  >
</div> 
<div class="bricks-form__field with-select-flags">
  <label for="rd-phone_field-kml0e3u6" class="bricks-form__label">
    Telefone*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-kml0e3u6"
      (change)= "changeField()"
      formControlName="telefone"
      class="bricks-form__input required phone js-phone js-field-personal_phone"
      name="personal_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Telefone *"
      required="required"
    >
  </div>
</div>
<div class="bricks-form__field">
  <label for="rd-select_field-kml0e3u7" class="bricks-form__label">
    Você é aluno de*
  </label>
  <select
    id="rd-select_field-kml0e3u7"
    (change)= "changeField()" 
    formControlName="alunode"
    class="bricks-form__input required js-field-cf_categoria_concoc"
    data-use-type="STRING"
    name="custom_fields[1128267]"
    required="required"
  >
    <option value="">
      Selecione
    </option>
    
      <option value="Escola Pública">Escola Pública</option>
    
      <option value="Escola Privada">Escola Privada</option>
    
      <option value="Aluno COC">Aluno COC</option>
    
  </select>
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-kml0e3u8" class="bricks-form__label">
    Indique um amigo para participar (Nome)
  </label>
  <input
    id="rd-text_field-kml0e3u8"
    (change)= "changeField()" 
    formControlName="nomeResponsavel"
    class="bricks-form__input  js-text js-field-cf_indique_um_amigo_para_participar"
    name="custom_fields[1338936]"
    type="text"
    data-use-type="STRING"
    placeholder="Indique um amigo para participar (Nome)"
    
  >
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-kml0e3u9" class="bricks-form__label">
    Telefone do amigo
  </label>
  <input
    id="rd-text_field-kml0e3u9"
    (change)= "changeField()" 
    formControlName="telefoneResponsavel"
    class="bricks-form__input  js-text js-field-cf_telefone_do_amigo"
    name="custom_fields[1338937]"
    type="text"
    data-use-type="STRING"
    placeholder="Telefone do amigo"
    
  >
</div>


<div class="bricks-form__field " >
  
    <input type="hidden" name="custom_fields[1048901]" value="0">
  
  <div class="bricks-form__field__option">
    <label class="bricks-form__label">
      <input
        id="rd-checkbox_field-kml0e3ua"
        name="custom_fields[1048901]"
        type="checkbox"
        value="1"
        (change)= "changeField()"
        data-use-type="STRING"
        data-always-visible=""
        class="bricks-form__field__option__input required js-field-cf_li_e_aceito_edital_do_concurso_de_bolsas"
        required="required"
      >
      Li e concordo com o edital do concurso de bolsas*
    </label>
  </div>
</div>
<div class="bricks-form__field">
  <label for="captcha" class="bricks-form__label">
    <span id="math_expression">1 + 1 = ?</span>
  </label>
  <input
    id="captcha"
    (change)= "changeField()"
    class="bricks-form__input math rd-bricks-captcha"
    name="captcha"
    type="text"
    placeholder="1 + 1 = ?"
  >
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-joq3m2m7 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #ff1f65;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 350px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    #rd-button-disabled {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #ff1f65;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 100px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 350px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2m7 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <div class="bricks-form__submit" *ngIf="buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-joq3m2m7"
    >
      QUERO PARTICIPAR!
    </button>
  </div>
  <div class="bricks-form__submit" *ngIf="!buttonActive">
    <button
      class="bricks--component bricks--component-button rd-button"
      id="rd-button-disabled"
      (click)= "avisoCampos()"
    >
      QUERO PARTICIPAR!
    </button>
  </div>



    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>

  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1616507210"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1616507210"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1616507210"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/concoc-enem-rio-do-sul/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/concoc-enem-rio-do-sul/conversao'); } catch(err) { }
        }
      

      

      

      var redirectTo = $("input[name='redirect_to']");

      if (redirectTo.length && redirectTo.val().length) {
        top.location.href = $("input[name='redirect_to']").val();
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


<style type="text/css" rel="stylesheet">
  
    #rd-text-kebfp2t8 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 288px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kebfp2t8" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 13px;">Confira o edital completo clicando </strong><a href="https://drive.google.com/file/d/1NMU-b5LfrzTg75m-ZZfIrgDgAXm6YCl2/view?usp=sharing" rel="noopener noreferrer" target="_blank" title="" style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 13px;"><strong><u>AQUI</u></strong></a></p>
</div>

</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kcf6uugg {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1616442361/$1i7i8ly1c7g), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kcf6uugg > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kcf6uugg" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kcf6uugh {
      min-height: 50px;
    }

    #rd-row-kcf6uugh .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kcf6uugh.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kcf6uugh" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-keahc2yc {
      flex: 12 0 0;
    }

    #rd-column-keahc2yc > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-keahc2yc > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-keahc2yc" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-keahdi0x {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-44px;
      max-width: 684px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-keahdi0x" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(51, 51, 51);">ENTENDA COMO FUNCIONA!</strong></h1>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-keai1vfj {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #ff1f65;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 30px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 30px;
      margin-top: 10px;
      max-width: 275px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-keai1vfj {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://drive.google.com/file/d/1NMU-b5LfrzTg75m-ZZfIrgDgAXm6YCl2/view?usp=sharing"
    id="rd-button-keai1vfj"
    target="_blank"
    title=""
  >
    CONFIRA O EDITAL COMPLETO
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-juljnjbr {
      
          background-color: #222222;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-juljnjbr > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-juljnjbr" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-juljnjbs {
      min-height: 50px;
    }

    #rd-row-juljnjbs .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-juljnjbs.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-juljnjbs" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-juljnjbt {
      flex: 12 0 0;
    }

    #rd-column-juljnjbt > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-juljnjbt > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-juljnjbt" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-juljno6w {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 497px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-juljno6w" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 12px; font-family: Ubuntu, sans-serif; color: rgb(255, 255, 255);">® COC Rio do Sul - Todos os direitos reservados - Feito por Nota Máxima</span></p>
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d24ecbab-6dfd-4592-b8fd-59cfba8ebcfb-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="2059321" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-137261937-5';
    var domain = 'coconline.com.br';

    function initializeBricksGA() {
      _gaq.push(['_setAccount', uuid]);
      if (document.domain.indexOf(domain) != -1) {
        _gaq.push(['_setDomainName', domain]);
      }
      _gaq.push(['_setAllowLinker', true]);
      _gaq.push(['_trackPageview']);

      (function() {
        var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
        ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      })();

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id, null, true]);

      if (idsDataset.experimentContentId) {
        _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId, null, true]);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    $(window).on("load", function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    });
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
  </body>
</html>
