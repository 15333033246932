
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    

    
      <meta property="og:title" content="Inscrição concluída!" />
      <meta name="twitter:title" content="Inscrição concluída!" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.coconline.com.br/agradecimento-concoc-online-2024-2" />
    

    <title>Inscrição concluída!</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NM5DHFZ3');</script>
<!-- End Google Tag Manager -->

    
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
    </script>
    
  </head>
  <body>
    
      
        <script type="text/javascript">
          (function() {
            var scriptId = "ga-gtag";

            if (document.getElementById(scriptId)) return;

            var script = document.createElement("script");
            var trackingId = ""
            var lpId = "6411882"
            var options = { send_page_view: !false }

            script.setAttribute("id", scriptId);
            script.setAttribute("type", "text/javascript");
            script.setAttribute(
              "src",
              `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
            );
            script.setAttribute("async", "");

            document.head.insertBefore(script, document.head.firstChild);

            gtag("js", new Date());
            gtag("config", trackingId, options);
            gtag("event", "RD Landing Pages", {
              rd_asset_id: lpId,
              rd_filter: "",
              rd_action: "viewed"
            })
            var experimentContentId = ""
            if(experimentContentId){
              gtag("event", "RD Landing Pages", {
                rd_asset_id: lpId,
                rd_filter: "experiment:"+experimentContentId,
                rd_action: "viewed"
              })
            }
          }());
        </script>
      
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kppthk7k {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/121436/1719495641/$jr4uz31v4oc), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kppthk7k > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-kppthk7k" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lpu1cjvi {
      min-height: 50px;
    }

    #rd-row-lpu1cjvi .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lpu1cjvi.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lpu1cjvi.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lpu1cjvi" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lpu1cjvj {
      flex: 12 0 0;
    }

    #rd-column-lpu1cjvj > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lpu1cjvj > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lpu1cjvj" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-lpu1cv1f {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lpu1cv1f" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-lpu1csaz {
      min-height: 50px;
    }

    #rd-row-lpu1csaz .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lpu1csaz.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lpu1csaz.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lpu1csaz" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lpu1csb0 {
      flex: 12 0 0;
    }

    #rd-column-lpu1csb0 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lpu1csb0 > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lpu1csb0" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lpu1cy8z {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 294px;
    }

    #rd-image-lpu1cy8z a,
    #rd-image-lpu1cy8z img {
      width: 294px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lpu1cy8z {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lpu1cy8z" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719595479/$tmj2g133sz" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxbci75 {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lxxbci75" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxbbuba {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto42px;
      max-width: 502px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lxxbbuba.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lxxbbuba {
          display: none;
        }

        #rd-text-lxxbbuba.mobile {
          display: block;
          max-width: 390px;
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    
  
</style>

<div id="rd-text-lxxbbuba" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; background-color: transparent; color: rgb(60, 55, 118); font-size: 30px;">INSCRIÇÃO EFETUADA COM </strong><strong style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; background-color: transparent; color: rgb(189, 193, 50); font-size: 30px;">SUCESSO!</strong></p>
</div>


  <div id="rd-text-lxxbbuba" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><strong style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; background-color: transparent; color: rgb(60, 55, 118); font-size: 30px;">INSCRIÇÃO EFETUADA COM </strong><strong style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; background-color: transparent; color: rgb(189, 193, 50); font-size: 30px;">SUCESSO!</strong></p>
  </div>


<style type="text/css" rel="stylesheet">
  
    #rd-text-kylxv2au {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-25px;
      max-width: 770px;
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-kylxv2au" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 18px; color: rgb(60, 55, 118); background-color: transparent;">Prepare-se para a prova com a trilha de aprendizagem disponível em nossa plataforma.</strong></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-image-lxysezgo {
      margin-top: -3px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 491px;
    }

    #rd-image-lxysezgo a,
    #rd-image-lxysezgo img {
      width: 491px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lxysezgo {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lxysezgo" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719595479/$61t6wgz1oox" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-llo44zgw {
      min-height: 50px;
    }

    #rd-row-llo44zgw .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-llo44zgw.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-llo44zgw.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-llo44zgw" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-llo44zgx {
      flex: 12 0 0;
    }

    #rd-column-llo44zgx > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-llo44zgx > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-llo44zgx > div {
          padding-top: 0px;
          padding-right: 10px;
          padding-bottom: 0px;
          padding-left: 10px;
        }
      }
    
  
</style>

<div id="rd-column-llo44zgx" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-llo45ejr {
      display: flex;
      flex-direction: column;
    }

    #rd-button-llo45ejr.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #bdc132;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      
          box-shadow: 0px
                      0px
                      0px
                      0px
                      #cccccc;
        ;
      color: #ffffff;
      cursor: pointer;
      font-family: Source Sans Pro, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: bold;
      height: 53px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 258px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-llo45ejr {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://plataforma.coconline.com.br/signin"
    id="rd-button-llo45ejr"
    target="_blank"
    title="Any Title"
  >
    ACESSAR A PLATAFORMA
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxjsowk {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 792px;
      margin-top: 1px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-lxxjsowk.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-lxxjsowk {
          display: none;
        }

        #rd-text-lxxjsowk.mobile {
          display: block;
          max-width: 404px;
          margin-top: 1px;
          margin-bottom: 30px;
        }
      }
    
  
</style>

<div id="rd-text-lxxjsowk" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="background-color: transparent; color: rgb(60, 55, 118); font-size: 13px;">Pedimos que verifique constantemente o seu e-mail, pois todas as orientações serão enviadas via correio eletrônico.</strong></p>
</div>


  <div id="rd-text-lxxjsowk" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><strong style="background-color: transparent; color: rgb(60, 55, 118); font-size: 13px;">Pedimos que verifique constantemente o seu e-mail, pois todas as orientações serão enviadas via correio eletrônico.</strong></p>
  </div>


<style type="text/css" rel="stylesheet">
  
    #rd-text-ly2za3lz {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 770px;
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
    
      #rd-text-ly2za3lz.mobile {
        display: none;
      }

      @media (max-width: 480px) {
         #rd-text-ly2za3lz {
          display: none;
        }

        #rd-text-ly2za3lz.mobile {
          display: block;
          max-width: 257px;
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
    
  
</style>

<div id="rd-text-ly2za3lz" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><a href="https://wa.me/message/VFGBOFHYGSNEA1" rel="noopener noreferrer" target="_self" title="" style="background-color: transparent; color: rgb(60, 55, 118); font-size: 17px;"><strong><u>Salve o nosso número e tire todas as suas dúvidas por WhatsApp!</u></strong></a></p>
</div>


  <div id="rd-text-ly2za3lz" class="bricks--component bricks--component-text mobile rd-text">
    <p style="text-align: center;"><a href="https://wa.me/message/VFGBOFHYGSNEA1" rel="noopener noreferrer" target="_self" title="" style="font-size: 16px; color: rgb(60, 55, 118); background-color: transparent;"><strong><u>Salve o nosso número e tire todas as suas dúvidas!</u></strong></a></p>
  </div>


<style type="text/css" rel="stylesheet">
  
    #rd-text-lxxitedp {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lxxitedp" class="bricks--component bricks--component-text rd-text">
  <p> </p>
</div>
















<style type="text/css" rel="stylesheet">
  .mobile {
    display: none;
  }

  #rd-social_media-ly2zkci2.desktop {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 200px;
    }

  

  .bricks-social-media-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    list-style: none;
    padding: 0;
    gap: 16px;
    flex-wrap: wrap;
  }

  .bricks-social-media-item,
  .bricks-social-media-image {
    width: 24px;
    height: 24px;
  }
</style>

<div class="bricks--component bricks--component-social-media mobile rd-social_media" id="rd-social_media-ly2zkci2">
  <ul class="bricks-social-media-list">
    
      <li class="bricks-social-media-item">
        <a href="https://www.instagram.com/coconline.oficial/" target="_blank">
          <img src="
            https://d335luupugsy2.cloudfront.net/cms/public-assets/social_media/instagram_original.svg
          "
          alt="instagram icon"
          class="bricks-social-media-image" />
        </a>
      </li>
    
  </ul>
</div>

<div class="bricks--component bricks--component-social-media desktop rd-social_media" id="rd-social_media-ly2zkci2">
  <ul class="bricks-social-media-list">
    
      <li class="bricks-social-media-item">
        <a href="https://www.instagram.com/coconline.oficial/" target="_blank">
          <img src="
            https://d335luupugsy2.cloudfront.net/cms/public-assets/social_media/instagram_circle_dark.svg
          "
          alt="instagram icon"
          class="bricks-social-media-image" />
        </a>
      </li>
    
      <li class="bricks-social-media-item">
        <a href="https://www.youtube.com/@COCOnline" target="_blank">
          <img src="
            https://d335luupugsy2.cloudfront.net/cms/public-assets/social_media/youtube_circle_dark.svg
          "
          alt="youtube icon"
          class="bricks-social-media-image" />
        </a>
      </li>
    
  </ul>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-llo4865j {
      min-height: 50px;
    }

    #rd-row-llo4865j .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-llo4865j.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-llo4865j.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-llo4865j" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-llo4865k {
      flex: 12 0 0;
    }

    #rd-column-llo4865k > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-llo4865k > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-llo4865k" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-l4d5pzl1 {
      margin-top: 15px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 123px;
    }

    #rd-image-l4d5pzl1 a,
    #rd-image-l4d5pzl1 img {
      width: 123px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-l4d5pzl1 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-l4d5pzl1" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1719495641/$6097glh87tt" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d24ecbab-6dfd-4592-b8fd-59cfba8ebcfb-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6411882" data-experiment-content-id=""></script>


    

    <div data-editable="true" data-field-name="landing_page"></div>
    <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NM5DHFZ3"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    <style>
      /* FONTE DO TÍTULO*/
@font-face {
    font-family: 'trashhandregular';
    src: local('TrashHand'), url('https://aletech.com.br/trashhand.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#rd-text-kppt77vn h1 span{
    font-family: 'trashhandregular'!important;
    font-size: 79px!important;
    margin-bottom: 12px;
    background: -webkit-linear-gradient(#5548FF, #0A7EFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/*FIM DA FONTE DO TÍTULO*/

/*BOX DA PRIMEIRA SEÇÃO E DA ÚLTIMA SEÇÃO*/

#rd-box-l45zpsgd, #rd-box-l470thna, #rd-box-l470tpm1, #rd-box-l470trgj{
    overflow:hidden;
    border-radius: 15px;
}

/*FIM DO BOX DA PRIMEIRA SEÇÃO*/
#rd-section-l46zb1jt{
    background: linear-gradient(#64c500, #509600);
}

@media (max-width: 600px)
{
  #rd-section-l460eoho, #rd-section-l46367mc{
        background-position:center center;
    }
}

#rd-row-kylxpp37 .rd-box:hover img{
    transform: rotate(720deg) scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover{
    transform: scale(1.1);
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box{
    box-shadow: 20px 20px 20px #333;
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box:hover span, #rd-row-kylxpp37 .rd-box:hover strong{
    transition: 0.5s all;
}

#rd-row-kylxpp37 .rd-box, #rd-row-kylxpp37 .rd-box img, #rd-row-kylxpp37 .rd-box span, #rd-row-kylxpp37 .rd-box strong{
    transition: 0.5s all;
}

#rd-button-llo45ejr:hover {
  background-image: linear-gradient(#3c3776, #57528f);
}
    </style>
    

  </body>
</html>
