
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Estude Química de forma objetiva sem precisar sair de casa, com o melhor sistema de ensino do mundo!  ">
    

    
      <meta property="og:title" content="Química In 12 - Metodologia Nota Máxima Escolas" />
      <meta name="twitter:title" content="Química In 12 - Metodologia Nota Máxima Escolas" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    
      <meta property="og:description" content="Estude Química de forma objetiva sem precisar sair de casa, com o melhor sistema de ensino do mundo!  " />
      <meta name="twitter:description" content="Estude Química de forma objetiva sem precisar sair de casa, com o melhor sistema de ensino do mundo!  " />
    

    
      <link rel="canonical" href="http://conteudos.notamaxima.com.br/quimica-in-12" />
    

    <title>Química In 12 - Metodologia Nota Máxima Escolas</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
      }

      .bricks-form__field {
        width: 100%;
        flex-grow: 1;
      }

      /* Hide generated jQuery validator label */
      .bricks-form__field label.error {
        display: none !important;
      }

      .bricks-form__label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        line-height: 1.42857;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 10px;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .bricks-form__field__option + .bricks-form__field__option {
        margin-top: -5px;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 1200px;
      }
    </style>

    <script type="text/javascript"> </script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-k0o9x602 {
      
          background-color: #002d50;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-k0o9x602 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-k0o9x602" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-k0o9x603 {
      min-height: 50px;
    }

    #rd-row-k0o9x603 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-k0o9x603.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-k0o9x603" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-k18f3go2 {
      flex: 12 0 0;
    }

    #rd-column-k18f3go2 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-k18f3go2 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-k18f3go2" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-k18f49g3 {
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: center;
      max-width: 151px;
    }

    #rd-image-k18f49g3 a,
    #rd-image-k18f49g3 img {
      width: 151px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-k18f49g3 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-k18f49g3" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1597948360/$axbodpmy1mc" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgshbw2x {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-48px;
      max-width: 574px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgshbw2x p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgshbw2x" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 42px;">Plataforma Química i﻿n 12</strong></h1>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgshcch5 {
      min-height: 50px;
    }

    #rd-row-kgshcch5 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgshcch5.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgshcch5" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgshcch6 {
      flex: 12 0 0;
    }

    #rd-column-kgshcch6 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgshcch6 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgshcch6" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgshcele {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgshcele p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgshcele" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-k0occ27l {
      
          background-color: #ffffff;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-k0occ27l > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-k0occ27l" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-k0ocgcu7 {
      min-height: 122px;
    }

    #rd-row-k0ocgcu7 .bricks--column > div {
      min-height: 122px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-k0ocgcu7.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-k0ocgcu7" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kbi66gp9 {
      flex: 6 0 0;
    }

    #rd-column-kbi66gp9 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kbi66gp9 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kbi66gp9" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-video-kar6x9gz {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 530px;
      max-width: 100%;
    }

    #rd-video-kar6x9gz iframe {
      border-bottom-style: solid;
      border-color: #ffffff;
      border-left-style: solid;
      border-radius: 0px;
      border-right-style: solid;
      border-top-style: solid;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kar6x9gz" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/G1B8hqRpkxU?autoplay=0&rel=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-k8jh5iih {
      flex: 6 0 0;
    }

    #rd-column-k8jh5iih > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-k8jh5iih > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-k8jh5iih" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-k8jh5iii {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 520px;
      margin-top: 40px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k8jh5iii p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k8jh5iii" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 42px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);">Curso de química para o ENEM e os maiores Vestibulares do Brasil</strong></h1>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-k8kbly4s {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 520px;
      margin-top: 10px;
      margin-bottom: 15px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k8kbly4s p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k8kbly4s" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: justify; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);">O canal de Química mais objetivo e dinâmico do Youtube agora também em uma plataforma arrojada e inovadora. O curso </span><strong style="font-size: 14px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);">Química in 12</strong><span style="font-size: 14px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);"> se une ao Nota Máxima para que você tenha um estudo organizado e resultados surpreendentes!</span></p><p style="text-align: justify; line-height: 1.25;"><br></p><p style="text-align: justify; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">Com aulas objetivas e com mais de 20 anos de experiência nos principais vestibulares do país, o professor </span><strong style="font-size: 14px; font-family: Open Sans, sans-serif;">Francis Isotton</strong><span style="font-size: 14px; font-family: Open Sans, sans-serif;"> traz a forma exata de você alcançar suas aprovações.</span></p><p style="text-align: justify; line-height: 1.25;"><br></p><p style="text-align: justify; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">Planos de estudos inteligentes e direcionados aos seus objetivos. Cursos específicos (básicos/aprofundados). Faça parte da melhor plataforma de estudos online na área da Química.</span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-kasb80x8 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #002d50;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 400px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kasb80x8 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://quimicain12.notamaxima.com.br/signin"
    id="rd-button-kasb80x8"
    target="_blank"
    title=""
  >
    JÁ É ALUNO QUÍMICA IN 12? FAÇA LOGIN!
  </a>



<style type="text/css" rel="stylesheet">
  
    #rd-button-kbgtnuef {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #2ae117;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 40px;
      margin-top: 10px;
      max-width: 400px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kbgtnuef {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="#rd-section-jsc3dnf3"
    id="rd-button-kbgtnuef"
    target="_self"
    title=""
  >
    QUERO EXPERIMENTAR GRATUITAMENTE
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kgseawzf {
      
          background-color: #002d50;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kgseawzf > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kgseawzf" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kgseawzg {
      min-height: 50px;
    }

    #rd-row-kgseawzg .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgseawzg.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgseawzg" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgseawzh {
      flex: 12 0 0;
    }

    #rd-column-kgseawzh > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgseawzh > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgseawzh" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsebkv9 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 827px;
      margin-top: 50px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsebkv9 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsebkv9" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Escolha o curso ideal para você!</strong></h1><h1 style="text-align: center;"><span style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Aprenda Química de forma dinâmica e objetiva!</span></h1>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgsex08k {
      min-height: 50px;
    }

    #rd-row-kgsex08k .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgsex08k.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgsex08k" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsex08l {
      flex: 12 0 0;
    }

    #rd-column-kgsex08l > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsex08l > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsex08l" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsex08m {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsex08m p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsex08m" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgsef62k {
      min-height: 50px;
    }

    #rd-row-kgsef62k .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgsef62k.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgsef62k" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsef62l {
      flex: 3 0 0;
    }

    #rd-column-kgsef62l > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsef62l > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsef62l" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsefd05 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsefd05 a,
    #rd-image-kgsefd05 img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsefd05 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsefd05" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$j3ncefjj8x9" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgseff9h {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgseff9h p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgseff9h" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Plataforma exclusiva com acesso direto às aulas, podcasts, listas de exercícios e resumos dos conteúdo.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgseko8m {
      flex: 3 0 0;
    }

    #rd-column-kgseko8m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgseko8m > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgseko8m" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgseko8n {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgseko8n a,
    #rd-image-kgseko8n img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgseko8n {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgseko8n" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$2v7zek116o" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgseko8o {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgseko8o p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgseko8o" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Aulas objetivas em apenas 12 minutos.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsehrlv {
      flex: 3 0 0;
    }

    #rd-column-kgsehrlv > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsehrlv > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsehrlv" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsehrlw {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsehrlw a,
    #rd-image-kgsehrlw img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsehrlw {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsehrlw" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$3k6e9p888nx" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsehrlx {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsehrlx p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsehrlx" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Aulas diferenciadas para cada curso (Básico, Aprofundado, ENEM).</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsehstf {
      flex: 3 0 0;
    }

    #rd-column-kgsehstf > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsehstf > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsehstf" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsehstg {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsehstg a,
    #rd-image-kgsehstg img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsehstg {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsehstg" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$d4qpolg7by" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsehsth {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsehsth p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsehsth" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Revisões periódicas AO VIVO para cada segmento.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgsekq1z {
      min-height: 50px;
    }

    #rd-row-kgsekq1z .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgsekq1z.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgsekq1z" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsekq20 {
      flex: 3 0 0;
    }

    #rd-column-kgsekq20 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsekq20 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsekq20" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsekq21 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsekq21 a,
    #rd-image-kgsekq21 img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsekq21 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsekq21" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$8rydjaf317" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsekq22 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsekq22 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsekq22" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Contato direto com o professor via Whatsapp.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsekq23 {
      flex: 3 0 0;
    }

    #rd-column-kgsekq23 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsekq23 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsekq23" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsekq24 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsekq24 a,
    #rd-image-kgsekq24 img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsekq24 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsekq24" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$2x0csf3rp8m" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsekq25 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsekq25 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsekq25" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Acesso total ao material digital (ppt, material guia, exercícios).</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsekq26 {
      flex: 3 0 0;
    }

    #rd-column-kgsekq26 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsekq26 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsekq26" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsekq27 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsekq27 a,
    #rd-image-kgsekq27 img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsekq27 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsekq27" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$1z3rriz1b9p" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsekq28 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsekq28 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsekq28" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 16px; color: rgb(255, 255, 255);">Grupos de suporte e material complementar (Telegram).</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsekq29 {
      flex: 3 0 0;
    }

    #rd-column-kgsekq29 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsekq29 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsekq29" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgsekq2a {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-kgsekq2a a,
    #rd-image-kgsekq2a img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgsekq2a {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgsekq2a" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$e96pafio8hg" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsekq2b {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 245px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsekq2b p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsekq2b" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 16px; color: rgb(255, 255, 255);">Podcast resumindo os principais conteúdos da plataforma.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgsexufd {
      min-height: 50px;
    }

    #rd-row-kgsexufd .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgsexufd.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgsexufd" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgsexufe {
      flex: 12 0 0;
    }

    #rd-column-kgsexufe > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgsexufe > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgsexufe" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsexuff {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsexuff p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsexuff" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kgseazjt {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kgseazjt > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kgseazjt" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kgseazju {
      min-height: 50px;
    }

    #rd-row-kgseazju .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgseazju.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgseazju" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgseazjv {
      flex: 12 0 0;
    }

    #rd-column-kgseazjv > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgseazjv > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgseazjv" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kar70s42 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-44px;
      max-width: 856px;
      margin-top: 50px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kar70s42 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kar70s42" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 45, 80); font-size: 34px;">Conheça o Professor Francis</strong></h1><h1 style="text-align: center;"><span style="font-size: 34px; color: rgb(0, 45, 80); font-family: Open Sans, sans-serif;">Fundador do Química In 12</span></h1>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgseq56q {
      min-height: 50px;
    }

    #rd-row-kgseq56q .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgseq56q.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgseq56q" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgseq8y1 {
      flex: 6 0 0;
    }

    #rd-column-kgseq8y1 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgseq8y1 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgseq8y1" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgseqes1 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 551px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgseqes1 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgseqes1" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 2; text-align: center;">Professor Francis Isotton leciona a disciplina de Química há 20 anos nos melhores Pré-vestibulares do Sul do país. Professor e fundador do Canal Química in 12 (You Tube) possui amplo conhecimento estatístico e pedagógico nos principais Vestibulares do Brasil. Agora com uma plataforma exclusiva, objetiva e completa, permite ao aluno resolver toda e qualquer dúvida nessa maravilhosa disciplina que é a Química.</p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kgseq56r {
      flex: 6 0 0;
    }

    #rd-column-kgseq56r > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgseq56r > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgseq56r" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kgseqcwx {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 500px;
    }

    #rd-image-kgseqcwx a,
    #rd-image-kgseqcwx img {
      width: 500px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kgseqcwx {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kgseqcwx" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603828798/$28l0rtau1we" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kgselyxr {
      min-height: 50px;
    }

    #rd-row-kgselyxr .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kgselyxr.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kgselyxr" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kgselyxs {
      flex: 12 0 0;
    }

    #rd-column-kgselyxs > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kgselyxs > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kgselyxs" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgsem0fk {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgsem0fk p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgsem0fk" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kar6yxfp {
      
          background-color: #002d50;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kar6yxfp > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kar6yxfp" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kar70q4g {
      min-height: 50px;
    }

    #rd-row-kar70q4g .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kar70q4g.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kar70q4g" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kar70q4h {
      flex: 12 0 0;
    }

    #rd-column-kar70q4h > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kar70q4h > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kar70q4h" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kgselfdv {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-44px;
      max-width: 856px;
      margin-top: 50px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kgselfdv p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kgselfdv" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Escolha o curso ideal para você!</strong></h1><h1 style="text-align: center;"><span style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Aprenda Química de forma dinâmica e objetiva!</span></h1>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kar6yxfq {
      min-height: 1274px;
    }

    #rd-row-kar6yxfq .bricks--column > div {
      min-height: 1274px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kar6yxfq.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kar6yxfq" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kar6yxfr {
      flex: 4 0 0;
    }

    #rd-column-kar6yxfr > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kar6yxfr > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kar6yxfr" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-k0odgdy3 {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 30px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 15px;
      padding-right: 10px;
      padding-bottom: 25px;
      padding-left: 10px;
      min-height: auto70px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 448px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-k0odgdy3" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-k0ocjhtf {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 322px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k0ocjhtf p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k0ocjhtf" class="bricks--component bricks--component-text rd-text">
  <h2 style="line-height: 1; text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 24px; color: rgb(19, 19, 19);">CURSO ENEM</strong></h2><h2 style="text-align: center; line-height: 1;"><strong style="font-family: Open Sans, sans-serif; font-size: 24px; color: rgb(19, 19, 19);">QUÍMICA IN 12</strong></h2>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-video-kevpc5mq {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 311px;
      max-width: 100%;
    }

    #rd-video-kevpc5mq iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kevpc5mq" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/3EzgFHqvvUA?autoplay=0&rel=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-k18dkmf0 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 324px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k18dkmf0 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k18dkmf0" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 12px; color: rgb(0, 0, 0); font-family: Open Sans, sans-serif;"><s>De 8x R$14,90</s></strong></p><p style="text-align: center;"><strong style="font-size: 14px; color: rgb(18, 185, 93); font-family: Open Sans, sans-serif;">Por </strong><strong style="font-size: 34px; color: rgb(18, 185, 93); font-family: Open Sans, sans-serif;">8x R$9,90</strong></p><p style="text-align: center;"><strong style="font-size: 15px; color: rgb(0, 0, 0); font-family: Open Sans, sans-serif;">à vista R$ 69,90</strong></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-kbgtjz7l {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #2ae117;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kbgtjz7l {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/088613731015"
    id="rd-button-kbgtjz7l"
    target="_blank"
    title=""
  >
    COMPRAR NO CARTÃO EM ATÉ 8X
  </a>



<style type="text/css" rel="stylesheet">
  
    #rd-button-kasb83ls {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: rgba(47,122,210,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kasb83ls {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/419344968739"
    id="rd-button-kasb83ls"
    target="_blank"
    title=""
  >
    COMPRAR À VISTA COM DESCONTO
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-kar6xr8w {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 321px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kar6xr8w p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kar6xr8w" class="bricks--component bricks--component-text rd-text">
  <ul><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">03 Aulas semanais até a prova do Enem;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Plano de estudos inteligente;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Aulas dinâmicas e objetivas em 12 minutos;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Aulas voltadas especificamente à prova do Enem;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Material Guia (PDF) para acompanhamento das aulas;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Material em Power Point para acompanhamento das aulas;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Lista de Exercícios (mais de 600 questões);</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Simulado mensal (online) nos moldes no ENEM;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Correção ao vivo do Simulado Mensal;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Revisão mensal AO VIVO com o professor;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Grupo de estudos no Telegram;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Podcast dos principais assuntos da prova ENEM.</span></li></ul>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36dkxp {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 311px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36dkxp p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36dkxp" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Início em 21/09/2020</span></p><p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Acesso até 28/02/2021</span></p>
</div>

</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-ke36ijd4 {
      flex: 4 0 0;
    }

    #rd-column-ke36ijd4 > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-ke36ijd4 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-ke36ijd4" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-ke36ijd5 {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 30px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 15px;
      padding-right: 10px;
      padding-bottom: 25px;
      padding-left: 10px;
      min-height: auto70px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 448px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-ke36ijd5" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ijd6 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 322px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ijd6 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ijd6" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center; line-height: 1;"><strong style="color: rgb(19, 19, 19); font-size: 24px; font-family: Open Sans, sans-serif;">CURSO BÁSICO</strong></h2><h2 style="text-align: center; line-height: 1;"><strong style="color: rgb(19, 19, 19); font-size: 24px; font-family: Open Sans, sans-serif;">QUÍMICA GERAL</strong></h2>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-video-kecrpwlj {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 311px;
      max-width: 100%;
    }

    #rd-video-kecrpwlj iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kecrpwlj" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/TK1Us96aCtM?autoplay=0&rel=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ijd7 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 324px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ijd7 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ijd7" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 12px; color: rgb(0, 0, 0); font-family: Open Sans, sans-serif;"><s>De 8x R$14,90</s></strong></p><p style="text-align: center;"><strong style="font-size: 14px; color: rgb(18, 185, 93); font-family: Open Sans, sans-serif;">Por </strong><strong style="font-size: 34px; color: rgb(18, 185, 93); font-family: Open Sans, sans-serif;">8x R$9,90</strong></p><p style="text-align: center;"><strong style="font-size: 15px; color: rgb(0, 0, 0); font-family: Open Sans, sans-serif;">à vista R$ 69,90</strong></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-ke36ijd8 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #2ae117;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-ke36ijd8 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/864059922087"
    id="rd-button-ke36ijd8"
    target="_blank"
    title=""
  >
    COMPRAR NO CARTÃO EM ATÉ 8X
  </a>



<style type="text/css" rel="stylesheet">
  
    #rd-button-ke36ijd9 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: rgba(47,122,210,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-ke36ijd9 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/233703064907"
    id="rd-button-ke36ijd9"
    target="_blank"
    title=""
  >
    COMPRAR À VISTA COM DESCONTO
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ijda {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 321px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ijda p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ijda" class="bricks--component bricks--component-text rd-text">
  <ul><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">60 aulas específicas de conteúdo básico em Química Geral;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Plano de estudos inteligente;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Aulas dinâmicas e objetivas em 12 minutos;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Aulas voltadas diretamente à parte básica;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Material Guia para acompanhamento das aulas;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Lista de Exercícios (mais de 600 questões);</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Simulado mensal (online) em Química Geral;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Revisão mensal AO VIVO com o professor;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Grupo de estudos no Telegram;</span></li><li style="text-align: left; line-height: 2;"><span style="font-family: Open Sans, sans-serif;">Podcast dos principais assuntos básicos da química.</span></li></ul>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ijdb {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 311px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ijdb p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ijdb" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Início em 21/09/2020</span></p><p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Acesso até 28/02/2021</span></p>
</div>

</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-ke36ip44 {
      flex: 4 0 0;
    }

    #rd-column-ke36ip44 > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-ke36ip44 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-ke36ip44" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-ke36ip45 {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 30px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 15px;
      padding-right: 10px;
      padding-bottom: 25px;
      padding-left: 10px;
      min-height: auto70px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 448px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-ke36ip45" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ip46 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 322px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ip46 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ip46" class="bricks--component bricks--component-text rd-text">
  <h2 style="line-height: 1; text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 24px; color: rgb(19, 19, 19);">CURSO DE APROFUNDAMENTO EM QUÍMICA</strong></h2>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-video-kecrqam7 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 311px;
      max-width: 100%;
    }

    #rd-video-kecrqam7 iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kecrqam7" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/E_X_5J190ws?autoplay=0&rel=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ip47 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 324px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ip47 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ip47" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 0, 0); font-size: 12px;"><s>De 12x R$19,90</s></strong></p><p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(18, 185, 93); font-size: 14px;">Por </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(18, 185, 93); font-size: 34px;">12x R$12,90</strong></p><p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(0, 0, 0); font-size: 15px;">à vista R$ 139,90</strong></p>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-button-ke36ip48 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: #2ae117;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-ke36ip48 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/048445224310"
    id="rd-button-ke36ip48"
    target="_blank"
    title=""
  >
    COMPRAR NO CARTÃO EM ATÉ 12X
  </a>



<style type="text/css" rel="stylesheet">
  
    #rd-button-ke36ip49 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-items: center;
      align-self: center;
      background-color: rgba(47,122,210,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 15px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      #rd-button-ke36ip49 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
        align-self: center;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.asaas.com/c/585187108239"
    id="rd-button-ke36ip49"
    target="_blank"
    title=""
  >
    COMPRAR À VISTA COM DESCONTO
  </a>


<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ip4a {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 321px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ip4a p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ip4a" class="bricks--component bricks--component-text rd-text">
  <ul><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Aulas ao vivo toda semana (3 h semanais);</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Plano de estudos inteligente;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Aulas dinâmicas e objetivas;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Aulas voltadas aos vestibulares mais concorridos do país;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Material Guia para acompanhamento das aulas;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Lista de Exercícios (mais de 1000 exercícios propostos);</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Simulado mensal (online) em nível aprofundado;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Revisão periódica AO VIVO com o professor;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Grupo de estudos no Telegram;</span></li><li style="line-height: 2; text-align: left;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Podcast dos principais assuntos básicos/aprofundados da química.</span></li></ul>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-ke36ip4b {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 311px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-ke36ip4b p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-ke36ip4b" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Início em 21/09/2020</span></p><p style="text-align: center; line-height: 1;"><span style="font-size: 13px;">*Acesso até 28/02/2021</span></p>
</div>

</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-jsc3dnf3 {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 1px;
      border-right-style: none;
      border-top-style: none;
      border-width: 110px;
    }

    #rd-section-jsc3dnf3 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-jsc3dnf3" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-jsc3dnf4 {
      min-height: 599px;
    }

    #rd-row-jsc3dnf4 .bricks--column > div {
      min-height: 599px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-jsc3dnf4.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-jsc3dnf4" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jsc3dpzm {
      flex: 7 0 0;
    }

    #rd-column-jsc3dpzm > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jsc3dpzm > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-jsc3dpzm" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-k8ji5cgu {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 932px;
      margin-top: 50px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k8ji5cgu p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k8ji5cgu" class="bricks--component bricks--component-text rd-text">
  <h3><strong style="color: rgb(0, 0, 0); font-size: 42px; font-family: Open Sans, sans-serif;">Solicite uma demonstração por 3 dias!</strong></h3><p><br></p><p><span style="color: rgb(0, 0, 0); font-size: inherit; font-family: Open Sans, sans-serif;">Estude conosco e tenha todo suporte necessário para se dar bem no ENEM e nos vestibulares!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0); font-size: inherit; font-family: Open Sans, sans-serif;">Peça seu acesso gratuito por 3 dias em nossa plataforma online. Queremos que você sinta a experiência de ser um aluno online do Química In 12!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0); font-size: 12px; font-family: Open Sans, sans-serif; background-color: transparent;">Seus dados estão protegidos conosco.</span></p><p style="text-align: center;"><br></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kar76s69 {
      flex: 5 0 0;
    }

    #rd-column-kar76s69 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kar76s69 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kar76s69" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kar771g1 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 400px;
      margin-top: 50px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-kar771g1 p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-kar771g1" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 32px; color: rgb(0, 0, 0);">Preencha o formulário: </strong></h2><p style="text-align: center;"><br></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 400px;
      margin-top: 10px;
      margin-bottom: 60px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 5px;
      padding: 0;
      width: 100.0%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #000000;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #000000;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #000000;
      display: inline-block;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #000000;
      background-color: #ffffff;
      border-bottom-style: solid;
      border-left-style: solid;
      border-right-style: solid;
      border-top-style: solid;
      border-color: #c9c9c9;
      border-radius: 20px;
      border-width: 1px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 40px !important;
      line-height: 40px !important;

      color: #000000 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 14px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 40px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags label.error {
      color: #DC3543;
    }
  
</style>


<div
id="rd-form-joq3m2m5"
class="bricks--component bricks-form rd-form"
>

<form
  [formGroup] = "profileForm"
  (ngSubmit)="onSubmit()"
  id="conversion-form"
  action="https://www.rdstation.com.br/api/1.2/conversions"
  data-typed-fields=""
  data-lang="pt-BR"
>

  <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
  <input type="hidden" name="identificador" value="quimica-in-12">
  <input type="hidden" name="_is" value="2">
  <input type="hidden" name="_rd_experiment_content_id" value="">
  <input type="hidden" name="c_utmz" value=""/>
  <input type="hidden" name="traffic_source" value=""/>
  <input type="hidden" name="redirect_to" value="https://conteudos.notamaxima.com.br/confirmacao-quimicain12"/>
  <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

  

  


  <div
class="bricks-form__fieldset"
data-progressive-profiling=""
>
<div class="bricks-form__field">
<label for="rd-text_field-k8hofpkn" class="bricks-form__label">
  Nome*
</label>
<input
  formControlName="nome"
  id="rd-text_field-k8hofpkn"
  class="bricks-form__input required js-text js-field-name"
  name="name"
  type="text"
  data-use-type="STRING"
  placeholder="Nome *"
  required="required"
>
</div>
<div class="bricks-form__field">
<label for="rd-email_field-k8hofpko" class="bricks-form__label">
  Email*
</label>
<input
  formControlName="email"
  id="rd-email_field-k8hofpko"
  class="bricks-form__input required js-email js-field-email"
  name="email"
  type="email"
  data-use-type="STRING"
  placeholder="Email *"
  required="required"
>
</div>
<div class="bricks-form__field">
<label for="rd-senha_field-kcng8yh5" class="bricks-form__label">
  Crie a sua senha*
</label>
<input
  formControlName="senha"
  id="rd-senha_field-kcng8yh5"
  class="bricks-form__input required js-senha js-field-senha"
  name="senha"
  type="senha"
  data-use-type="STRING"
  placeholder="Senha *"
  required="required"
>
</div> 
<div class="bricks-form__field with-select-flags">
<label for="rd-phone_field-k8hofpkp" class="bricks-form__label">
  Telefone*
</label>
<div class="phone-input-group">
  
    <div class="form-control phone-country" data-type="countries"></div>
    <div class="country-field" value="BR"></div>
  

  <input
    id="rd-phone_field-k8hofpkp"
    class="bricks-form__input required phone js-phone js-field-personal_phone"
    name="personal_phone"
    data-input-mask="INTERNATIONAL_MASK"
    data-use-type="STRING"
    type="tel"
    placeholder="Telefone *"
    required="required"
  >
</div>
</div>
<div class="bricks-form__field">
<label for="captcha" class="bricks-form__label">
  <span id="math_expression">1 + 1 = ?</span>
</label>
<input
  id="captcha"
  class="bricks-form__input math rd-bricks-captcha"
  name="captcha"
  type="text"
  placeholder="1 + 1 = ?"
>
</div>

</div>


<style type="text/css" rel="stylesheet">

  #rd-button-joq3m2m7 {
    -ms-flex-align: center;
    -ms-flex-direction: column;
    -ms-flex-pack: center;
    align-items: center;
    align-self: center;
    background-color: #2ae117;
    border-bottom-style: none;
    border-color: #000000;
    border-left-style: none;
    border-radius: 100px;
    border-right-style: none;
    border-top-style: none;
    border-width: 0px;
    color: #FFFFFF;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    height: 57px;
    justify-content: center;
    line-height: 1.42857;
    margin-bottom: 0px;
    margin-top: 15px;
    max-width: 400px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

</style>


<div class="bricks-form__submit">
  <button
    class="bricks--component bricks--component-button rd-button"
    id="rd-button-joq3m2m7"
  >
    SOLICITAR MINHA DEMONSTRAÇÃO
  </button>
</div>



  <div style="position: fixed; left: -5000px">
    <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
    <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
  </div>

</form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1603911420"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1603911420"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1603911420"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/quimica-in-12/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/quimica-in-12/conversao'); } catch(err) { }
        }
      

      

      try {
        var jsonApiResponse = JSON.parse(resp.responseText);
        if (jsonApiResponse['result'] === 'double_opt_in_email_sent') {
          top.location.href = jsonApiResponse['redirect_to'];

          return;
        }
      } catch(err) {
        console.log(err, resp)
      }

      var redirectTo = $("input[name='redirect_to']");

      if (redirectTo.length && redirectTo.val().length) {
        top.location.href = $("input[name='redirect_to']").val();
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-jspxq9mi {
      
          background-color: #002d50;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-jspxq9mi > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-jspxq9mi" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-jspxq9mj {
      min-height: 131px;
    }

    #rd-row-jspxq9mj .bricks--column > div {
      min-height: 131px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-jspxq9mj.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-jspxq9mj" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-k18f102h {
      flex: 12 0 0;
    }

    #rd-column-k18f102h > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-k18f102h > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-k18f102h" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-k18fcaqw {
      margin-top: 10px;
      margin-bottom: 0px;
      align-self: center;
      max-width: 230px;
    }

    #rd-image-k18fcaqw a,
    #rd-image-k18fcaqw img {
      width: 230px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-k18fcaqw {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-k18fcaqw" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1597953939/$c25e75a194p" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-k8hog54h {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 661px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: 767px) {
      #rd-text-k8hog54h p {
        text-align: left !important;
      }
    }
  
</style>

<div id="rd-text-k8hog54h" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 10px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;"> Powered by Nota Máxima Escolas © - Todos os direitos reservados à Ale Educação e Tecnologia - CNPJ: 31.745.881/0001-01</span></p>
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="1669626" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      _gaq.push(['_setAccount', uuid]);
      if (document.domain.indexOf(domain) != -1) {
        _gaq.push(['_setDomainName', domain]);
      }
      _gaq.push(['_setAllowLinker', true]);
      _gaq.push(['_trackPageview']);

      (function() {
        var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
        ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      })();

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id, null, true]);

      if (idsDataset.experimentContentId) {
        _gaq.push(['_trackEvent', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId, null, true]);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    $(window).on("load", function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    });
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      

@media (max-width: 1023px) {


#rd-image-k0o9xfas, #rd-image-k18f49g3, #rd-image-k18fcaqw, #rd-image-k18fcd4l {align-self: center !important}
#rd-column-jsc2cjzv {background: #00be9c !important;}

#rd-image-k18f49g3 a, #rd-image-k18f49g3 img { width: 150px !important}

}
    </style>
  </body>
</html>
