import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-concursodebolsas2024-agradecimento',
  templateUrl: './concursodebolsas2024-agradecimento.component.html',
  styleUrls: ['./concursodebolsas2024-agradecimento.component.scss']
})
export class Concursodebolsas2024AgradecimentoComponent implements OnInit {

  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        this.link =  atob(params.link) ;
      });
  }
}
