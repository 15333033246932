import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcursoBolsasService } from '../concurso-bolsas.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-simulado-uel-sigma',
  templateUrl: './simulado-uel-sigma.component.html',
  styleUrls: ['./simulado-uel-sigma.component.scss']
})
export class SimuladoUelSigmaComponent implements OnInit {

  buttonActive:boolean = false;
  profileForm: FormGroup;
  botaoEnviar: boolean;
  landClasses: any;
  constructor(  private route: ActivatedRoute,
                private router : Router,
                private  concursoBolsasService: ConcursoBolsasService
  ) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(""),
      email: new FormControl(""),
      senha: new FormControl(""),
      turma: new FormControl(""),
      telefone: new FormControl(""),
      colegio: new FormControl(""),
      alunode: new FormControl(""),  
      linguaestrangeira: new FormControl(""), 
      nomeResponsavel: new FormControl(""), 
      telefoneResponsavel: new FormControl(""), 
      turmaqueentra: new FormControl(""),
      agree: new FormControl(""), 
      captcha: new FormControl(""), 
    
    });
    this.getLandClass();
  }

  getLandClass(){
    this.concursoBolsasService.getLandRoute("simulado-uel-sigma")
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {

            this.landClasses = response.data; 
            console.info(this.landClasses); 
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-select_field-kelhwh7o")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {

    if(this.botaoEnviar){ 
    
      // TODO: Use EventEmitter with form value
      var data = this.profileForm.getRawValue(); 
  
      var jsondata = {
        name : data.nome,
        email: data.email,
        pass: data.senha,    
        telefone: data.telefone,
        colegio: data.colegio,
        alunode: data.alunode, 
        urlRedirect: "https://sigmacursoecolegio.coconline.com.br/redirect",
        pv :"c58c4f3c82ba76bcc504b89dbfb2b3a2"
      } 
      this.concursoBolsasService.register(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
    
          if (!response.error) { 
              let url:any = response.data; 
              //await localStorage.setItem('link',url.link);
              console.info(url.link);
              this.router.navigate( ['/simulado-uel-sigma-confirmacao'], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    
    if(data.nome != null && data.email != null && 
      data.senha != null && data.telefone != null && 
      data.colegio != null && data.alunode != null && 
      data.linguaestrangeira != null && 
      data.captcha!= null ){ 
      this.buttonActive = true;
      this.botaoEnviar= true;
    }
  }


}
