
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    

    
      <meta property="og:title" content="Cadastro feito!" />
      <meta name="twitter:title" content="Cadastro feito!" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://cocblumenau.gruponotamaxima.com.br/agradecimento-concoc-2025-bnu" />
    

    <title>Cadastro feito!</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__field--hidden {
        display: none;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link rel="preload" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
    <noscript>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter|Cabin|Crimson+Text|Droid+Sans|Droid+Serif|Lato|Lobster|Montserrat|Old+Standard+TT|Open+Sans|Oswald|Pacifico|Playfair+Display|PT+Sans|Raleway|Rubik|Source+Sans+Pro|Ubuntu|Roboto">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css">
    </noscript>

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 960px;
      }
    </style>

    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MVSMKM8C');</script>
<!-- End Google Tag Manager -->

    
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
    </script>
    
  </head>
  <body>
    
      
        <script type="text/javascript">
          (function() {
            var scriptId = "ga-gtag";

            if (document.getElementById(scriptId)) return;

            var script = document.createElement("script");
            var trackingId = ""
            var lpId = "6621506"
            var options = { send_page_view: !false }

            script.setAttribute("id", scriptId);
            script.setAttribute("type", "text/javascript");
            script.setAttribute(
              "src",
              `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
            );
            script.setAttribute("async", "");

            document.head.insertBefore(script, document.head.firstChild);

            gtag("js", new Date());
            gtag("config", trackingId, options);
            gtag("event", "RD Landing Pages", {
              rd_asset_id: lpId,
              rd_filter: "",
              rd_action: "viewed"
            })
            var experimentContentId = ""
            if(experimentContentId){
              gtag("event", "RD Landing Pages", {
                rd_asset_id: lpId,
                rd_filter: "experiment:"+experimentContentId,
                rd_action: "viewed"
              })
            }
          }());
        </script>
      
    

    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2vm {
      
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.46) 0%, rgba(255,255,255,0.46) 100%), url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1723493653/$kfy24moplh);
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #585858;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2vm > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-joq3m2vm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2vn {
      min-height: 408px;
    }

    #rd-row-joq3m2vn .bricks--column > div {
      min-height: 408px;
    }

    @media (max-width: 480px) {
      #rd-row-joq3m2vn.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2vn.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2vn" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-jruwfhaa {
      flex: 12 0 0;
    }

    #rd-column-jruwfhaa > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-jruwfhaa > div {
        min-height: auto !important;
      }
    }
  

  
    
      @media (max-width: 480px) {
        #rd-column-jruwfhaa > div {
          padding-top: 0px;
          padding-right: 25px;
          padding-bottom: 0px;
          padding-left: 25px;
        }
      }
    
  
</style>

<div id="rd-column-jruwfhaa" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lz08q2j9 {
      margin-top: 7px;
      margin-bottom: -33px;
      align-self: center;
      max-width: 327px;
    }

    #rd-image-lz08q2j9 a,
    #rd-image-lz08q2j9 img {
      width: 327px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lz08q2j9 {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lz08q2j9" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1721849149/$kmdkc49nbnj" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-lyio7sen {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 685px;
      margin-top: 10px;
      margin-bottom: 12px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lyio7sen" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><span style="font-family: &quot;Arial Black&quot;, Gadget, sans-serif; font-size: 38px; color: rgb(95, 135, 32);">Você garantiu a sua vaga no Concurso de Bolsas do COC!</span></p>
</div>



<style type="text/css" rel="stylesheet">
  
    #rd-text-lq0zu11q {
      display: block;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 910px;
      margin-top: 10px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  

  
</style>

<div id="rd-text-lq0zu11q" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 23px; color: rgb(0, 151, 57); font-family: Roboto, sans-serif;">Prepare-se para a prova com a trilha de aprendizagem disponível em nossa plataforma.</span></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-image-lzrfyv2l {
      margin-top: 10px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 802px;
    }

    #rd-image-lzrfyv2l a,
    #rd-image-lzrfyv2l img {
      width: 802px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzrfyv2l {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lzrfyv2l" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723493653/$9qw81qrr62" alt="">

  
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-lzrg6jc1 {
      display: flex;
      flex-direction: column;
    }

    #rd-button-lzrg6jc1.bricks--component-button {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #9b5099;
      border-bottom-style: solid;
      border-color: transparent;
      border-left-style: solid;
      border-radius: 100px;
      border-right-style: solid;
      border-top-style: solid;
      border-width: 1px;
      
          box-shadow: 0px
                      0px
                      15px
                      0px
                      #9b5099;
        ;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Arial Black, Gadget, sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: normal;
      height: 49px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 85px;
      margin-top: 10px;
      max-width: 446px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-lzrg6jc1 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  

  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://blumenau.coconline.com.br"
    id="rd-button-lzrg6jc1"
    target="_blank"
    title="Clique aqui e acesse a plataforma"
  >
    Clique aqui e acesse a plataforma
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-lzr7eot8 {
      
          background-color: #009739;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-lzr7eot8 > div:not(.bricks--row) {
      min-height: 0px;
    }
  

  
</style>

<section id="rd-section-lzr7eot8" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-lzr7eot9 {
      min-height: 50px;
    }

    #rd-row-lzr7eot9 .bricks--column > div {
      min-height: 50px;
    }

    @media (max-width: 480px) {
      #rd-row-lzr7eot9.bricks--row {
        min-height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      #rd-row-lzr7eot9.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-lzr7eot9" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-lzr7eota {
      flex: 12 0 0;
    }

    #rd-column-lzr7eota > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-lzr7eota > div {
        min-height: auto !important;
      }
    }
  

  
</style>

<div id="rd-column-lzr7eota" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-lzr7ewtu {
      margin-top: 15px;
      margin-bottom: 15px;
      align-self: center;
      max-width: 296px;
    }

    #rd-image-lzr7ewtu a,
    #rd-image-lzr7ewtu img {
      width: 296px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-lzr7ewtu {
        align-self: center;
      }
    }
  

  
</style>



<div id="rd-image-lzr7ewtu" class="bricks--component bricks--component-image rd-image">
  
        <img loading="lazy" src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1723479841/$89dhrgf70a4" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="6621506" data-experiment-content-id=""></script>

    <div data-editable="true" data-field-name="landing_page"></div>
    <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVSMKM8C"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    <style>
      #rd-button-lp794x96:hover 

{ background-color: #82aa39; 

transition: 0.5s;

opacity: 10;

}

#rd-button-joq3m2w0:hover {

    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.6); 

}
    </style>
    

  </body>
</html>

