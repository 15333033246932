
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Chegou a hora de colocar os seus conhecimentos para o ENEM 2021 à prova, neste simulado on-line e GRATUITO do Nota Máxima!">
    

    
      <meta property="og:title" content="Simulado Nacional ENEM - Nota Máxima" />
      <meta name="twitter:title" content="Simulado Nacional ENEM - Nota Máxima" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    
      <meta property="og:description" content="Chegou a hora de colocar os seus conhecimentos para o ENEM 2021 à prova, neste simulado on-line e GRATUITO do Nota Máxima!" />
      <meta name="twitter:description" content="Chegou a hora de colocar os seus conhecimentos para o ENEM 2021 à prova, neste simulado on-line e GRATUITO do Nota Máxima!" />
    

    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/simulado-nacional-enem" />
    

    <title>Simulado Nacional ENEM - Nota Máxima</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 1280px;
      }
    </style>

    <script type="text/javascript"> </script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kroy5g47 {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$a5c17al00wn), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kroy5g47 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kroy5g47" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kroy613x {
      min-height: 50px;
    }

    #rd-row-kroy613x .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kroy613x.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kroy613x" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kroy613y {
      flex: 12 0 0;
    }

    #rd-column-kroy613y > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kroy613y > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kroy613y" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kroy62jq {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kroy62jq" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kroy5g48 {
      min-height: 50px;
    }

    #rd-row-kroy5g48 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kroy5g48.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kroy5g48" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kroy8dzp {
      flex: 12 0 0;
    }

    #rd-column-kroy8dzp > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kroy8dzp > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kroy8dzp" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp10jva {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 295px;
    }

    #rd-image-krp10jva a,
    #rd-image-krp10jva img {
      width: 295px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp10jva {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp10jva" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$g29k09edjpo" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kroy8ruc {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kroy8ruc" class="bricks--component bricks--component-text rd-text">
  <p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Chegou a hora de colocar os seus conhecimentos para o ENEM 2021 à prova neste simulado on-line e GRATUITO do Nota Máxima!</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Serão dois dias de prova no mesmo formato do ENEM, totalizando 180 QUESTÕES; duas semanas de aulas AO VIVO, precedendo cada prova; além de </span><strong style="color: rgb(88, 26, 179); font-size: 16px; font-family: Open Sans, sans-serif; background-color: rgb(255, 255, 255);"> PRÊMIOS para as melhores colocações!</strong><strong style="color: rgb(0, 0, 0); font-size: 16px; font-family: Open Sans, sans-serif; background-color: rgb(255, 255, 255);"> </strong></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: Open Sans, sans-serif;">Quer se dar bem no ENEM? Então faça a sua inscrição no Simulado Nacional ENEM do Nota Máxima!</span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-kroy9k83 {
          display: flex;
      flex-direction: column;
    }

    #rd-button-kroy9k83 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #f6c14e;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #000000;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      height: 49px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 223px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kroy9k83 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


 <!--  <a
    class="bricks--component bricks--component-button rd-button"
    href="#rd-section-krp6zg6c"
    id="rd-button-kroy9k83"
    target="_self"
    title=""
  >
    INCREVA-SE AGORA!
  </a> -->
  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="onClick('rd-section-krp6zg6c')" 
    id="rd-button-kroy9k83" 
    title=""
  >
    INCREVA-SE AGORA!
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kroy6x4x {
      min-height: 50px;
    }

    #rd-row-kroy6x4x .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kroy6x4x.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kroy6x4x" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kroy6x4y {
      flex: 12 0 0;
    }

    #rd-column-kroy6x4y > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kroy6x4y > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kroy6x4y" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kroy6x4z {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kroy6x4z" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kroy7a8d {
      
          background-color: #1abc9c;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kroy7a8d > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kroy7a8d" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp15876 {
      min-height: 50px;
    }

    #rd-row-krp15876 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp15876.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp15876" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp15877 {
      flex: 12 0 0;
    }

    #rd-column-krp15877 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp15877 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp15877" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp159tm {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp159tm" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kroy7a8e {
      min-height: 50px;
    }

    #rd-row-kroy7a8e .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kroy7a8e.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kroy7a8e" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kroy7a8f {
      flex: 12 0 0;
    }

    #rd-column-kroy7a8f > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kroy7a8f > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kroy7a8f" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krqo4jr8 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 859px;
    }

    #rd-image-krqo4jr8 a,
    #rd-image-krqo4jr8 img {
      width: 859px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krqo4jr8 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krqo4jr8" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$8zxd75hwekm" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp15cx9 {
      min-height: 50px;
    }

    #rd-row-krp15cx9 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp15cx9.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp15cx9" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp15cxa {
      flex: 12 0 0;
    }

    #rd-column-krp15cxa > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp15cxa > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp15cxa" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp15cxb {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp15cxb" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp1a5v6 {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp1a5v6 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp1a5v6" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1a7z1 {
      min-height: 50px;
    }

    #rd-row-krp1a7z1 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1a7z1.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1a7z1" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1a7z2 {
      flex: 12 0 0;
    }

    #rd-column-krp1a7z2 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1a7z2 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1a7z2" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp1a9c6 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp1a9c6" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1a5v7 {
      min-height: 50px;
    }

    #rd-row-krp1a5v7 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1a5v7.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1a5v7" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1a5v8 {
      flex: 12 0 0;
    }

    #rd-column-krp1a5v8 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1a5v8 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1a5v8" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp1aeik {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp1aeik" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 28px;">COMO FUNCIONARÁ O SIMULADO NACIONAL ENEM</strong></p><p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 28px;">DO NOTA MÁXIMA?</strong></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp9mx15 {
      min-height: 50px;
    }

    #rd-row-krp9mx15 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp9mx15.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp9mx15" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9mx16 {
      flex: 12 0 0;
    }

    #rd-column-krp9mx16 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9mx16 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9mx16" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9mx17 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9mx17" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1chxo {
      min-height: 50px;
    }

    #rd-row-krp1chxo .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1chxo.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1chxo" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1chxp {
      flex: 4 0 0;
    }

    #rd-column-krp1chxp > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1chxp > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1chxp" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp1gqie {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp1gqie a,
    #rd-image-krp1gqie img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp1gqie {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp1gqie" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$q5bh8cuyv67" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp1gvi8 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp1gvi8" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 20px; font-family: Open Sans, sans-serif;">1º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqoa06v {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqoa06v" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 20px; font-family: Open Sans, sans-serif;">Inscrição</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9fms8 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9fms8" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif;">Qualquer aluno que queira testar os seus conhecimentos para o ENEM poderá participar deste simulado completo e objetivo, que terá 180 questões. Basta fazer o cadastro no  </span>
    <a (click)="onClick('rd-section-krp6zg6c')" rel="noopener noreferrer" target="_self" title="" style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(26, 188, 156);"><strong>formulário desta página</strong></a>
 <!--    <a href="#rd-section-krp6zg6c" rel="noopener noreferrer" target="_self" title="" style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(26, 188, 156);"><strong>formulário desta página</strong></a> -->
    
    <span style="font-size: 16px; font-family: Open Sans, sans-serif;"> gratuitamente!</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9nwhl {
      flex: 4 0 0;
    }

    #rd-column-krp9nwhl > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9nwhl > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9nwhl" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp9nwhm {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp9nwhm a,
    #rd-image-krp9nwhm img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp9nwhm {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp9nwhm" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$ifrox0yvydc" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqoaep4 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqoaep4" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">2º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9nwhn {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9nwhn" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">Acesso à plataforma</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9nwho {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9nwho" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 16px;">Ao fazer a sua inscrição, você receberá o passo a passo em seu e-mail para conseguir acessar as aulas ao vivo e os simulados. Além disso,  </span><strong style="font-family: Open Sans, sans-serif; font-size: 16px; color: rgb(26, 188, 156);">você também receberá materiais extras</strong><span style="font-family: Open Sans, sans-serif; font-size: 16px;">, como listas de exercícios e uma cartilha explicando todos os detalhes da prova do ENEM.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9q5yi {
      flex: 4 0 0;
    }

    #rd-column-krp9q5yi > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9q5yi > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9q5yi" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp9q5yj {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp9q5yj a,
    #rd-image-krp9q5yj img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp9q5yj {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp9q5yj" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$jsgin1ns9zm" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqoagqw {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqoagqw" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">3º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9q5yk {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9q5yk" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 20px; font-family: Open Sans, sans-serif;">Aulas ao vivo</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9q5yl {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9q5yl" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 16px;">As aulas ao vivo serão disponibilizadas  </span><strong style="font-family: Open Sans, sans-serif; font-size: 16px; color: rgb(26, 188, 156);">exclusivamente para os alunos inscritos no simulado</strong><span style="font-family: Open Sans, sans-serif; font-size: 16px;">, nas semanas que precedem as provas. O link será disponibilizado dentro da nossa plataforma. Serão 3 aulas por dia, no período noturno, com tempo total de 3h (1h cada aula).</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp9shot {
      min-height: 50px;
    }

    #rd-row-krp9shot .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp9shot.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp9shot" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9shou {
      flex: 4 0 0;
    }

    #rd-column-krp9shou > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9shou > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9shou" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp9shov {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp9shov a,
    #rd-image-krp9shov img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp9shov {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp9shov" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$dsbi5gwf4oc" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqoc47k {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqoc47k" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">4º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9show {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9show" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">Provas</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9shox {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9shox" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(0, 0, 0); font-size: 16px; font-family: Open Sans, sans-serif;">As provas acontecerão dentro da nossa plataforma on-line, com</span><span style="font-size: 16px; font-family: Open Sans, sans-serif;"> </span><strong style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(26, 188, 156);">liberação nos dias 28 de agosto e 04 de setembro</strong><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);">, exatamente às 13h30min. As inscrições são gratuitas e somente os alunos inscritos terão acesso à plataforma e, portanto, às provas.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9shoy {
      flex: 4 0 0;
    }

    #rd-column-krp9shoy > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9shoy > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9shoy" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp9shoz {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp9shoz a,
    #rd-image-krp9shoz img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp9shoz {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp9shoz" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$dt57m09gm0r" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqoci5z {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqoci5z" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 20px; font-family: Open Sans, sans-serif;">5º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9shp0 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9shp0" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(0, 0, 0); font-size: 20px; font-family: Open Sans, sans-serif;">Boletim de desempenho</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9shp1 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9shp1" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(0, 0, 0);">Aproximadamente, 48h após a finalização de cada prova, dentro da plataforma, será liberado o resultado individual de cada participante. Os alunos poderão conferir as questões que acertaram e as alternativas corretas das questões que erraram. O gabarito com as questões comentadas poderá ser encontrado em nosso site no decorrer da semana.</span></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp9shp2 {
      flex: 4 0 0;
    }

    #rd-column-krp9shp2 > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp9shp2 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp9shp2" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp9shp3 {
      margin-top: 25px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 150px;
    }

    #rd-image-krp9shp3 a,
    #rd-image-krp9shp3 img {
      width: 150px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp9shp3 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp9shp3" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627564956/$uw8cwcc42w" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqocrv3 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 20px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqocrv3" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">6º</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9shp4 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9shp4" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; font-size: 20px;">Premiações</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp9shp5 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 450px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp9shp5" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 16px; color: rgb(0, 0, 0);">A premiação física, descrita em detalhes no edital e logo abaixo, será para os 3 primeiros colocados do simulado. Os alunos classificados entre 4º e 10º lugar receberão descontos, que poderão ser utilizados em qualquer um de nossos cursos Semiextensivos.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1c0ry {
      min-height: 50px;
    }

    #rd-row-krp1c0ry .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1c0ry.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1c0ry" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1c0rz {
      flex: 12 0 0;
    }

    #rd-column-krp1c0rz > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1c0rz > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1c0rz" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp1c0s0 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp1c0s0" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krxg8l4u {
      min-height: 50px;
    }

    #rd-row-krxg8l4u .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krxg8l4u.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krxg8l4u" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krxg8l4v {
      flex: 12 0 0;
    }

    #rd-column-krxg8l4v > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krxg8l4v > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krxg8l4v" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krxg8qhj {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 623px;
    }

    #rd-image-krxg8qhj a,
    #rd-image-krxg8qhj img {
      width: 623px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krxg8qhj {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krxg8qhj" class="bricks--component bricks--component-image rd-image">
  
    <a
      href="https://docs.google.com/document/d/1v3V5vnFa7LNLPgKnVt1XuBIaj21Erg7s672-e_HUdr8/edit?usp=sharing"
      target="_blank"
      title="Edital completo"
    >
          <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$z8cq2ovvlue" alt="">

    </a>
  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krxg8n5i {
      min-height: 50px;
    }

    #rd-row-krxg8n5i .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krxg8n5i.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krxg8n5i" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krxg8n5j {
      flex: 12 0 0;
    }

    #rd-column-krxg8n5j > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krxg8n5j > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krxg8n5j" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krxg8n5k {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krxg8n5k" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1kvl3 {
      min-height: 50px;
    }

    #rd-row-krp1kvl3 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1kvl3.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1kvl3" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1kvl4 {
      flex: 12 0 0;
    }

    #rd-column-krp1kvl4 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1kvl4 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1kvl4" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-krp1l1x4 {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krp1l1x4 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(26,188,156,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      height: 49px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 223px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krp1l1x4 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button" 
    (click)="onClick('rd-button-krp1l1x4')"
    id="rd-button-krp1l1x4"
    target="_self"
    title=""
  >
    QUERO PARTICIPAR!
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp1kxun {
      min-height: 50px;
    }

    #rd-row-krp1kxun .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp1kxun.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp1kxun" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp1kxuo {
      flex: 12 0 0;
    }

    #rd-column-krp1kxuo > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp1kxuo > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp1kxuo" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp1kxup {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp1kxup" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp5hc00 {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$u11xih3b4sg), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 50%;
            background-position-y: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp5hc00 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp5hc00" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5hc01 {
      min-height: 50px;
    }

    #rd-row-krp5hc01 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5hc01.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5hc01" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5hc02 {
      flex: 12 0 0;
    }

    #rd-column-krp5hc02 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5hc02 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5hc02" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5hc03 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5hc03" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5hc04 {
      min-height: 50px;
    }

    #rd-row-krp5hc04 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5hc04.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5hc04" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5hc05 {
      flex: 12 0 0;
    }

    #rd-column-krp5hc05 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5hc05 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5hc05" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5hc06 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5hc06" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 28px;">PREMIAÇÃO</strong></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqprg2o {
      min-height: 50px;
    }

    #rd-row-krqprg2o .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqprg2o.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqprg2o" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqpvt3u {
      flex: 12 0 0;
    }

    #rd-column-krqpvt3u > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqpvt3u > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqpvt3u" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krqq4s21 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 774px;
    }

    #rd-image-krqq4s21 a,
    #rd-image-krqq4s21 img {
      width: 774px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krqq4s21 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krqq4s21" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1627666476/$pmq09ohfyde" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqq7f9a {
      min-height: 50px;
    }

    #rd-row-krqq7f9a .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqq7f9a.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqq7f9a" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqq7f9b {
      flex: 12 0 0;
    }

    #rd-column-krqq7f9b > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqq7f9b > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqq7f9b" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqq7f9c {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto24px;
      max-width: 675px;
      margin-top: 30px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqq7f9c" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 18px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Os alunos classificados entre 4º e 10º lugar receberão descontos, que poderão ser utilizados em qualquer um de nossos cursos Semiextensivos!</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqq9k5c {
      min-height: 50px;
    }

    #rd-row-krqq9k5c .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqq9k5c.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqq9k5c" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqq9k5d {
      flex: 2 0 0;
    }

    #rd-column-krqq9k5d > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqq9k5d > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqq9k5d" class="bricks--column rd-column">
  <div>
    
  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krqqbt4g {
      flex: 4 0 0;
    }

    #rd-column-krqqbt4g > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqqbt4g > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqqbt4g" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqqbt4h {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 393px;
      margin-top: 30px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqqbt4h" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="background-color: rgb(233, 22, 85); font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> 4º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 70%</strong></p><p style="text-align: center;"><strong style="background-color: rgb(246, 193, 78); font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> 5º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 50%</strong></p><p style="text-align: center;"><strong style="background-color: rgb(26, 188, 156); font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> 6º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 40%</strong></p><p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(88, 26, 179); font-size: 28px; background-color: rgb(255, 255, 255);"> 7º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 30%</strong></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krqqbnif {
      flex: 4 0 0;
    }

    #rd-column-krqqbnif > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqqbnif > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqqbnif" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqqd68l {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 393px;
      margin-top: 30px;
      margin-bottom: 30px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqqd68l" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(88, 26, 179); font-size: 28px; background-color: rgb(255, 255, 255);"> 8º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 20%</strong></p><p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(88, 26, 179); font-size: 28px; background-color: rgb(255, 255, 255);"> 9º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 20%</strong></p><p style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; color: rgb(88, 26, 179); font-size: 28px; background-color: rgb(255, 255, 255);"> 10º LUGAR: </strong><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 28px;"> bolsa de 20%</strong></p>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krqqbwlb {
      flex: 2 0 0;
    }

    #rd-column-krqqbwlb > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqqbwlb > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqqbwlb" class="bricks--column rd-column">
  <div>
    
  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5hc0b {
      min-height: 50px;
    }

    #rd-row-krp5hc0b .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5hc0b.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5hc0b" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5hc0c {
      flex: 12 0 0;
    }

    #rd-column-krp5hc0c > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5hc0c > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5hc0c" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    a#rd-button-krqqj38n {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krqqj38n {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: #1abc9c;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 25px;
      margin-top: 25px;
      max-width: 236px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krqqj38n {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="onClick('rd-section-krp6zg6c')"
    id="rd-button-krqqj38n"
    target="_self"
    title=""
  >
    QUERO GANHAR!
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqqizjr {
      min-height: 50px;
    }

    #rd-row-krqqizjr .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqqizjr.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqqizjr" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqqizjt {
      flex: 12 0 0;
    }

    #rd-column-krqqizjt > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqqizjt > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqqizjt" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqqizju {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqqizju" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp5gfzb {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp5gfzb > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp5gfzb" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5gfzc {
      min-height: 50px;
    }

    #rd-row-krp5gfzc .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5gfzc.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5gfzc" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5gfzd {
      flex: 12 0 0;
    }

    #rd-column-krp5gfzd > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5gfzd > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5gfzd" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5gfze {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5gfze" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5gfzf {
      min-height: 50px;
    }

    #rd-row-krp5gfzf .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5gfzf.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5gfzf" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5gfzk {
      flex: 6 0 0;
    }

    #rd-column-krp5gfzk > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5gfzk > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5gfzk" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp5gfzl {
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 345px;
    }

    #rd-image-krp5gfzl a,
    #rd-image-krp5gfzl img {
      width: 345px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp5gfzl {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp5gfzl" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$tgvq7oev8c9" alt="">

  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5gfzg {
      flex: 6 0 0;
    }

    #rd-column-krp5gfzg > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5gfzg > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5gfzg" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5gfzh {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5gfzh" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><strong style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 28px;">BÔNUS</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5gfzi {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5gfzi" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><strong style="color: rgb(78, 78, 78); font-size: 18px; font-family: Open Sans, sans-serif;">Materiais em PDF para ajudar na sua preparação para o ENEM!</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqqm5zx {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqqm5zx" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">Além de toda a preparação que você receberá ao se inscrever, com as aulas ao vivo e simulados, você ainda garantirá </span><strong style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">UMA CARTILHA</strong><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;"> com dados e estatísticas dos últimos 10 anos da prova do ENEM para você saber exatamente o que o aguarda e uma </span><strong style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">LISTA DE EXERCÍCIOS</strong><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;"> com as 117 questões mais difíceis da prova.</span></p><p style="text-align: left;"><br></p><p style="text-align: left;"><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">Já será meio caminho andado rumo à sua aprovação!</span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-krp5gfzj {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krp5gfzj {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: flex-start;
      background-color: rgba(26,188,156,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 236px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krp5gfzj {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="onClick('rd-section-krp6zg6c')"
    id="rd-button-krp5gfzj"
    target="_self"
    title=""
  >
    QUERO MEU BÔNUS!
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5gfzm {
      min-height: 50px;
    }

    #rd-row-krp5gfzm .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5gfzm.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5gfzm" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5gfzn {
      flex: 12 0 0;
    }

    #rd-column-krp5gfzn > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5gfzn > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5gfzn" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5gfzo {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5gfzo" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krqpm2h6 {
      
          background-color: rgba(241,241,241,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krqpm2h6 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krqpm2h6" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krqpm2h7 {
      min-height: 50px;
    }

    #rd-row-krqpm2h7 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqpm2h7.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqpm2h7" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqpm2h8 {
      flex: 12 0 0;
    }

    #rd-column-krqpm2h8 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqpm2h8 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqpm2h8" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqpm2h9 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqpm2h9" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqpm2ha {
      min-height: 50px;
    }

    #rd-row-krqpm2ha .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqpm2ha.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqpm2ha" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqpm2hb {
      flex: 5 0 0;
    }

    #rd-column-krqpm2hb > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqpm2hb > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqpm2hb" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqpm2hc {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqpm2hc" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><strong style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 28px;">REDAÇÃO</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqpm2hd {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 700px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqpm2hd" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><strong style="font-family: Open Sans, sans-serif; font-size: 15px; color: rgb(78, 78, 78);">ESTE SIMULADO GRATUITO NÃO TERÁ PROVA DE REDAÇÃO!</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqqtyak {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 900px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqqtyak" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left;"><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">Maaaaas, caso você sinta que esteja precisando praticar a sua escrita até a data da prova, poderá adquirir algum de nossos cursos e ter à sua disposição a </span><strong style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">correção de até 5 REDAÇÕES MENSAIS</strong><span style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">, para treinar sempre que quiser, não somente para o ENEM mas </span><strong style="color: rgb(78, 78, 78); font-size: 16px; font-family: Open Sans, sans-serif;">para qualquer vestibular do Brasil!</strong></p>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-krqpm2he {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krqpm2he {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: flex-start;
      background-color: rgba(233,22,85,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 18px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 236px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krqpm2he {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.notamaxima.com.br/"
    id="rd-button-krqpm2he"
    target="_blank"
    title=""
  >
    QUERO AS CORREÇÕES!
  </a>


  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krqpm2hf {
      flex: 7 0 0;
    }

    #rd-column-krqpm2hf > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqpm2hf > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqpm2hf" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krqpm2hg {
      margin-top: 20px;
      margin-bottom: 20px;
      align-self: center;
      max-width: 401px;
    }

    #rd-image-krqpm2hg a,
    #rd-image-krqpm2hg img {
      width: 401px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krqpm2hg {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krqpm2hg" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$e42hftf9rdv" alt="">

  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqpm2hh {
      min-height: 50px;
    }

    #rd-row-krqpm2hh .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqpm2hh.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqpm2hh" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqpm2hi {
      flex: 12 0 0;
    }

    #rd-column-krqpm2hi > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqpm2hi > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqpm2hi" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqpm2hj {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqpm2hj" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp5iif3 {
      
          background-color: #1abc9c;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp5iif3 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp5iif3" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5iif4 {
      min-height: 50px;
    }

    #rd-row-krp5iif4 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5iif4.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5iif4" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5iif5 {
      flex: 12 0 0;
    }

    #rd-column-krp5iif5 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5iif5 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5iif5" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5iif6 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5iif6" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5iif7 {
      min-height: 50px;
    }

    #rd-row-krp5iif7 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5iif7.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5iif7" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5iif8 {
      flex: 12 0 0;
    }

    #rd-column-krp5iif8 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5iif8 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5iif8" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5iif9 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5iif9" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 32px;">Aulas preparatórias AO VIVO e GRATUITAS!</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5jlpg {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto24px;
      max-width: 641px;
      margin-top: 15px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5jlpg" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">﻿Além dos simulados, você ainda contará </span><strong style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">3h de aulas diárias AO VIVO, GRATUITAS e exclusivas para os inscritos no simulado! </strong><span style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Serão duas semanas de resumos, dicas, macetes, técnicas e diversos outros conteúdos que nossos professores trarão para garantir que você esteja preparado para testar os seus conhecimentos!</span></p>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-krp5kcjq {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krp5kcjq {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(255,255,255,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 5px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #10a98b;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      height: 52px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 311px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krp5kcjq {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    (click)="onClick('rd-section-krp6zg6c')"
    id="rd-button-krp5kcjq"
    target="_self"
    title=""
  >
    QUERO TER ESSA PREPARAÇÃO!
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5iifa {
      min-height: 50px;
    }

    #rd-row-krp5iifa .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5iifa.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5iifa" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5iifb {
      flex: 12 0 0;
    }

    #rd-column-krp5iifb > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5iifb > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5iifb" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5iifc {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5iifc" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp5orny {
      min-height: 50px;
    }

    #rd-row-krp5orny .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp5orny.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp5orny" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp5ornz {
      flex: 6 0 0;
    }

    #rd-column-krp5ornz > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp5ornz > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp5ornz" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krp5rzzd {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1627666476/$xalzxcst2g), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 0px;
      margin-bottom: 10px;
      max-width: 1100px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krp5rzzd" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5qkd1 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5qkd1" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 32px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">SEMANA 01</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krpbqteq {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krpbqteq" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Matérias</span></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Linguagens, Códigos e suas Tecnologias</strong></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Ciências Humanas e suas Tecnologias</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp5rzze {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp5rzze" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Aulas ao vivo</span></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">De 23/08 a 27/08</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krpbri21 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krpbri21" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Período das aulas</span></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Noturno</strong></p><p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">(será disponibilizado o cronograma específico</span></p><p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">somente para os inscritos)</span></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krpbtkj3 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krpbtkj3" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Prova</span></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Dia 28/08, às 13h30min</strong></p><p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">(dentro da nossa plataforma on-line)</span></p>
</div>

</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krqouf2m {
      flex: 6 0 0;
    }

    #rd-column-krqouf2m > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqouf2m > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqouf2m" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqouf2n {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1627666476/$xalzxcst2g), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: scroll;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 0px;
      margin-bottom: 10px;
      max-width: 1100px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqouf2n" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqouf2o {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqouf2o" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 32px;">SEMANA 02</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqouf2p {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqouf2p" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Matérias</span></p><p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Matemática e suas Tecnologias</strong></p><p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Ciências da Natureza e suas Tecnologias</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqouf2q {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqouf2q" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Aulas ao vivo</span></p><p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">De 30/08 a 03/09</strong></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqouf2r {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqouf2r" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Período das aulas</span></p><p style="text-align: center;"><strong style="font-size: 21px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Noturno</strong></p><p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">(será disponibilizado o cronograma específico</span></p><p style="text-align: center;"><span style="font-size: 16px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">somente para os inscritos)</span></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqouf2s {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 1000px;
      margin-top: 10px;
      margin-bottom: 20px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqouf2s" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Prova</span></p><p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 21px;">Dia 04/09, às 13h30min</strong></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">(dentro da nossa plataforma on-line)</span></p>
</div>

</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp70iuk {
      min-height: 50px;
    }

    #rd-row-krp70iuk .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp70iuk.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp70iuk" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp70iul {
      flex: 12 0 0;
    }

    #rd-column-krp70iul > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp70iul > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp70iul" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp70ium {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp70ium" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp6zg6c {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp6zg6c > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp6zg6c" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp6zg6d {
      min-height: 50px;
    }

    #rd-row-krp6zg6d .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp6zg6d.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp6zg6d" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp6zg6e {
      flex: 12 0 0;
    }

    #rd-column-krp6zg6e > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp6zg6e > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp6zg6e" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp6zg6f {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp6zg6f" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp6zg6g {
      min-height: 617px;
    }

    #rd-row-krp6zg6g .bricks--column > div {
      min-height: 617px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp6zg6g.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp6zg6g" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp6zg6h {
      flex: 12 0 0;
    }

    #rd-column-krp6zg6h > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp6zg6h > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp6zg6h" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-joq3m2m2 {
      
          background-color: #581ab3;
        
      border-bottom-style: none;
      border-color: #4b5677;
      border-left-style: none;
      border-radius: 12px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
      padding-left: 25px;
      min-height: auto0px;
      margin-top: 0px;
      margin-bottom: 0px;
      max-width: 581px;
      align-self: center;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-joq3m2m2" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m3 {
      border-bottom-style: none;
      border-color: #62e7fe;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-38px;
      max-width: 500px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2m3" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 34px; font-family: Source Sans Pro, sans-serif; color: rgb(255, 255, 255);">Inscreva-se gratuitamente e teste seus conhecimentos!</strong></h2>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2m4 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-23px;
      max-width: 519px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2m4" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; color: rgb(255, 255, 255); font-size: 18px;">Preencha o formulário abaixo, participe do simulado, teste seus conhecimentos e tenha a chance de ganhar prêmios!</span></p>
</div>




<style type="text/css" rel="stylesheet">
  
    #rd-form-joq3m2m5 {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 460px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    #rd-form-joq3m2m5 .bricks-form__fieldset {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    #rd-form-joq3m2m5 .bricks-form__field {
      margin-bottom: 15px;
      padding: 0;
      width: 100.0%;
    }

    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__privacy,
    #rd-form-joq3m2m5 .bricks-form__field.bricks-form__terms {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
      #rd-form-joq3m2m5 .bricks-form__field {
        width: 100%;
        padding: 0;
      }
    }

    #rd-form-joq3m2m5 .bricks-form__static {
      color: #FFFFFF;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 .bricks-form__static a {
      color: #FFFFFF;
      text-decoration: underline;
    }

    #rd-form-joq3m2m5 .bricks-form__label {
      color: #FFFFFF;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }

    #rd-form-joq3m2m5 label.error {
      color: #DC3543;
      font-size: 12px;
      line-height: 1.1;
      padding: 2px 0 0 4px;
    }

    #rd-form-joq3m2m5 .bricks-form__label.always-visible,
    #rd-form-joq3m2m5 .bricks-form__field__option .bricks-form__label {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .bricks-form__input {
      color: #555555;
      background-color: #ffffff;
      border-bottom-style: none;
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      border-color: #a73131;
      border-radius: 4px;
      border-width: 0px;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      
          box-shadow: inset 0px 2px 3px #ddd;
          -webkit-box-shadow: inset 0px 2px 3px #ddd;
          -moz-box-shadow: inset 0px 2px 3px #ddd;
        
    }

    #rd-form-joq3m2m5 .bricks-form__input::placeholder {
      color: transparent;
    }

    #rd-form-joq3m2m5 .bricks-form__input.select2-container {
      padding: 0 !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice {
      height: 32px !important;
      line-height: 32px !important;

      color: #555555 !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-size: 16px !important;
      background: #ffffff;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow b {
      background-position: 0 5px !important;
    }

    #rd-form-joq3m2m5 .select2-container .select2-choice .select2-arrow {
      background: none;
      border: none;
    }

    #rd-form-joq3m2m5 .bricks-form__input:not(textarea) {
      height: 32px;
    }

    #rd-form-joq3m2m5 .bricks-form__submit {
      display: flex;
      flex-direction: column;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-style: none;
    }
    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen {
      margin-right: 0px;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .select2-container > .select2-choice > .select2-chosen > .flag {
      vertical-align: middle;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone {
      width: calc(100% - 55px);
      display: inline-block;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-country {
      display: inline-block;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group {
      display: flex;
    }

    #rd-form-joq3m2m5 .with-select-flags > .phone-input-group > .phone-country {
      width: 55px;
      display: inline-block;
    }
  
</style>


<div
  id="rd-form-joq3m2m5"
  class="bricks--component bricks-form rd-form"
>
  

  <form
    id="conversion-form" 
    [formGroup] = "profileForm"
    (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    action="https://event-api.rdstation.com.br/v2/conversions"
    data-typed-fields="1"
    data-lang="pt-BR"
    data-asset-action = "aHR0cHM6Ly9jb250ZXVkb3Mubm90YW1heGltYS5jb20uYnIvc2ltdWxhZG8t
bmFjaW9uYWwtZW5lbS1jb25maXJtYWNhbw==
"
  >

    <input type="hidden" name="token_rdstation" value="a2c50fa21b1241f64043a7de500dc820">
    <input type="hidden" name="conversion_identifier" value="simulado-nacional-enem">
    <input type="hidden" name="internal_source" value="2">
    <input type="hidden" name="_rd_experiment_content_id" value="">
    <input type="hidden" name="c_utmz" value=""/>
    <input type="hidden" name="traffic_source" value=""/>
    <input type="hidden" name="privacy_data[browser]" id="privacy_data_browser" />

    

    


    <div
  class="bricks-form__fieldset"
  data-progressive-profiling=""
>
  <div class="bricks-form__field">
  <label for="rd-text_field-krxgmfak" class="bricks-form__label">
    Nome*
  </label>
  <input
    id="rd-text_field-krxgmfak"
    (change)= "changeField()"
    formControlName="nome"
    class="bricks-form__input required js-text js-field-name"
    name="name"
    type="text"
    data-use-type="STRING"
    placeholder="Nome *"
    required="required"
  >
</div>
<div class="bricks-form__field">
  <label for="rd-email_field-krxgmfal" class="bricks-form__label">
    Email*
  </label>
  <input
    id="rd-email_field-krxgmfal" 
    (change)= "changeField()"
    formControlName="email"
    class="bricks-form__input required js-email js-field-email"
    name="email"
    type="email"
    data-use-type="STRING"
    placeholder="Email *"
    required="required"
  >
</div>
<div class="bricks-form__field with-select-flags">
  <label for="rd-phone_field-krxgmfam" class="bricks-form__label">
    Telefone*
  </label>
  <div class="phone-input-group">
    
      <div class="form-control phone-country" data-type="countries"></div>
      <div class="country-field" value="BR"></div>
    

    <input
      id="rd-phone_field-krxgmfam" 
      (change)= "changeField()"
      formControlName="telefone"
      class="bricks-form__input required phone js-phone js-field-personal_phone"
      name="personal_phone"
      data-input-mask="INTERNATIONAL_MASK"
      data-use-type="STRING"
      type="tel"
      placeholder="Telefone *"
      required="required"
    >
  </div>
</div>
<div class="bricks-form__field">
  <label for="rd-text_field-krxgmfan" class="bricks-form__label">
    Você faz algum cursinho? Se sim, qual? Se não, pretende fazer?*
  </label>
  <input
    id="rd-text_field-krxgmfan"
    (change)= "changeField()"
    formControlName="alunode"
    class="bricks-form__input required js-text js-field-cf_voce_faz_algum_cursinho_se_sim_qual_se_nao_pretende"
    name="custom_fields[1470512]"
    type="text"
    data-use-type="STRING"
    placeholder="Você faz algum cursinho? Se sim, qual? Se não, pretende fazer? *"
    required="required"
  >
</div>


<div class="bricks-form__field " >
  
  <div class="bricks-form__field__option">
    <label class="bricks-form__label">
      <input
        id="rd-checkbox_field-krxgmfao"
        name="custom_fields[1385178]"
        type="checkbox"
        value="1"
        (change)= "changeField()"
        data-use-type="STRING"
        data-always-visible=""
        class="bricks-form__field__option__input required js-field-cf_li_e_concordo_com_o_edital_da_campanha"
        required="required"
      >
      Li e concordo com o edital da campanha*
    </label>
  </div>
</div>
<div class="bricks-form__field">
  <label for="captcha" class="bricks-form__label">
    <span id="math_expression">1 + 1 = ?</span>
  </label>
  <input
    id="captcha"
    (change)= "changeField()"
    class="bricks-form__input math rd-bricks-captcha"
    name="captcha"
    type="text"
    placeholder="1 + 1 = ?"
  >
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-joq3m2m7 {
          display: flex;
      flex-direction: column;
    }

    #rd-button-joq3m2m7 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(244,195,0,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 10px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Ubuntu, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      height: 60px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 500px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    #rd-button-disabled {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(244,195,0,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 10px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Ubuntu, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      height: 60px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 500px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-joq3m2m7 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
      #rd-button-disabled {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
     
    }
  
</style>


    <div class="bricks-form__submit" *ngIf="buttonActive">
        <button
        class="bricks--component bricks--component-button rd-button"
        id="rd-button-joq3m2m7"
        >
        QUERO FAZER O SIMULADO!
        </button>
    </div>
    <div class="bricks-form__submit" *ngIf="!buttonActive">
        <button
        class="bricks--component bricks--component-button rd-button"
        id="rd-button-disabled"
        >
        QUERO FAZER O SIMULADO!
        </button>
    </div>



    <div style="position: fixed; left: -5000px">
      <input type="text" name="emP7yF13ld" readonly autocomplete="false" tabindex="-1" value="">
      <input type="text" name="sh0uldN07ch4ng3" readonly autocomplete="false" tabindex="-1" value="should_not_change">
    </div>

  </form>
</div>
<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.validate/1.9/jquery.validate.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/jquery.form/2.02/jquery.form.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/jquery.cookie.v1.4.1.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/lead-tracking/stable/plugins/math.uuid.v1.4.min.js"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps.min.js?t=1628165451"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-progressive-profiling.min.js?t=1628165451"></script>
<script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/rd/stable/rdlps-autofill.min.js?t=1628165451"></script>

<script type="text/javascript">
    var origConversionSuccess = window.conversionSuccess;
    const lpConversionForm = document.getElementById('conversion-form')

    lpConversionForm.addEventListener('submit', event => {
      if (lpConversionForm.dataset.assetAction && $(lpConversionForm).valid()) {
        const input = document.createElement('input')

        input.type = 'hidden'
        input.name = 'redirect_to'
        input.value = atob(lpConversionForm.dataset.assetAction)

        lpConversionForm.appendChild(input);
      }
    })

    conversionSuccess = function(resp) {
      if (window.origConversionSuccess) window.origConversionSuccess(resp);

      
        if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
          try { _gaq.push(['_trackPageview', '/simulado-nacional-enem/conversao']); } catch(err) { }
          try { ga('send', 'pageview', '/simulado-nacional-enem/conversao'); } catch(err) { }
        }
      

      

      

      let redirectTo = (lpConversionForm.dataset.assetAction)
        ? atob(lpConversionForm.dataset.assetAction)
        : $("input[name='redirect_to']").val();

      if (redirectTo.length > 0) {
        top.location.href = redirectTo;
      }
    }
</script>

<script>
  document.querySelectorAll('input[name=emP7yF13ld],input[name=sh0uldN07ch4ng3]').forEach(function(element) {
    element.addEventListener('focus', function(e) {
      e.target.removeAttribute('readonly');
    });
  });
</script>


<style type="text/css" rel="stylesheet">
  
    #rd-text-krxgkjm0 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 459px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krxgkjm0" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 14px; color: rgb(255, 255, 255);">Confira o edital completo da campanha clicando </span><a href="https://docs.google.com/document/d/1v3V5vnFa7LNLPgKnVt1XuBIaj21Erg7s672-e_HUdr8/edit?usp=sharing" rel="noopener noreferrer" target="_blank" title="" style="font-family: Open Sans, sans-serif; font-size: 14px; color: rgb(255, 255, 255);"><strong>AQUI</strong></a></p>
</div>

</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp6zg6n {
      min-height: 50px;
    }

    #rd-row-krp6zg6n .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp6zg6n.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp6zg6n" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp6zg6o {
      flex: 12 0 0;
    }

    #rd-column-krp6zg6o > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp6zg6o > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp6zg6o" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp6zg6p {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp6zg6p" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp72xs4 {
      
          background-color: rgba(222,222,222,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp72xs4 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp72xs4" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp72xs5 {
      min-height: 50px;
    }

    #rd-row-krp72xs5 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp72xs5.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp72xs5" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp72xs6 {
      flex: 12 0 0;
    }

    #rd-column-krp72xs6 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp72xs6 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp72xs6" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp72xs7 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp72xs7" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp72xs8 {
      min-height: 50px;
    }

    #rd-row-krp72xs8 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp72xs8.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp72xs8" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp72xs9 {
      flex: 12 0 0;
    }

    #rd-column-krp72xs9 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp72xs9 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp72xs9" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp72xsa {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp72xsa" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 28px; font-family: Open Sans, sans-serif; color: rgb(78, 78, 78);">PERGUNTAS FREQUENTES</strong></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsvk52 {
      min-height: 50px;
    }

    #rd-row-krqsvk52 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsvk52.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsvk52" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsvk53 {
      flex: 12 0 0;
    }

    #rd-column-krqsvk53 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsvk53 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsvk53" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsvk55 {
      
          background-color: #7d7d7d;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsvk55" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsvk56 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 764px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsvk56" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 22px;">O que é o Nota Máxima?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsvk57 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsvk57" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><span style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 18px;">O Nota Máxima é um cursinho pré-vestibular on-line, que visa oferecer ao estudante o caminho para a aprovação através de uma trilha de aprendizagem, com aulas focadas nos conteúdos certeiros mais cobrados nos vestibulares do Brasil. Nossa equipe conta com mais de 25 anos de experiência nos principais vestibulares do país e, apesar de termos surgido em 2019, já impactamos mais de 200 mil alunos em todo território nacional, tanto de escolas públicas como de particulares.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsw5kz {
      min-height: 50px;
    }

    #rd-row-krqsw5kz .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsw5kz.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsw5kz" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsw5l0 {
      flex: 12 0 0;
    }

    #rd-column-krqsw5l0 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsw5l0 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsw5l0" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsw5l1 {
      
          background-color: rgba(125,125,125,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsw5l1" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsw5l2 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 880px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsw5l2" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Após efetuar meu cadastro em quanto tempo receberei o acesso à plataforma?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsw5l3 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsw5l3" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><span style="font-size: 18px; font-family: Open Sans, sans-serif; color: rgb(78, 78, 78);">Você receberá o acesso à plataforma em poucos minutos, através do e-mail cadastrado. Caso não encontre o e-mail de confirmação de inscrição em sua caixa de entrada, verifique a caixa de spam e lixo eletrônico. Também é importante checar se o e-mail que você cadastrou estava correto. Se preciso, refaça sua inscrição ou entre em contato conosco pelo e-mail: contato@notamaxima.com.br.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsx08s {
      min-height: 50px;
    }

    #rd-row-krqsx08s .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsx08s.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsx08s" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsx08t {
      flex: 12 0 0;
    }

    #rd-column-krqsx08t > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsx08t > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsx08t" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsx08u {
      
          background-color: rgba(125,125,125,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsx08u" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsx08v {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 880px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsx08v" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 22px;">Quanto tempo terei para fazer as provas?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsx08w {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsx08w" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><span style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 18px;">As provas seguirão o mesmo formato das provas objetivas do ENEM, para você sentir na pele como será no dia e já chegar preparado. Portanto, você terá o mesmo tempo do ENEM, 4 horas e 30 minutos para cada dia. Elas também serão liberadas exatamente às 13h30, então, se você se atrasar, estará perdendo tempo de prova. Em resumo: pratique como se estivesse no dia da prova do ENEM e não tenha medo de errar. Afinal, é bem melhor errar no simulado e já ter experiência para fazer certo no dia, não é? </span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsxxin {
      min-height: 50px;
    }

    #rd-row-krqsxxin .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsxxin.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsxxin" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsxxio {
      flex: 12 0 0;
    }

    #rd-column-krqsxxio > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsxxio > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsxxio" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsxxip {
      
          background-color: rgba(125,125,125,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsxxip" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsxxiq {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 880px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsxxiq" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Como saberei quem ganhou os prêmios?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsxxir {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsxxir" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><span style="font-size: 18px; font-family: Open Sans, sans-serif; color: rgb(78, 78, 78);">Os vencedores e suas notas serão divulgadas exclusivamente em nosso Instagram. Então, se você não nos segue, procura por @notamaxima e siga-nos para ficar por dentro do resultado!</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsyoke {
      min-height: 50px;
    }

    #rd-row-krqsyoke .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsyoke.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsyoke" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsyokf {
      flex: 12 0 0;
    }

    #rd-column-krqsyokf > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsyokf > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsyokf" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsyokg {
      
          background-color: rgba(125,125,125,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsyokg" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsyokh {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 880px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsyokh" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><strong style="color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; font-size: 22px;">Onde poderei ver minha nota?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsyoki {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsyoki" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: left; line-height: 1.5;"><span style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 18px;">Você poderá conferir sua nota, o que errou, o que acertou, bem como o gabarito, dentro da plataforma, 48 horas após o término da prova, ou seja, na segunda-feira posterior à mesma. Nós também enviaremos no e-mail de cada participante um arquivo em PDF com as questões da prova resolvidas e comentadas (com resposta em texto) pelos nossos professores, para que não fiquem dúvidas sobre as questões.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsz3mk {
      min-height: 50px;
    }

    #rd-row-krqsz3mk .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsz3mk.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsz3mk" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsz3ml {
      flex: 12 0 0;
    }

    #rd-column-krqsz3ml > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsz3ml > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsz3ml" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-box-krqsz3mm {
      
          background-color: rgba(125,125,125,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 20px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      min-height: -1px;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 1280px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  
</style>

<div id="rd-box-krqsz3mm" class="bricks--component bricks--component-box rd-box">
  
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsz3mn {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-32px;
      max-width: 880px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsz3mn" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(255, 255, 255);">Se eu perder o horário ou dia da prova, terei como refazê-la?</strong></p>
</div>

</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsz3mo {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1251px;
      margin-top: 0px;
      margin-bottom: 20px;
      align-self: flex-start;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsz3mo" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.5; text-align: left;"><span style="font-size: 18px; font-family: Open Sans, sans-serif; color: rgb(78, 78, 78);">Não. O objetivo deste simulado é passar a você a experiência real de fazer a prova do ENEM, para te preparar de verdade para esse dia tão esperado. Portanto, você só poderá realizar as provas nos dias estipulados.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krqsfcus {
      min-height: 50px;
    }

    #rd-row-krqsfcus .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krqsfcus.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krqsfcus" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krqsfcut {
      flex: 12 0 0;
    }

    #rd-column-krqsfcut > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krqsfcut > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krqsfcut" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krqsfcv3 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 743px;
      margin-top: 30px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krqsfcv3" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Open Sans, sans-serif; font-size: 20px;">Para saber todas as informações sobre o Simulado Nacional ENEM, leia nosso edital completo!</strong></p>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-krqsfcv4 {
          display: flex;
      flex-direction: column;
    }

    #rd-button-krqsfcv4 {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(26,188,156,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 19px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      height: 55px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 271px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-krqsfcv4 {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://docs.google.com/document/d/1v3V5vnFa7LNLPgKnVt1XuBIaj21Erg7s672-e_HUdr8/edit?usp=sharing"
    id="rd-button-krqsfcv4"
    target="_blank"
    title="Edital completo"
  >
    LER EDITAL COMPLETO
  </a>


  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp72xsf {
      min-height: 50px;
    }

    #rd-row-krp72xsf .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp72xsf.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp72xsf" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp72xsg {
      flex: 12 0 0;
    }

    #rd-column-krp72xsg > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp72xsg > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp72xsg" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp72xsh {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp72xsh" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-krp7h6m8 {
      
          background-color: rgba(255,255,255,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-krp7h6m8 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-krp7h6m8" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-krp7hkg7 {
      min-height: 50px;
    }

    #rd-row-krp7hkg7 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp7hkg7.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp7hkg7" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7hkg8 {
      flex: 12 0 0;
    }

    #rd-column-krp7hkg8 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7hkg8 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7hkg8" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp7hkg9 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp7hkg9" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp7h6mc {
      min-height: 50px;
    }

    #rd-row-krp7h6mc .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp7h6mc.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp7h6mc" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7h6md {
      flex: 12 0 0;
    }

    #rd-column-krp7h6md > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7h6md > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7h6md" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp7h6me {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp7h6me" class="bricks--component bricks--component-text rd-text">
  <h2 style="text-align: center;"><strong style="font-size: 22px; font-family: Open Sans, sans-serif; color: rgb(78, 78, 78); background-color: transparent;">FICOU COM ALGUMA OUTRA DÚVIDA OU QUER SABER MAIS SOBRE O NOTA MÁXIMA?</strong></h2>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp7h6mf {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp7h6mf" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><span style="font-size: 20px; font-family: Open Sans, sans-serif; color: rgb(74, 74, 74);">Entre em contato por meio dos nossos canais de comunicação.</span></p>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-krp7h6mg {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto0px;
      max-width: 1139px;
      margin-top: 0px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp7h6mg" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="font-size: 20px; font-family: Open Sans, sans-serif; color: rgb(74, 74, 74);">contato@notamaxima.com.br</strong></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp7jmlp {
      min-height: 50px;
    }

    #rd-row-krp7jmlp .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp7jmlp.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp7jmlp" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7jmlq {
      flex: 5 0 0;
    }

    #rd-column-krp7jmlq > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7jmlq > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7jmlq" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp7jvdi {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: flex-end;
      max-width: 50px;
    }

    #rd-image-krp7jvdi a,
    #rd-image-krp7jvdi img {
      width: 50px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp7jvdi {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp7jvdi" class="bricks--component bricks--component-image rd-image">
  
    <a
      href="https://www.facebook.com/onotamaxima"
      target="_blank"
      title=""
    >
          <img src="https://d335luupugsy2.cloudfront.net/cms/files/65920/1594748902/$bhpzx819e2" alt="">

    </a>
  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7l4ew {
      flex: 2 0 0;
    }

    #rd-column-krp7l4ew > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7l4ew > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7l4ew" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp7l4ex {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 50px;
    }

    #rd-image-krp7l4ex a,
    #rd-image-krp7l4ex img {
      width: 50px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp7l4ex {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp7l4ex" class="bricks--component bricks--component-image rd-image">
  
    <a
      href="https://www.instagram.com/notamaxima/"
      target="_blank"
      title=""
    >
          <img src="https://d335luupugsy2.cloudfront.net/cms/files/65920/1594748902/$p3apnai74z9" alt="">

    </a>
  
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7l546 {
      flex: 5 0 0;
    }

    #rd-column-krp7l546 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7l546 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7l546" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-krp7l547 {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: flex-start;
      max-width: 50px;
    }

    #rd-image-krp7l547 a,
    #rd-image-krp7l547 img {
      width: 50px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-krp7l547 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-krp7l547" class="bricks--component bricks--component-image rd-image">
  
    <a
      href="https://www.youtube.com/notamaxima"
      target="_blank"
      title=""
    >
          <img src="https://d335luupugsy2.cloudfront.net/cms/files/65920/1594748902/$hay9bbdbsg7" alt="">

    </a>
  
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-krp7h6ml {
      min-height: 50px;
    }

    #rd-row-krp7h6ml .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-krp7h6ml.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-krp7h6ml" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-krp7h6mm {
      flex: 12 0 0;
    }

    #rd-column-krp7h6mm > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-krp7h6mm > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-krp7h6mm" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-krp7h6mn {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-krp7h6mn" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2na {
      
          background-color: rgba(88,26,179,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2na > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2na" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2nb {
      min-height: 50px;
    }

    #rd-row-joq3m2nb .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2nb.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2nb" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2nc {
      flex: 12 0 0;
    }

    #rd-column-joq3m2nc > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 0px;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2nc > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2nc" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-jun4iss6 {
      margin-top: 30px;
      margin-bottom: 30px;
      align-self: center;
      max-width: 223px;
    }

    #rd-image-jun4iss6 a,
    #rd-image-jun4iss6 img {
      width: 223px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-jun4iss6 {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-jun4iss6" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1606314813/$p92hw95xxws" alt="">

  
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="2314217" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', uuid, 'auto');
      ga('send', 'pageview');

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);

      if (idsDataset.experimentContentId) {
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    window.addEventListener('load', function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    })
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
  </body>
</html>

