import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcocService } from 'src/app/concoc/concoc.service';

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-set2023-concoc-bc-ef2',
  templateUrl: './set2023-concoc-bc-ef2.component.html',
  styleUrls: ['./set2023-concoc-bc-ef2.component.scss']
})
export class Set2023ConcocBcEf2Component implements OnInit {

  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any;
  buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  public pv: any;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService)
  { 
    this.landRoute          = "set-2023-concoc-bc-ef2";
    this.urlRedirect        = "https://plataforma.cocbalneario.com.br/redirect";
    this.landRouteNavigate  = "./set-2023-concoc-confirmacao-prova";
    }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      //senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      alunode: new FormControl(''), 
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')
    
    });
    this.getLandClass();
  }
  getLandClass(){
    this.concocService.getLandRoute(this.landRoute)
    .subscribe(async res => {
        const response = res.body as ResponseApi; 
        if (!response.error) {
          this.landClasses = response.data;  
        }
    }, err => console.log("a")); 
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    var el    = (<HTMLInputElement>document.getElementById("rd-text_field-llvc1x3s")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {
    if(this.botaoEnviar){
      // TODO: Use EventEmitter with form value
      var data = this.profileForm.getRawValue(); 
      if(data.turmaqueentra == "5º ano"){
        this.pv = 'e55e2c254c3bc5465b5eabf69476ad2b';
      }else if(data.turmaqueentra == "6º ano"){
        this.pv = '5794ac470ad914502dcd8fa168a500c9';
      }else if(data.turmaqueentra == "7º ano"){
        this.pv = '7247b1fdc1e69428c68ab4c015cb5528';
      } else if(data.turmaqueentra == "8º ano"){
        this.pv = '0896b7e963a9d6382676b2f8580e6e7a';
      }  else if(data.turmaqueentra == "9º ano"){
        this.pv = '67341e487582ebd4de03e85fb605aa82';
      }       
      var jsondata = {
        name : data.nome,
        email: data.email, 
        pass: 'coc2024',   
        telefone: data.telefone,
        alunode :data.alunode,          
        colegio: data.turma, 
        turmaqueentra: data.turmaqueentra,
        urlRedirect: this.urlRedirect ,
        pv :this.pv
      } 
      this.concocService.registerSave(jsondata)
      .subscribe(async res => {
          const response = res.body as ResponseApi;
          if (!response.error) { 
              let url:any = response.data; 
              this.router.navigate( ['./set-2023-concoc-confirmacao-prova'], {queryParams:{ link : btoa(url.link) } })
          }
      }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  changeField(){
    var data = this.profileForm.getRawValue();
    console.log(data);
    if(data.nome != null && data.email != null &&
          data.telefone != null && data.turmaqueentra != null && data.alunode != null && data.captcha != null){ 
      this.buttonActive = true;
      this.botaoEnviar = true;  
    }
  }

  checkCaptcha(captcha,response){ 
    const captchaVal = captcha.split(" ");
    const value = Number(captchaVal[0])+ Number(captchaVal[2]);
    
    if(value === Number(response)){
      this.changeField();
    }
      
  }
  
  btnClick(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}