import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agradecimento-concoc-rds',
  templateUrl: './agradecimento-concoc-rds.component.html',
  styleUrls: ['./agradecimento-concoc-rds.component.scss']
})
export class AgradecimentoConcocRdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
