import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcocService } from '../../concoc/concoc.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ViewportScroller } from '@angular/common';
export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}

@Component({
  selector: 'app-simulado-nacional-enem',
  templateUrl: './simulado-nacional-enem.component.html',
  styleUrls: ['./simulado-nacional-enem.component.scss']
})
export class SimuladoNacionalEnemComponent implements OnInit {


  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any = [];
  public buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string;
  public landRouteNavigate:string;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService,
              private viewportScroller: ViewportScroller)
  {
    this.landRoute          = "simulado-nacional-enem";
    this.urlRedirect        = "https://sistema.notamaxima.com.br/redirect";
    this.landRouteNavigate  = "/simulado-nacional-enem-confirmacao";
  }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),//ok
      email: new FormControl(''),//ok
      senha: new FormControl(''),//ok
      turma: new FormControl(''),
      telefone: new FormControl(''),//ok
      colegio: new FormControl(''),
      alunode: new FormControl(''), //ok
      unidadequevaiestudar: new FormControl(''),
      nomeResponsavel: new FormControl(''),
      telefoneResponsavel: new FormControl(''),
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')

    });
    this.getLandClass();
  }
  onClick(elementId: string):void{
    this.viewportScroller.scrollToAnchor(elementId);
  }
  getLandClass(){
    this.concocService.getLandRoute(this.landRoute)
    .subscribe(async res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let result:any = response.data;

          if(result.length > 0){
            this.landClasses = response.data;
          }else{
            this.router.navigate( ["/"]);

          }

        }
    }, err => console.log("a"));
  }
  getLandClassSelect(landStr:string){

    landStr = (landStr == "Escola pública")? 'concoc-semi-balneario-publica':'concoc-semi-balneario-particular' ;
    this.concocService.getLandRoute(landStr)
    .subscribe(async res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

            this.landClasses = response.data;
        }
    }, err => console.log("a"));
  }

  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    console.info(turma.options[turma.selectedIndex].textContent);
    console.info(turma.options[turma.selectedIndex].value);
    var el    = (<HTMLInputElement>document.getElementById("turmanome")) ;
    el.value = turma.options[turma.selectedIndex].textContent;

  }
  async onSubmit() {

    if(this.botaoEnviar){

        // TODO: Use EventEmitter with form value
        var data = this.profileForm.getRawValue();
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: "enem2021",
          telefone: data.telefone,
          alunode :data.alunode,
          telefoneResponsavel :  data.telefoneResponsavel,
          nomeResponsavel :      data.nomeResponsavel,
          unidadequevaiestudar : data.unidadequevaiestudar,
          colegio: data.colegio,
          urlRedirect: this.urlRedirect ,
          pv :["bb0f8c8f9fd86bb83c044ac512723d78","9fb4b9b172e226ba242c1ecaf6a6200b"]//data.turma
        }
        this.concocService.registerUserSaveMulti(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                let url:any = response.data;
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( [this.landRouteNavigate ], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a"));
    }
  }

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

  changeField(){
    var data = this.profileForm.getRawValue();

    if(data.nome != null && data.email != null &&  data.telefone != null  && data.alunode != null ){
      this.buttonActive = true;
      this.botaoEnviar = true;

      }
  }

}
