
<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="theme-color" content="#000000">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    
      <meta name="description" content="Chegou a hora de colocar os seus conhecimentos para o ENEM 2021 à prova, neste simulado on-line e GRATUITO do Nota Máxima!">
    

    
      <meta property="og:title" content="Simulado Nacional ENEM - Nota Máxima" />
      <meta name="twitter:title" content="Simulado Nacional ENEM - Nota Máxima" />
    

    

    
      <meta name="twitter:card" content="summary" />
    

    

    
      <link rel="canonical" href="https://conteudos.notamaxima.com.br/simulado-nacional-enem-confirmacao" />
    

    <title>Simulado Nacional ENEM - Nota Máxima</title>

    
    <!-- Reset Browser Styles -->
    <style type="text/css" rel="stylesheet">
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed,
      figure, figcaption, footer, header, hgroup,
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      article, aside, details, figcaption, figure,
      footer, header, hgroup, menu, nav, section {
        display: block;
      }

      body {
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }

      ol, ul {
        list-style: none;
      }

      blockquote, q {
        quotes: none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      b, strong {
        font-weight: bold;
      }

      i, em {
        font-style: italic;
      }
    </style>

    <!-- Bricks Main Styles -->
    <style type="text/css" rel="stylesheet">
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      body {
        background-color: #FFFFFF;
        color: #333333;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.42857;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }

      h1 { font-size: 41px; }
      h2 { font-size: 34px; }
      h3 { font-size: 28px; }
      h4 { font-size: 20px; }
      h5 { font-size: 16px; }
      h6 { font-size: 14px; }

      .bricks--page {
        width: 100%;
      }

      .bricks--section {
        width: 100%;
      }

      .bricks--section > div {
        margin-left: auto;
        margin-right: auto;
      }

      .bricks--row {
        display: flex;
        flex-flow: column nowrap;
      }

      .bricks--column > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .bricks--component-box {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .bricks--row {
          flex-flow: row nowrap;
        }
      }
    </style>

    <!-- Bricks Component Form Styles -->
    <style type="text/css" rel="stylesheet">
      .bricks-form {
        height: auto;
        width: 100%;
      }

      .bricks-form__fieldset {
        display: flex;
        flex-flow: row wrap;
        margin-left: -5px;
        margin-right: -5px;
      }

      .bricks-form__field {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }

      .bricks-form__label {
        position: relative !important;
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.4em;
        line-height: 1.25;
        vertical-align: middle;
      }

      .bricks-form__input {
        display: block;
        width: 100%;
        padding: 0 0.5em;
        line-height: 1.42857;
      }

      .bricks-form__input.error {
        border: 1px solid red !important;
      }

      .bricks-form__field__option .bricks-form__label {
        padding-left: 1.33em;
        margin-bottom: 0.2em;
      }

      .bricks-form__field__option .bricks-form__label input[type="checkbox"],
      .bricks-form__field__option .bricks-form__label input[type="radio"] {
        margin: 0px 0px 0px -1.33em;
      }

      .bricks-form__submit {
        position: relative;
      }
    </style>

    <link href="https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Lato|Lobster|Open+Sans|Oswald|PT+Sans|Raleway|Source+Sans+Pro|Ubuntu|Roboto" rel="stylesheet">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/3.5.2/select2.min.css" rel="stylesheet" />

    <!-- Bricks Static Shared Styles -->
<style type="text/css" rel="stylesheet">
   .bricks--component-video div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    line-height: 0;
    box-sizing: border-box;
  }

   .bricks--component-video div iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>

    

<!-- Bricks Static Text Styles -->
<style type="text/css" rel="stylesheet">

   .bricks--component-text {
    color: #333;
    line-height: 1.42;
    overflow: hidden;
    word-wrap: break-word;
  }

   .bricks--component-text a {
    color: #31a2ff;
    text-decoration: none;
  }

   .bricks--component-text sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

   .bricks--component-text sub {
    bottom: -0.25em;
  }

   .bricks--component-text sup {
    top: -0.5em;
  }

   .bricks--component-text ol, 
   .bricks--component-text ul {
    padding-left: 1.5em;
  }

   .bricks--component-text ol > li,
   .bricks--component-text ul > li {
    list-style-type: none;
  }

   .bricks--component-text ul > li::before {
    content: '\2022';
  }

   .bricks--component-text li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

   .bricks--component-text li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

   .bricks--component-text li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

   .bricks--component-text ol li:not(.ql-direction-rtl),
   .bricks--component-text ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

   .bricks--component-text ol li.ql-direction-rtl,
   .bricks--component-text ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

   .bricks--component-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

   .bricks--component-text ol li:before {
    content: counter(list-0, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-increment: list-1;
  }

   .bricks--component-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-increment: list-2;
  }

   .bricks--component-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-increment: list-3;
  }

   .bricks--component-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-increment: list-4;
  }

   .bricks--component-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-increment: list-5;
  }

   .bricks--component-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-increment: list-6;
  }

   .bricks--component-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

   .bricks--component-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-increment: list-7;
  }

   .bricks--component-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

   .bricks--component-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-increment: list-8;
  }

   .bricks--component-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

   .bricks--component-text ol li.ql-indent-8 {
    counter-reset: list-9;
  }

   .bricks--component-text ol li.ql-indent-9 {
    counter-increment: list-9;
  }

   .bricks--component-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

   .bricks--component-text .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

   .bricks--component-text li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

   .bricks--component-text .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

   .bricks--component-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

   .bricks--component-text .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

   .bricks--component-text li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

   .bricks--component-text .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

   .bricks--component-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

   .bricks--component-text .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

   .bricks--component-text li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

   .bricks--component-text .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

   .bricks--component-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

   .bricks--component-text .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

   .bricks--component-text li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

   .bricks--component-text .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

   .bricks--component-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

   .bricks--component-text .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

   .bricks--component-text li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

   .bricks--component-text .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

   .bricks--component-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

   .bricks--component-text .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

   .bricks--component-text li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

   .bricks--component-text .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

   .bricks--component-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

   .bricks--component-text .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

   .bricks--component-text li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

   .bricks--component-text .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

   .bricks--component-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

   .bricks--component-text .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

   .bricks--component-text li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

   .bricks--component-text .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

   .bricks--component-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

   .bricks--component-text .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

   .bricks--component-text li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

   .bricks--component-text .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

   .bricks--component-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

   .bricks--component-text .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  
</style>


    <!-- Defines page width style to row components -->
    <style>
      .bricks--section > div {
        max-width: 1280px;
      }
    </style>

    <script type="text/javascript"> </script>


<!-- Global site tag (gtag.js) - Google Ads: 735857650 -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-735857650"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-735857650');
</script>


<!-- Event snippet for Cadastro conversion page -->
<script>
  gtag('event', 'conversion', {'send_to': 'AW-735857650/hGIQCKSv798CEPKX8d4C'});
</script>
  </head>
  <body>
    <div class="bricks--container">
      <div class="bricks--page">
        

<style type="text/css" rel="stylesheet">
  
    #rd-section-kr51i1z2 {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kr51i1z2 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kr51i1z2" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kr51i1z3 {
      min-height: 50px;
    }

    #rd-row-kr51i1z3 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kr51i1z3.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kr51i1z3" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kr51i1z4 {
      flex: 12 0 0;
    }

    #rd-column-kr51i1z4 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kr51i1z4 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kr51i1z4" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-kr51i9dh {
      margin-top: 50px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 99px;
    }

    #rd-image-kr51i9dh a,
    #rd-image-kr51i9dh img {
      width: 99px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-kr51i9dh {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-kr51i9dh" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/121436/1598449837/$gn7fzhqhgns" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kr51ibhj {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 643px;
      margin-top: 30px;
      margin-bottom: 7px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr51ibhj" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-size: 30px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif; background-color: rgb(26, 188, 156);"> Sua inscrição está confirmada! </strong></h1>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kr51icqu {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 703px;
      margin-top: 10px;
      margin-bottom: 40px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr51icqu" class="bricks--component bricks--component-text rd-text">
  <p style="line-height: 1.25; text-align: center;"><span style="font-size: 17px; font-family: Open Sans, sans-serif;">Em breve você receberá um e-mail com os seus dados de login e senha para acessar a plataforma. Caso não o encontre na caixa de entrada, por favor, verifique a caixa de spam e o lixo eletrônico. Estaremos à disposição pelo e-mail: </span><strong style="font-size: 17px; font-family: Open Sans, sans-serif;">contato@notamaxima.com.br</strong><span style="font-size: 17px; font-family: Open Sans, sans-serif;">, ou através de nossas redes sociais. 😉</span></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kr51jut1 {
      
            background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/111907/1628076997/$a5c17al00wn), linear-gradient(to bottom, rgba(17,151,151,0.8) 0%, rgba(17,151,151,0.8) 100%);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
          
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kr51jut1 > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kr51jut1" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kr51jut2 {
      min-height: 50px;
    }

    #rd-row-kr51jut2 .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kr51jut2.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kr51jut2" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kr51jut3 {
      flex: 12 0 0;
    }

    #rd-column-kr51jut3 > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kr51jut3 > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kr51jut3" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kr51jut6 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 703px;
      margin-top: 50px;
      margin-bottom: 7px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr51jut6" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="font-family: Open Sans, sans-serif; background-color: transparent; color: rgb(255, 255, 255);">Estude para o ENEM 2021 com o Semiextensivo do Nota Máxima!</strong></h1>
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kr51ljk8 {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 703px;
      margin-top: 20px;
      margin-bottom: 7px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr51ljk8" class="bricks--component bricks--component-text rd-text">
  <h1 style="text-align: center;"><strong style="color: rgb(255, 255, 255); background-color: transparent; font-family: Open Sans, sans-serif; font-size: 22px;">Clique no botão abaixo para conferir nossos cursos e utilize o cupom ENEM2021 para ganhar 20% de desconto!</strong></h1>
</div>


<style type="text/css" rel="stylesheet">
  
    a#rd-button-kfh3jfuc {
          display: flex;
      flex-direction: column;
    }

    #rd-button-kfh3jfuc {
      -ms-flex-align: center;
      -ms-flex-direction: column;
      -ms-flex-pack: center;
      align-self: center;
      background-color: rgba(244,195,0,1);
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 44px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
      color: #ffffff;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      line-height: 1.42857;
      margin-bottom: 50px;
      margin-top: 30px;
      max-width: 289px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-button-kfh3jfuc {
        height: auto;
        padding-top: 0.66em;
        padding-bottom: 0.66em;
      }
    }
  
</style>


  <a
    class="bricks--component bricks--component-button rd-button"
    href="https://www.notamaxima.com.br/?utm_source=rd&utm_medium=lp&utm_campaign=lista-enem"
    id="rd-button-kfh3jfuc"
    target="_blank"
    title=""
  >
    QUERO GARANTIR MEU DESCONTO
  </a>


  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2lr {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: transparent;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 0px;
    }

    #rd-section-joq3m2lr > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2lr" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2ls {
      min-height: 50px;
    }

    #rd-row-joq3m2ls .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2ls.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2ls" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2lt {
      flex: 12 0 0;
    }

    #rd-column-joq3m2lt > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2lt > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2lt" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kr55cnvw {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr55cnvw" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>


<style type="text/css" rel="stylesheet">
  
    #rd-section-kr55a3bm {
      
          background-color: transparent;
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-kr55a3bm > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-kr55a3bm" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-kr55a3bn {
      min-height: 50px;
    }

    #rd-row-kr55a3bn .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kr55a3bn.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kr55a3bn" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kr55a3bo {
      flex: 6 0 0;
    }

    #rd-column-kr55a3bo > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kr55a3bo > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kr55a3bo" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-video-kr55cd0o {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 500px;
      max-width: 100%;
    }

    #rd-video-kr55cd0o iframe {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
  
</style>

<div id="rd-video-kr55cd0o" class="bricks--component bricks--component-video rd-video">
  <div>
    <iframe src="https://www.youtube.com/embed/fxmWQZHUHxY?&rel=0&autoplay=0&mute=0" frameborder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>

  </div>
</div>


<style type="text/css" rel="stylesheet">
  
    #rd-column-kr55abjn {
      flex: 6 0 0;
    }

    #rd-column-kr55abjn > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kr55abjn > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kr55abjn" class="bricks--column rd-column">
  <div>
    

<style type="text/css" rel="stylesheet">
  
    #rd-image-joq3m2lu {
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      max-width: 289px;
    }

    #rd-image-joq3m2lu a,
    #rd-image-joq3m2lu img {
      width: 289px;
      max-width: 100%;
      height: auto;
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }
    @media screen and (max-width: 767px) {
      #rd-image-joq3m2lu {
        align-self: center;
      }
    }
  
</style>



<div id="rd-image-joq3m2lu" class="bricks--component bricks--component-image rd-image">
  
        <img src="https://d335luupugsy2.cloudfront.net/cms/files/111907/1603299830/$xb7bh77vbj" alt="">

  
</div>

<style type="text/css" rel="stylesheet">
  
    #rd-text-kr55d25k {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: -1px;
      max-width: 527px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr55d25k" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">Estude para o ENEM e os vestibulares mais concorridos do país com os melhores professores do Brasil!</span></p><p style="text-align: center; line-height: 1.25;"><br></p><p style="text-align: center; line-height: 1.25;"><span style="font-size: 14px; font-family: Open Sans, sans-serif;">﻿Somos o único curso preparatório que entende o estado emocional de cada aluno e o ajuda a conseguir a aprovação com estudos que se encaixam na sua rotina.</span></p>
</div>

  </div>
</div>

</div>


<style type="text/css" rel="stylesheet">
  
    #rd-row-kr55ae2p {
      min-height: 50px;
    }

    #rd-row-kr55ae2p .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-kr55ae2p.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-kr55ae2p" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-kr55ae2q {
      flex: 12 0 0;
    }

    #rd-column-kr55ae2q > div {
      
          background-color: transparent;
        
      justify-content: center;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-kr55ae2q > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-kr55ae2q" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-kr55ctwf {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: 0px;
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-kr55ctwf" class="bricks--component bricks--component-text rd-text">
  <p><br></p>
</div>

  </div>
</div>

</div>

</section>
<section>
  <div id="acesso-plataforma" class="bricks--component bricks--component-text rd-text mt-5">
    <h1 style="text-align: center;"><strong style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Obrigado pela sua inscrição!</strong> </h1>
    
    <h1 style="text-align: center;"><span style="font-size: 34px; color: rgb(255, 255, 255); font-family: Open Sans, sans-serif;">Em breve nossa equipe entrará em contato contigo!</span></h1>
  
    <a
      class="btn blink_me"
      style="display: inline-block;"
      [href]="link" 
      target="_blank"
      title="Any Title"
    > <span  class="btn btn-plataform"> Acesso a Plataforma AQUI!</span>
    </a>
  
  </div>
</section>

<style type="text/css" rel="stylesheet">
  
    #rd-section-joq3m2na {
      
          background-color: rgba(88,26,179,1);
        
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
    }

    #rd-section-joq3m2na > div:not(.bricks--row) {
      min-height: 0px;
    }
  
</style>

<section id="rd-section-joq3m2na" class="bricks--section rd-section">
  

<style type="text/css" rel="stylesheet">
  
    #rd-row-joq3m2nb {
      min-height: 50px;
    }

    #rd-row-joq3m2nb .bricks--column > div {
      min-height: 50px;
    }

    @media screen and (max-width: 767px) {
      #rd-row-joq3m2nb.bricks--row.rd-row {
        min-height: auto;
      }
    }
  
</style>

<div id="rd-row-joq3m2nb" class="bricks--row rd-row">
  

<style type="text/css" rel="stylesheet">
  
    #rd-column-joq3m2nc {
      flex: 12 0 0;
    }

    #rd-column-joq3m2nc > div {
      
          background-color: transparent;
        
      justify-content: flex-start;
      padding-top: 0px;
      padding-right: 25px;
      padding-bottom: 0px;
      padding-left: 25px;
    }
    @media screen and (max-width: 767px) {
      #rd-column-joq3m2nc > div {
        min-height: auto !important;
      }
    }
  
</style>

<div id="rd-column-joq3m2nc" class="bricks--column rd-column">
  <div>
    
<style type="text/css" rel="stylesheet">
  
    #rd-text-joq3m2nd {
      border-bottom-style: none;
      border-color: #000000;
      border-left-style: none;
      border-radius: 0px;
      border-right-style: none;
      border-top-style: none;
      border-width: 1px;
      min-height: auto-22px;
      max-width: 450px;
      margin-top: 15px;
      margin-bottom: 15px;
      align-self: center;
      width: 100%;
      word-break: break-word;
    }
  
</style>

<div id="rd-text-joq3m2nd" class="bricks--component bricks--component-text rd-text">
  <p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 12px; font-family: Ubuntu, sans-serif;">®Nota Máxima - Todos os direitos reservados</strong></p>
</div>

  </div>
</div>

</div>

</section>

      </div>
    </div>

    
      <script type="text/javascript" src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ba34491a-0415-480f-bec6-2c2bc69887b2-loader.js"></script>
    

    <script id="landing-page-attributes" data-id="2324340" data-experiment-content-id=""></script>

  <script id="analytics_tracking_code" type="text/javascript">
    var uuid = 'UA-143747841-1';
    var domain = 'notamaxima.com.br';

    function initializeBricksGA() {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', uuid, 'auto');
      ga('send', 'pageview');

      idsDataset = document.getElementById('landing-page-attributes').dataset;
      ga('send', 'event', 'RD Landing Pages', 'Viewed', 'landing_page:' + idsDataset.id);

      if (idsDataset.experimentContentId) {
        ga('send', 'event', 'RD Landing Pages', 'Viewed', 'experiment_content:' + idsDataset.experimentContentId);
      }
    }

    var _gaq = _gaq || [];

    if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
      initializeBricksGA();
    } else {
      document.addEventListener(window.RDCookieControl.analytics.eventName, initializeBricksGA);
    }
  </script>


    
  <script id="user-ga-interaction" type="text/javascript">
    function addGoogleAnalyticsInteraction() {
      var tags = ['input', 'a', 'button'];

      function retrieveAllElements(tags) {
        return tags.reduce(function(prev, curr){
          return prev.concat(Array.prototype.slice.call(document.querySelectorAll(curr)));
        }, []);
      }

      function addEvents(elements, event) {
        if(!elements) return;
        elements.forEach(function(element) {
          element.addEventListener(event, createInterativeCallback());
        });
      }

      function defineLabel(e) {
        var target = e.target || e.currentTarget
        var evtLabel = target.textContent || target.name || target.value || 'default';
        return 'rdstationlp=<'+ target.tagName.toLowerCase() + '>:' + evtLabel.trim();
      }

      function createInterativeCallback() {
        return function(e){
          var label = defineLabel(e);
           _gaq.push(['_trackEvent', 'RD_LP_user_interaction', e.type, label , null, false]);
        }
      }

      addEvents(retrieveAllElements(tags), 'click');
    }

    window.addEventListener('load', function() {
      if (!window.RDCookieControl || window.RDCookieControl.analytics.track) {
        addGoogleAnalyticsInteraction();
      } else {
        document.addEventListener(window.RDCookieControl.analytics.eventName, addGoogleAnalyticsInteraction);
      }
    })
  </script>


    <div data-editable="true" data-field-name="landing_page"></div>
    <script type="text/javascript"> </script>
    <style>
      
    </style>
  </body>
</html>
