import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-simulado-uel-sigma-confirmacao',
  templateUrl: './simulado-uel-sigma-confirmacao.component.html',
  styleUrls: ['./simulado-uel-sigma-confirmacao.component.scss']
})
export class SimuladoUelSigmaConfirmacaoComponent implements OnInit {

  public link:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.info(this.route.queryParams);
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.link =  atob(params.link) ;
      });
    //this.link = this.route.queryParams ; 
  }


}
