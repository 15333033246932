import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { QuimicaService } from '../quimica.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 

@Component({
  selector: 'app-quimica-in-doze',
  templateUrl: './quimica-in-doze.component.html',
  styleUrls: ['./quimica-in-doze.component.scss']
})
export class QuimicaInDozeComponent implements OnInit {

  profileForm: FormGroup;
  botaoEnviar: boolean;
  landClasses: any;
  constructor(  private route: ActivatedRoute,
                private router : Router,
                private quimicaService: QuimicaService
  ) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      turmaqueentra: new FormControl('')
    
    }); 
  }

  
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    var data = this.profileForm.getRawValue();
    
      
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          urlRedirect: "https://quimicain12.notamaxima.com.br/redirect",
          //urlRedirect: "http://localhost:4300/redirect",
          pv : "a92d5ad3bbe98fd58e9e2352fc163807"
        } 
        this.quimicaService.register(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( ['/confirmacao-quimicain12'], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a")); 
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

}
