import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { LandingService } from '../services/landing.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
}


@Component({
  selector: 'app-enem-gratuito-blumenau',
  templateUrl: './enem-gratuito-blumenau.component.html',
  styleUrls: ['./enem-gratuito-blumenau.component.scss']
})
export class EnemGratuitoBlumenauComponent implements OnInit {
  profileForm: FormGroup;
  constructor(  private route: ActivatedRoute,
    private router : Router,
    private landingService: LandingService
) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl('')
    
    });
    
  }
 
  async onSubmit() {
    // TODO: Use EventEmitter with form value
    var data = this.profileForm.getRawValue();

    var jsondata = {
      name : data.nome,
      email: data.email,
      pass: data.senha,
      urlRedirect: "https://sistema.notamaxima.com.br/redirect",
      pv :"7d7c71417fffe9588e1a4608136617d4"
    }
    this.landingService.register(jsondata)
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) { 
            let url:any = response.data; 
            //await localStorage.setItem('link',url.link);
            console.info(url.link);
            this.router.navigate( ['/confirmacao-enem-gratuito'], {queryParams:{ link : btoa(url.link) } })
        }
    }, err => console.log("a")); 
  }  


}
