import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ConcocService } from '../concoc.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 

export interface ResponseApi {
  data: object | boolean | number | string;
  error: string;
} 
@Component({
  selector: 'app-concoc-ef2022-lages',
  templateUrl: './concoc-ef2022-lages.component.html',
  styleUrls: ['./concoc-ef2022-lages.component.scss']
})
export class ConcocEf2022LagesComponent implements OnInit {

  
  
  public profileForm: FormGroup;
  public botaoEnviar: boolean = false;
  public landClasses: any = [];
  public buttonActive: boolean  = false;
  public landRoute:string;
  public urlRedirect:string; 
  public landRouteNavigate:string;
  constructor(private route: ActivatedRoute,
              private router : Router,
              private  concocService: ConcocService)
  { 
    this.landRoute          = "concoc-ef-2022-lages";
    this.urlRedirect        = "https://plataforma.coclages.com.br/redirect";
    this.landRouteNavigate  = "/concoc-ef-2022-lages-confirmacao";
  }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      nome: new FormControl(''),
      email: new FormControl(''),
      senha: new FormControl(''),
      turma: new FormControl(''),
      telefone: new FormControl(''),
      colegio: new FormControl(''),
      alunode: new FormControl(''), 
      unidadequevaiestudar: new FormControl(''), 
      nomeResponsavel: new FormControl(''), 
      telefoneResponsavel: new FormControl(''), 
      turmaqueentra: new FormControl(''),
      captcha: new FormControl('')
    
    });
    this.getLandClass();
    this.setDefault();
  }
  setDefault(){
    this.profileForm.get("turma").valueChanges.subscribe(x => {
      x = 0;
      this.profileForm.get("turma").setValue(x);
    });
    this.profileForm.get("alunode").valueChanges.subscribe(x => {
    x = 0;
    this.profileForm.get("alunode").setValue(x);
    });
  }
  getLandClass(){
    this.concocService.getLandRoute(this.landRoute)
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {
          let result:any = response.data;

          if(result.length > 0){
            this.landClasses = response.data;
          }else{
            this.router.navigate( ["/"]);

          }
        }
    }, err => console.log("a")); 
  }
  getLandClassSelect(landStr:string){
  
    landStr = (landStr == "Escola pública")? 'concoc-semi-riodosul-publica':'concoc-semi-riodosul-particular' ;
    this.concocService.getLandRoute(landStr)
    .subscribe(async res => {
        const response = res.body as ResponseApi;
 
        if (!response.error) {

            this.landClasses = response.data;  
        }
    }, err => console.log("a")); 
  }


  getNameClass() {
    var turma = (<HTMLSelectElement>document.getElementById("turma"));
    console.info(turma.options[turma.selectedIndex].textContent);
    console.info(turma.options[turma.selectedIndex].value);
    var el    = (<HTMLInputElement>document.getElementById("turmanome")) ;
    el.value = turma.options[turma.selectedIndex].textContent;
    
  }
  async onSubmit() {

    if(this.botaoEnviar){ 
     
        // TODO: Use EventEmitter with form value
        var data = this.profileForm.getRawValue(); 
        var jsondata = {
          name : data.nome,
          email: data.email,
          pass: data.senha,    
          telefone: data.telefone,
          alunode :data.alunode,
          telefoneResponsavel : data.telefoneResponsavel,
          nomeResponsavel : data.nomeResponsavel, 
          unidadequevaiestudar : data.unidadequevaiestudar,
          colegio: data.colegio, 
          urlRedirect: this.urlRedirect ,
          pv :data.turma
        } 
        this.concocService.registerSave(jsondata)
        .subscribe(async res => {
            const response = res.body as ResponseApi;
     
            if (!response.error) { 
                let url:any = response.data; 
                //await localStorage.setItem('link',url.link);
                console.info(url.link);
                this.router.navigate( [this.landRouteNavigate ], {queryParams:{ link : btoa(url.link) } })
            }
        }, err => console.log("a"));
    }  
  }  

  avisoCampos(){
    alert('Preencha todos os campos!');
  }

  print(test){
    console.log(test);
  }

  changeField(){
    var data = this.profileForm.getRawValue();
  
    if(data.nome != null && data.email != null && 
      data.senha != null && data.telefone != null  && data.alunode != null && data.turma != null ){ 
      this.buttonActive = true;
      this.botaoEnviar = true;
      
      }
  }


}
